import React, { ChangeEvent, useRef, useState } from 'react'
import { css } from 'glamor'
import { executeSearch, SearchInput } from './FullPageSearch'
import HighlightBox from './HighlightBox/HighlightBox'
import { createMQ } from './Responsive'
import { StaticContext, useHistory, withRouter } from 'react-router'

const style = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'url(/assets/404.jpg)',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  marginBottom: -60,
  width: '100vw',
  height: 'calc(100vh - 70px)',
  [createMQ('desktop')]: {
    height: 'calc(100vh - 100px)',
  },
  backgroundSize: 'cover',
  backgroundPosition: '75% 0%',
})

const NotFound = ({ staticContext }: { staticContext?: StaticContext }) => {
  if (staticContext) {
    staticContext.statusCode = 404
  }

  const history = useHistory()
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')

  return (
    <div {...style}>
      <div {...css({ background: 'rgba(255, 255, 255, 0.5)' })}>
        <HighlightBox
          fontStyle={{ fontWeight: 300, textTransform: 'none', fontSize: 15 }}
          titleFontStyle={{ fontWeight: 600, fontSize: 25 }}
          title="Seite nicht gefunden"
        >
          <div
            {...css({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              flexDirection: 'column',
            })}
          >
            Die gewünschte Seite ist leider nicht mehr verfügbar.
            <br />
            Nutze die Maprodo Produktsuche, um zu finden, was du suchst.
            <br />
            <div {...css({ padding: '25px 0px' })}>
              <SearchInput
                value={searchValue}
                ref={inputRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(e.target.value)
                }
                onClickX={() => {
                  setSearchValue('')
                  inputRef.current && inputRef.current.focus()
                }}
                onKeyUp={(e) => {
                  e.key === 'Enter' &&
                    searchValue &&
                    executeSearch(history, searchValue)
                }}
                placeholder="Suchen…"
              />
            </div>
          </div>
        </HighlightBox>
      </div>
    </div>
  )
}

export default withRouter(NotFound)
