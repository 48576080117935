import React, { FunctionComponent, useEffect, useState } from 'react'
import { css } from 'glamor'
import NumberFormat from 'react-number-format'

import Checkbox from '../FilterBox/Checkbox'
import FilterBox, { IFilterBoxProps } from '../FilterBox/FilterBox'
// import Slider from 'omni-slider/omni-slider.min'
// import 'omni-slider/omni-slider.min.css'
// import './slider-fly.css'

const styles = {
  // priceList: css({
  //   display: 'flex',
  //   flexDirection: 'column',
  // }),
  ranges: css({
    display: 'flex',
    justifyContent: 'space-around',
  }),
  range: css({
    position: 'relative',
    width: 70,
  }),
  // sliderWrapper: css({
  //   margin: '25px 0 12px 0',
  //   // padding: '0 7px',
  //   display: 'flex',
  //   width: '100%',
  //   height: 14,
  //   justifyContent: 'center',
  //   flexDirection: 'row',
  // }),
  numberInput: css({
    width: '100%',
    position: 'relative',
    border: 'none',
    borderBottom: '1px solid gray',
  }),
  currency: css({
    position: 'absolute',
    left: 0,
    top: 1,
  }),
}
const STEP = 25

const roundToNearestMin = (value: number) => Math.floor(value / STEP) * STEP
const roundToNearestMax = (value: number) => Math.ceil(value / STEP) * STEP

const roundToSteps = (range: [number?, number?] = []) => {
  const [min, max] = range
  if (!min || !max) return []

  return [
    // round down to nearest step (26 => 0 with step  50)
    roundToNearestMin(min),
    // round up to nearest step (1336 => 1350 with step 50)
    roundToNearestMax(max),
  ]
}

interface IPriceFilterBoxProps extends Pick<IFilterBoxProps, 'forceOpen'> {
  priceRange: [number?, number?]
  selectedRange: [number?, number?]
  onMinPriceChange: (min: number | undefined) => void
  onMaxPriceChange: (max: number | undefined) => void
  hasUserSelectedRange: boolean
  onClear: () => void
  onSaleChange: (value: string, checked: boolean) => void
  isSaleChecked?: boolean
}

const CURRENCY_PREFIX = '€ '

const PriceFilterBox: FunctionComponent<IPriceFilterBoxProps> = ({
  priceRange,
  selectedRange,
  onMinPriceChange,
  onMaxPriceChange,
  hasUserSelectedRange,
  onClear,
  onSaleChange,
  isSaleChecked,
  forceOpen,
}) => {
  const [minRange, maxRange] = roundToSteps(priceRange)

  const [minSelected, setMinSelected] = useState<string | number>('')
  const [maxSelected, setMaxSelected] = useState<string | number>('')

  // const sliderRef = useRef()
  // const uiSliderInstance = useRef()
  //
  // useEffect(() => {
  //   uiSliderInstance.current = new Slider(sliderRef.current, {
  //     isDate: false,
  //     min: 0,
  //     max: 365,
  //     start: 100,
  //     end: 200,
  //     overlap: true,
  //   })
  //
  //   return () => uiSliderInstance.current.stop()
  // }, [])

  useEffect(() => {
    const [minSelected, maxSelected] = roundToSteps(selectedRange)

    // uiSliderInstance.current.updateOptions()

    setMinSelected(minSelected)
    setMaxSelected(maxSelected)
  }, [selectedRange])

  // Always render children here so we don't lose the `sliderRef`
  return (
    <FilterBox
      label="Preis"
      forceOpen={forceOpen}
      clearable={isSaleChecked || hasUserSelectedRange}
      onClear={onClear}
      alwaysRenderChildren
    >
      <div {...styles.ranges}>
        <div {...styles.range}>
          <NumberFormat
            prefix={CURRENCY_PREFIX}
            onValueChange={({ value }) => setMinSelected(value)}
            allowNegative={false}
            decimalSeparator=""
            thousandSeparator="."
            onBlur={() => {
              if (minSelected) {
                const newValue = Math.max(
                  minRange,
                  roundToNearestMin(+minSelected)
                )
                // if the value is higher than selected/max value decrease one step
                if (newValue < maxSelected && newValue < maxRange) {
                  onMinPriceChange(newValue)
                } else {
                  const nearest = +maxSelected || maxRange
                  onMinPriceChange(nearest ? nearest - STEP : undefined)
                }
              } else {
                onMinPriceChange(undefined)
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.currentTarget.blur()
              }
            }}
            value={minSelected}
            inputMode="numeric"
            onFocus={e => e.target.select()}
            placeholder={CURRENCY_PREFIX + minRange}
            {...styles.numberInput}
          />
        </div>
        &mdash;
        <div {...styles.range}>
          <NumberFormat
            prefix={CURRENCY_PREFIX}
            onValueChange={({ value }) => setMaxSelected(value)}
            allowNegative={false}
            decimalSeparator=""
            thousandSeparator="."
            onBlur={() => {
              if (maxSelected) {
                const newValue = Math.min(
                  maxRange,
                  roundToNearestMax(+maxSelected)
                )

                if (newValue <= minSelected) {
                  onMaxPriceChange(+minSelected + STEP)
                } else {
                  onMaxPriceChange(newValue)
                }
              } else {
                onMaxPriceChange(undefined)
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.currentTarget.blur()
              }
            }}
            value={maxSelected}
            inputMode="numeric"
            onFocus={e => e.target.select()}
            placeholder={CURRENCY_PREFIX + maxRange}
            {...styles.numberInput}
          />
        </div>
      </div>
      {/*<div {...styles.sliderWrapper} ref={sliderRef} className="fly" />*/}

      <div style={{ margin: '10px 0' }}>
        <Checkbox
          label="nur reduzierte Artikel"
          value="sale"
          checked={isSaleChecked}
          onChange={onSaleChange}
        />
      </div>
    </FilterBox>
  )
}

export default PriceFilterBox
