import React from 'react'
import { Link, Route } from 'react-router-dom'
import { css, Rule } from 'glamor'
import { createMQ } from '../Responsive'
import SidebarWrapper from './SidebarWrapper'
import Button from '../Button'
import CategoryRoutes from '../Category/CategoryRoutes'
import { Switch } from 'react-router'
import { useIsNativeApp } from '../utils/nativeApp'

const styles = {
  wrapper: css({
    background: '#fff',
    minHeight: '100%',
    paddingBottom: 60,
  }),
  top: css({
    background: '#fff',
    height: 70,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  }),
  topCategory: css({
    flex: 1,
    // height declaration safari fix
    height: '100%',
  }),
  topCategoryLink: css({
    display: 'flex',
    textTransform: 'uppercase',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }),
  activeSpacer: (translate, total: number) =>
    css({
      height: 3,
      marginBottom: -3,
      background: '#000',
      width: `${((1 / total) * 100).toFixed(3)}%`,
      transition: '150ms',
      transform: `translateX(${translate * 100}%) translateY(-100%)`,
    }),
  categoryWrapper: css({
    [createMQ('desktop')]: {
      margin: 20,
      marginBottom: 0,
    },
  }),
}

interface ICategorySidebarProps {
  open: boolean
  width: string
  onRequestClose: () => void
}

const TopBar = ({ prefixPath, onRequestClose }) => {
  const links = [
    <Link key="damen" to={`${prefixPath}/damen`} {...styles.topCategoryLink}>
      Damen
    </Link>,
    <Link key="herren" to={`${prefixPath}/herren`} {...styles.topCategoryLink}>
      Herren
    </Link>,
    !useIsNativeApp() && (
      <Link
        key="marken"
        to={`${prefixPath}/marken`}
        {...styles.topCategoryLink}
        onClick={onRequestClose}
      >
        Marken
      </Link>
    ),
  ].filter(Boolean)
  return (
    <Switch>
      <Route path="/marken" />
      <Route path={`${prefixPath}/:type(damen|herren|marken)?`}>
        {({ match }) => {
          let t = 0
          if (match) {
            switch (match.params.type) {
              case 'herren':
                t = 1
                break
              case 'marken':
                t = 2
                break
              case 'damen':
              default:
                t = 0
            }
          }
          return (
            <>
              <div {...styles.top}>
                {links.map((link, i) => (
                  <div key={i} {...styles.topCategory}>
                    {link}
                  </div>
                ))}
              </div>
              <div {...styles.activeSpacer(t, links.length)} />
            </>
          )
        }}
      </Route>
    </Switch>
  )
}

export const CategorySidebarInner = ({
  onRequestClose,
  prefixPath = '',
  hideTopBar,
  style,
}: {
  onRequestClose?: () => void
  prefixPath?: string
  hideTopBar?: boolean
  style?: Rule
}) => (
  <div {...css([styles.wrapper, style])}>
    {!hideTopBar && (
      <TopBar prefixPath={prefixPath} onRequestClose={onRequestClose} />
    )}
    <div {...styles.categoryWrapper}>
      <CategoryRoutes prefixPath={prefixPath} />
    </div>
  </div>
)

const CategorySidebar = ({
  open,
  width,
  onRequestClose,
}: ICategorySidebarProps) => (
  <SidebarWrapper
    open={open}
    position="left"
    width={width}
    bottomArea={
      <Button
        contrast
        {...css({ width: '100%', fontWeight: 400, textAlign: 'center' })}
        onClick={onRequestClose}
      >
        Übernehmen
      </Button>
    }
  >
    <CategorySidebarInner onRequestClose={onRequestClose} />
  </SidebarWrapper>
)

export default React.memo(CategorySidebar)
