import React, { ChangeEvent, CSSProperties, AllHTMLAttributes } from 'react'

import { css } from 'glamor'
import SearchIcon from '../icons/Search'
import CloseIcon from '../icons/Close'

const style = extraStyles =>
  css({
    width: '100%',
    border: 0,
    borderTop: '1px solid #c0c1c0',
    borderBottom: '2px solid #c0c1c0',
    background: '#efefef',
    padding: 6,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 300,
    fontSize: 12,
    ...extraStyles,
  })

const imgStyle = css({
  position: 'absolute',
  right: 0,
})

interface IFilterSearchProps extends AllHTMLAttributes<HTMLInputElement> {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  inputStyle?: CSSProperties
}

const FilterSearch = React.forwardRef<HTMLInputElement, IFilterSearchProps>(
  ({ value, onChange, inputStyle, ...props }, ref) => (
    <div
      {...css({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
      })}
    >
      <input
        aria-label="Nach Marken suchen"
        type="text"
        ref={ref}
        {...style(inputStyle)}
        value={value}
        onChange={onChange}
        {...props}
      />
      {value.length > 0 ? (
        <CloseIcon
          {...css(imgStyle, { cursor: 'pointer' })}
          onClick={() =>
            onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
          }
          width={18}
          height={18}
        />
      ) : (
        <SearchIcon
          {...css(imgStyle, { pointerEvents: 'none' })}
          width={18}
          height={18}
        />
      )}
    </div>
  )
)

export default FilterSearch
