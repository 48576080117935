import { useInitialUrlContext } from '../UrlContext'
import { getTLD } from './getTLD'
import { getDatabaseUrl } from './getDatabaseUrl'

const useDatabaseUrl = () => {
  const { hostname } = useInitialUrlContext()
  const tld = getTLD(hostname)

  return getDatabaseUrl(tld)
}

export default useDatabaseUrl
