import React from 'react'
import groupBy from 'lodash/groupBy'
import { css } from 'glamor'
import { Helmet } from 'react-helmet-async'
import { defineMessages, useIntl } from 'react-intl'

import Letterhead from '../Letterhead'
import FullPageLoading from '../FullPageLoading'
import { Page } from '../Layout'
import ListBox from './ListBox'
import { BrandWithSlug } from '../types'
import useDatabaseQuery from '../utils/useDatabaseQuery'

const messages = defineMessages({
  brand_overview_title:
    'Marken und Designer für Luxusmode und High Fashion auf Maprodo',
  brand_overview_meta_description:
    'Entdecke die besten Angebote der Top Marken und Designer für Luxusmode und High Fashion auf Maprodo » Jetzt shoppen. «',
})

export type BrandsResponse = Record<string, BrandWithSlug>
type BrandsTransformedResponse = Record<string, BrandWithSlug[]>

const getBrandsGroupedByFirstLetter = (
  brands: BrandsResponse
): Record<string, BrandWithSlug[]> =>
  groupBy(brands, brand => {
    const firstChar = brand._slug.slice(0, 1)
    return /\d|[^a-z]/i.test(firstChar) ? '#' : firstChar
  })

export const useGroupedBrands = () =>
  useDatabaseQuery<BrandsResponse, BrandsTransformedResponse>('/brands', {
    select: data => getBrandsGroupedByFirstLetter(data),
  })

const BrandOverview = () => {
  const intl = useIntl()
  const brands = useGroupedBrands()

  if (!brands.isSuccess) return <FullPageLoading />

  return (
    <Page>
      <Helmet>
        <title>{intl.formatMessage(messages.brand_overview_title)}</title>
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content={intl.formatMessage(messages.brand_overview_meta_description)}
        />
      </Helmet>
      <div>
        <Letterhead>
          <h1
            {...css({
              fontSize: 'inherit',
              margin: 'inherit',
              fontWeight: 'inherit',
            })}
          >
            Marken und Designer
          </h1>
        </Letterhead>
        <div
          {...css({
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            padding: '20px 0',
            flexWrap: 'wrap',
            marginRight: -30,
          })}
        >
          {Object.entries(brands.data).map(([char, brands]) => (
            <ListBox key={char} title={char} appearance="chic" size="l">
              {brands.map(brand => (
                <ListBox.Item
                  key={brand.id}
                  link={`/marken/${brand._slug}`}
                  title={`${brand.name} Luxusmode auf Maprodo`}
                >
                  {brand.name}
                </ListBox.Item>
              ))}
            </ListBox>
          ))}
        </div>
      </div>
    </Page>
  )
}

export default BrandOverview
