import { useQuery, UseQueryOptions } from 'react-query'
import { databaseCall, ModifierType } from './databaseCall'
import useDatabaseUrl from './useDatabaseUrl'

export const __REACT_QUERY_DATABASE_HASH_KEY__ = '__db__'

const useDatabaseQuery = <
  TQueryFnData = unknown,
  TData = TQueryFnData | null,
  TError = unknown,
>(
  path: string,
  options?: UseQueryOptions<TQueryFnData, TError, TData>,
  modifier?: ModifierType,
) => {
  const databaseUrl = useDatabaseUrl()

  return useQuery(
    [__REACT_QUERY_DATABASE_HASH_KEY__, path],
    () => databaseCall<TQueryFnData>(path, modifier, databaseUrl),
    options,
  )
}

export default useDatabaseQuery
