import React from 'react'

const PageNext = props => (
  <svg width={14} height={16} viewBox="0 0 14 16" {...props}>
    <path
      d="M1.431.587l-1.113.786L4.963 7.99.318 14.628l1.115.785 5.65-7.147v-.494L1.433.587h-.002zm6.601 0l-1.133.786 4.644 6.617-4.644 6.638 1.133.785 5.65-7.147v-.494L8.034.587h-.002z"
      fillRule="nonzero"
      fill="#1E1E1C"
    />
  </svg>
)

export default PageNext
