import Responsive, { createMQ } from '../Responsive'
import { css } from 'glamor'
import Button from '../Button'
import CategoryIcon from '../icons/Category'
import FilterIcon from '../icons/Filter'
import PriceFilterBox from './PriceFilterBox'
import ProductMode, { PRODUCT_TYPES } from './ProductMode'
import BrandFilterBox from './BrandFilterBox'
import ColorFilterBox from './ColorFilterBox'
import GenderFilterBox from './BrandGenderFilterBox'
import PerPage from '../FilterBox/PerPage'
import SortingBox from './SortingBox'
import React from 'react'
import useQueryFilterParams from '../utils/hooks/useQueryFilterParams'
import { useSidebarContext } from '../Sidebar/SidebarProvider'
import useFacets from './hooks/useFacets'
import CloseIcon from '../icons/Close'

const styles = {
  filterRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    margin: '0 -10px',
    flexWrap: 'wrap',
  }),
  filters: css({
    display: 'flex',
    flex: 1,
  }),
  mobileButton: css({
    flex: 1,
    display: 'flex !important',
    fontWeight: 600,
    width: '100%',
    margin: '15px 5px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
}

const ProductFilterBar = ({ isDisabled }: { isDisabled?: boolean }) => {
  const params = useQueryFilterParams()
  const { open } = useSidebarContext()
  const facets = useFacets()

  return (
    <>
      <Responsive mobile wrap={false}>
        <div
          {...css({
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          })}
        >
          <Button {...styles.mobileButton} onClick={() => open('category')}>
            <CategoryIcon style={{ opacity: 0.7, marginRight: 10 }} />
            &nbsp;Kategorien
          </Button>
          {!isDisabled && (
            <div
              {...css({
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                flex: 1,
              })}
            >
              <Button
                {...css(styles.mobileButton, {
                  flex: params.hasSelectedFilters && 0.9,
                })}
                onClick={() => open('filter')}
              >
                <FilterIcon style={{ opacity: 0.7, marginRight: 10 }} />
                &nbsp;Filter
              </Button>
              {params.hasSelectedFilters && (
                <Button
                  {...css(styles.mobileButton, {
                    flex: 0.1,
                    padding: '14px 10px',
                    justifyContent: 'center',
                    display: 'flex',
                  })}
                  onClick={() => params.removeFilters()}
                >
                  <CloseIcon width={16} height={16} />
                </Button>
              )}
            </div>
          )}
        </div>
      </Responsive>

      <Responsive desktop tablet>
        <div
          {...css(
            isDisabled && {
              position: 'relative',
              ':after': {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                content: `''`,
                backgroundColor: '#fff',
                opacity: 0.5,
              },
            }
          )}
        >
          <div {...styles.filterRow}>
            <div {...styles.filters}>
              <PriceFilterBox
                onMinPriceChange={params.setMinPrice}
                onMaxPriceChange={params.setMaxPrice}
                priceRange={[facets.data?.price.min, facets.data?.price.max]}
                selectedRange={[
                  params.price_min || facets.data?.price.min,
                  params.price_max || facets.data?.price.max,
                ]}
                hasUserSelectedRange={Boolean(
                  params.price_min || params.price_max
                )}
                onClear={params.clearPriceFilter}
                onSaleChange={(_val, checked) =>
                  params.setSale(checked || undefined)
                }
                isSaleChecked={params.sale}
              />
              <ProductMode type={PRODUCT_TYPES.CATEGORY}>
                <BrandFilterBox
                  onSelect={params.selectBrand}
                  selected={params.brand_id}
                  brands={facets.data?.brand_id}
                  onClear={params.clearBrand}
                />
              </ProductMode>
              <ProductMode type={PRODUCT_TYPES.SEARCH}>
                <BrandFilterBox
                  onSelect={params.selectBrand}
                  selected={params.brand_id}
                  brands={facets.data?.brand_id}
                  onClear={params.clearBrand}
                />
              </ProductMode>
              <ColorFilterBox
                onSelect={params.selectColor}
                selected={params.color}
                colors={facets.data?.color}
                onClear={params.clearColor}
              />
              <ProductMode type={PRODUCT_TYPES.BRAND}>
                <GenderFilterBox />
              </ProductMode>
            </div>
            <div>
              <PerPage onSelect={params.selectPerPage} />
            </div>
            <div>
              <SortingBox
                onSelect={params.setSorting}
                selected={params.sorting}
              />
            </div>
          </div>
        </div>
      </Responsive>
    </>
  )
}

export default ProductFilterBar
