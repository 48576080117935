import React, { CSSProperties, useCallback, useState } from 'react'
import { css } from 'glamor'
import { FixedSizeList as VirtualList } from 'react-window'
import { matchSorter } from 'match-sorter'

import FilterBox, { IFilterBoxProps } from '../FilterBox/FilterBox'
import FilterSearch from '../FilterBox/FilterSearch'
import FilterItem from '../FilterBox/FilterItem'
import { createMQ } from '../Responsive'
import { BrandFacet } from '../types'

export const scrollStyle = css({
  '::-webkit-scrollbar': {
    width: 5,
  },
  '::-webkit-scrollbar-thumb': {
    background: '#C0C1C0',
    borderRadius: 5,
  },
  '::-webkit-scrollbar-track': {
    background: '#F1F0F0',
    borderRadius: 5,
  },
})

const style = css(
  {
    maxWidth: '100%',
    width: '100%',
    [createMQ('desktop')]: {
      width: 220,
    },
  },
  scrollStyle
)

interface ItemData {
  brands: readonly BrandFacet[]
  selected: readonly string[]
  onSelect: (brandId: string) => void
}

interface IItemRendererProps {
  style: CSSProperties
  index: number
  data: ItemData
}

const ItemRenderer = React.memo<IItemRendererProps>(
  ({ style, index, data }) => (
    <FilterItem
      label={data.brands[index].name}
      value={data.brands[index].id}
      checked={data.selected.includes(data.brands[index].id)}
      onChange={data.onSelect}
      key={index}
      style={{
        ...style,
        borderBottomWidth: data.brands.length - 1 === index ? 0 : undefined,
      }}
    />
  )
)

interface IBrandFilterBoxProps extends Omit<IFilterBoxProps, 'label'> {
  brands?: BrandFacet[]
  selected?: string[]
  onSelect: (brandId: string) => void
  onClear: () => void
}

const BrandFilterBox = ({
  brands = [],
  selected = [],
  onSelect,
  onClear,
  ...props
}: IBrandFilterBoxProps) => {
  const [searchValue, setSearchValue] = useState('')

  const sortFn = useCallback(
    (a, b) => {
      if (selected.includes(a.id) && !selected.includes(b.id)) {
        return -1
      } else if (selected.includes(b.id) && !selected.includes(a.id)) {
        return 1
      } else {
        return a.name.localeCompare(b.name)
      }
    },
    [selected]
  )

  const filteredBrands = matchSorter(brands, searchValue, { keys: ['name'] })
  const sortedBrands = [...filteredBrands].sort(sortFn)

  return (
    <FilterBox
      clearable={selected?.length > 0}
      onClear={onClear}
      label={`Marke${selected?.length > 0 ? ` (${selected.length})` : ''}`}
      {...props}
    >
      <FilterSearch
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />
      <VirtualList
        className={style}
        height={150}
        itemCount={sortedBrands.length}
        itemSize={30}
        itemData={{ brands: sortedBrands, selected, onSelect }}
      >
        {ItemRenderer}
      </VirtualList>
    </FilterBox>
  )
}

export default BrandFilterBox
