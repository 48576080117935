import React, { FunctionComponent } from 'react'
import { css } from 'glamor'

import Button, { IButtonProps } from './Button'

const linkStyle = css({
  padding: 10,
  display: 'inline-block',
  height: '100%',
})

interface ILinkButtonProps extends IButtonProps {
  to: string
  linkProps?: object
  contrast?: boolean
}

const LinkButton: FunctionComponent<ILinkButtonProps> = ({
  children,
  to,
  contrast,
  linkProps,
  ...props
}) => (
  <Button noPadding contrast={contrast} {...props}>
    <a
      href={to}
      className={contrast ? 'contrast' : ''}
      {...linkProps}
      {...linkStyle}
    >
      {children}
    </a>
  </Button>
)

export default LinkButton
