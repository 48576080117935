import React, { useState } from 'react'
import { css } from 'glamor'
import CategoryHeading from '../Category/CategoryHeading'
import CategoryItem from '../Category/CategoryItem'
import CategoryList from '../Category/CategoryList'
import FilterSearch from '../FilterBox/FilterSearch'
import { scrollStyle } from '../Product/BrandFilterBox'
import { createMQ } from '../Responsive'
import { useSidebarContext } from '../Sidebar/SidebarProvider'
import useBrandCategories from '../Product/hooks/useBrandCategories'
import useBrand from '../Product/hooks/useBrand'
import useProductListInfo from '../Product/hooks/useProductListInfo'
import { matchSorter } from 'match-sorter'
import Highlighter from 'react-highlight-words'
import gold from '../utils/gold'

const BrandCategoryMenu = () => {
  const { params } = useProductListInfo()
  const categories = useBrandCategories()
  const brandInfo = useBrand()

  // prettier-ignore
  const path = `/marken/${params.brand}${params.gender ? `/${params.gender}` : ''}`
  const [searchValue, setSearchValue] = useState('')
  const { close } = useSidebarContext()

  return (
    <div
      {...css({
        [createMQ('desktop')]: {
          paddingRight: 15,
          position: 'sticky',
          top: 0,
        },
      })}
    >
      <CategoryList>
        <div
          {...css({
            [createMQ('desktop')]: {
              position: 'sticky',
              top: 0,
              background: '#fff',
            },
          })}
        >
          {brandInfo.isSuccess && brandInfo.data && (
            <CategoryHeading
              path={`/marken/${params.brand}`}
              name={`${brandInfo.data.name} Online Shop`}
            />
          )}
          {categories.isSuccess && categories.data && (
            <FilterSearch
              placeholder="durchsuchen"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              inputStyle={{
                background: 'none',
                borderTop: 0,
                borderBottomWidth: 1,
                paddingLeft: 0,
                [createMQ('mobile', 'tablet')]: {
                  padding: 12,
                },
              }}
            />
          )}
        </div>

        <div
          {...css(
            {
              [createMQ('desktop')]: {
                maxHeight: '65vh',
                overflow: 'auto',
              },
            },
            scrollStyle
          )}
        >
          {categories.isSuccess &&
            categories.data &&
            matchSorter(Object.values(categories.data), searchValue, {
              keys: ['name'],
            }).map(category => (
              <CategoryItem
                key={category.slug}
                onClick={close}
                path={`${path}/${category.slug}`}
              >
                <Highlighter
                  highlightStyle={{ color: gold, fontWeight: 600 }}
                  searchWords={[searchValue]}
                  textToHighlight={category.name}
                />
              </CategoryItem>
            ))}
        </div>
      </CategoryList>
    </div>
  )
}

export default BrandCategoryMenu
