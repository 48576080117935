import createDOMPurify from 'dompurify'

let DOMPurify = createDOMPurify
if (process.env.BUILD_TARGET === 'server') {
  const { JSDOM } = require('jsdom')
  const { window } = new JSDOM('')

  DOMPurify = createDOMPurify(window)
}

export default DOMPurify
