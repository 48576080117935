import { RefObject, useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

export const useIsStuck = (
  wrapperRef: RefObject<HTMLElement>,
  offset: number = 1
) => {
  const [isStuck, setIsStuck] = useState(false)

  // @todo triggering too much true/false positives in Safari:
  // https://stackoverflow.com/questions/16302483/event-to-detect-when-positionsticky-is-triggered
  useEffect(() => {
    const handleScroll = throttle(() => {
      if (!wrapperRef.current) return

      const bounds = wrapperRef.current.getBoundingClientRect()

      if (bounds.top === offset && !isStuck) {
        setIsStuck(true)
      } else if (bounds.top !== offset && isStuck) {
        setIsStuck(false)
      }
    }, 225)

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isStuck, offset, wrapperRef])

  return isStuck
}
