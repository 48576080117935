import React, { FunctionComponent } from 'react'

import { css } from 'glamor'
import FilterBox, { IFilterBoxProps } from '../FilterBox/FilterBox'

const sortingMap = {
  popularity: 'Beliebteste',
  price_desc: 'Höchster Preis',
  price_asc: 'Niedrigster Preis',
  newest: 'Neueste',
}

const styles = {
  link: active =>
    css({
      cursor: 'pointer',
      fontWeight: active && 600,
    }),
}

interface ISortingBoxProps extends Omit<IFilterBoxProps, 'label'> {
  selected: string
  onSelect: (selected: string) => void
}

const SortingBox: FunctionComponent<ISortingBoxProps> = ({
  selected,
  onSelect,
  ...props
}) => (
  <FilterBox label="Sortieren" align="right" {...props}>
    {Object.keys(sortingMap).map(key => (
      <div
        key={key}
        onClick={() => onSelect(key)}
        {...styles.link(selected === key)}
      >
        {sortingMap[key]}
      </div>
    ))}
  </FilterBox>
)

export default React.memo(SortingBox)
