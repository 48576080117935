import React, { useState } from 'react'
import { getCategoryFromPathname } from '../Category/utils'
import { Helmet } from 'react-helmet-async'
import ProductMode, { PRODUCT_TYPES } from './ProductMode'
import HeroHeaderContainer from './HeroHeaderContainer'
import { css } from 'glamor'
import ProductFilterBar from './ProductFilterBar'
import Pager from './Pager'
import ProductNoResults from './ProductNoResults'
import ProductItem, {
  GUTTER,
  mqColumnSelector,
  ProductItemSkeleton,
} from './ProductItem'
import Media from 'react-media'
import PopularBrands from '../Brand/PopularBrands'
import PopularBrandCategories from '../Brand/PopularBrandCategories'
import CategoryDescriptionText from '../Category/CategoryDescriptionText'
import { defineMessages, useIntl } from 'react-intl'
import useQueryFilterParams from '../utils/hooks/useQueryFilterParams'
import useProductListInfo from './hooks/useProductListInfo'
import useProducts from './hooks/useProducts'
import useBrand from './hooks/useBrand'
import useDescriptionText from './hooks/useDescriptionText'
import usePerPageSize from './hooks/usePerPageSize'
import ky from 'ky-universal'
import { Redirect } from 'react-router'
import { useIsNativeApp } from '../utils/nativeApp'

export const getFormattedGender = gender =>
  gender === 'damen' ? 'Damen' : 'Herren'

const ProductList = () => {
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
  const { formatMessage } = useIntl()

  const { pathname, params } = useProductListInfo()
  const { gender } = params
  const category = getCategoryFromPathname(pathname)
  const pageSize = usePerPageSize()
  const isNativeApp = useIsNativeApp()

  const products = useProducts()
  const brand = useBrand()
  const descriptionText = useDescriptionText()
  const queryParams = useQueryFilterParams()

  const handleSelectProduct = id =>
    setSelectedProduct(selectedProduct => (selectedProduct === id ? null : id))

  const handleCloseProduct = () => setSelectedProduct(null)
  const formattedGender = getFormattedGender(gender)

  return (
    <div {...styles.wrapper}>
      {process.env.BUILD_TARGET === 'server' &&
        params.brand &&
        brand.isSuccess &&
        !brand.data && <Redirect to="/marken" />}
      <Helmet>
        <meta name="robots" content="index,follow" />
      </Helmet>
      <ProductMode type={PRODUCT_TYPES.CATEGORY}>
        <Helmet>
          <title>
            {category
              ? category.slug === 'bekleidung'
                ? formatMessage(messages.category_bekleidung_title, {
                    gender: formattedGender,
                  })
                : formatMessage(messages.category_title, {
                    gender: formattedGender,
                    category: category.name,
                  })
              : formatMessage(messages.category_herren_or_damen_title, {
                  gender: formattedGender,
                })}
          </title>
          <meta
            name="description"
            content={
              category
                ? category.slug === 'bekleidung'
                  ? formatMessage(
                      messages.category_bekleidung_meta_description,
                      { gender: formattedGender }
                    )
                  : formatMessage(messages.category_meta_description, {
                      gender: formattedGender,
                      category: category.name,
                    })
                : formatMessage(
                    messages.category_herren_or_damen_meta_description,
                    { gender: formattedGender }
                  )
            }
          />
        </Helmet>
      </ProductMode>
      <ProductMode type={PRODUCT_TYPES.BRAND}>
        <Helmet>
          <title>
            {
              // BRAND MIT KATEGORIE
              category
                ? gender
                  ? // BRAND KATEGORIE MIT GENDER
                    formatMessage(
                      messages.brand_with_category_and_gender_title,
                      {
                        brand:
                          brand.isSuccess && brand.data?.name
                            ? brand.data.name.toUpperCase()
                            : '',
                        category: category.name,
                        gender: formattedGender,
                      }
                    )
                  : // BRAND KATEGORIE OHNE GENDER
                    formatMessage(
                      messages.brand_with_category_no_gender_title,
                      {
                        brand:
                          brand.isSuccess && brand.data?.name
                            ? brand.data.name.toUpperCase()
                            : '',
                        category: category.name,
                      }
                    )
                : // BRAND OHNE KATEGORIE
                gender
                ? // BRAND MIT GENDER
                  formatMessage(messages.brand_only_with_gender_title, {
                    brand:
                      brand.isSuccess && brand.data?.name
                        ? brand.data.name.toUpperCase()
                        : '',
                    gender: formattedGender,
                  })
                : // BRAND OHNE GENDER
                  formatMessage(messages.brand_only_no_gender_title, {
                    brand:
                      brand.isSuccess && brand.data?.name
                        ? brand.data.name.toUpperCase()
                        : '',
                  })
            }
          </title>
          <meta
            name="description"
            content={
              category
                ? gender
                  ? formatMessage(
                      messages.brand_with_category_and_gender_meta_description,
                      {
                        brand:
                          brand.isSuccess && brand.data?.name
                            ? brand.data.name
                            : undefined,
                        category: category.name,
                        gender: formattedGender,
                      }
                    )
                  : formatMessage(
                      messages.brand_with_category_no_gender_meta_description,
                      {
                        brand:
                          brand.isSuccess && brand.data?.name
                            ? brand.data.name
                            : undefined,
                        category: category.name,
                      }
                    )
                : gender
                ? formatMessage(
                    messages.brand_only_with_gender_meta_description,
                    {
                      brand:
                        brand.isSuccess && brand.data?.name
                          ? brand.data.name
                          : undefined,
                      gender: formattedGender,
                    }
                  )
                : formatMessage(
                    messages.brand_only_no_gender_meta_description,
                    {
                      brand:
                        brand.isSuccess && brand.data?.name
                          ? brand.data.name
                          : undefined,
                    }
                  )
            }
          />
        </Helmet>
      </ProductMode>
      <ProductMode>
        {type =>
          type !== PRODUCT_TYPES.SEARCH ? (
            <HeroHeaderContainer type={type} />
          ) : (
            <h1
              {...css({
                fontWeight: '400',
                fontSize: 26,
                borderBottom: '0px solid #000',
                paddingBottom: 8,
              })}
            >
              Suchergebnisse für &raquo;
              {params.searchTerm}
              &laquo;
            </h1>
          )
        }
      </ProductMode>
      <ProductFilterBar isDisabled={products.isError} />
      {products.isError ? (
        // @todo specific handle errors
        products.error instanceof ky.TimeoutError ? (
          <ProductNoResults />
        ) : (
          <ProductNoResults />
        )
      ) : (
        <>
          {products.isSuccess && products.data.results.length > 0 && (
            <Pager
              current={queryParams.page}
              total={products.data.pages_total}
              onPrevious={queryParams.goToPrevPage}
              onNext={queryParams.goToNextPage}
              shouldStick
            />
          )}
          {products.isSuccess && products.data.results.length === 0 ? (
            <ProductNoResults />
          ) : (
            <div {...styles.productGrid}>
              {products.isLoading &&
                Array.from({ length: pageSize }, (_, index) => (
                  <ProductItemSkeleton key={index} />
                ))}
              {products.isSuccess && (
                <>
                  {products.data.results.map((item, index) => (
                    <ProductItem
                      key={item.id}
                      onSelect={handleSelectProduct}
                      onRequestClose={handleCloseProduct}
                      isOpen={selectedProduct === item.id}
                      item={item}
                      listIndex={index}
                    />
                  ))}
                  {products.data.results.length < pageSize &&
                    [2, 3, 4].map(columns => (
                      <Media key={columns} query={mqColumnSelector[columns]}>
                        {matches =>
                          matches &&
                          Array.from(
                            {
                              length:
                                columns -
                                (products.data.results.length % columns),
                            },
                            (_, i) => (
                              <ProductItemSkeleton
                                key={`orphan-${i}`}
                                style={{ visibility: 'hidden' }}
                              />
                            )
                          )
                        }
                      </Media>
                    ))}
                </>
              )}
            </div>
          )}
          {products.isSuccess && products.data.results.length > 0 && (
            <Pager
              current={queryParams.page}
              total={products.data.pages_total}
              onPrevious={queryParams.goToPrevPage}
              onNext={queryParams.goToNextPage}
            />
          )}
        </>
      )}
      {!isNativeApp && (
        <div {...css({ '@media (max-width: 375px)': { margin: '0 -50px' } })}>
          {products.isSuccess && products.data.facets.brand_id.length >= 5 && (
            <ProductMode type={PRODUCT_TYPES.CATEGORY}>
              <PopularBrands
                facetBrands={products.data.facets.brand_id}
                category={category}
                gender={gender}
              />
            </ProductMode>
          )}
          {products.isSuccess && category && brand.data && (
            <ProductMode type={PRODUCT_TYPES.BRAND}>
              <PopularBrandCategories
                category={category}
                gender={gender}
                selectedBrand={brand.data}
              />
            </ProductMode>
          )}
        </div>
      )}
      {descriptionText.data && (
        <ProductMode type={PRODUCT_TYPES.CATEGORY}>
          <CategoryDescriptionText
            center
            justify
            title={category ? category.name : formattedGender}
            text={descriptionText.data}
          />
        </ProductMode>
      )}

      {descriptionText.data && brand.data && (
        <ProductMode type={PRODUCT_TYPES.BRAND}>
          <CategoryDescriptionText
            center
            justify
            title={[
              brand.data.name,
              category ? category.name : undefined,
              gender,
            ].join(' ')}
            text={descriptionText.data}
          />
        </ProductMode>
      )}
    </div>
  )
}

const styles = {
  wrapper: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  }),
  productGrid: css({
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between',
    margin: `0 -${GUTTER}px`,
  }),
}

const messages = defineMessages({
  category_bekleidung_title:
    'Entdecke Luxus und Designer {gender} Bekleidung auf Maprodo',
  category_bekleidung_meta_description:
    'Entdecke aktuelle und ausgewählte {gender} Bekleidung im Online Shop auf Maprodo! Luxusmode, High Fashion und Designermode für {gender}. » Jetzt shoppen «',

  category_herren_or_damen_title:
    'Entdecke Luxus und Designer {gender}mode auf Maprodo',
  category_herren_or_damen_meta_description:
    'Entdecke aktuelle und ausgewählte Designer {gender}mode im Online Shop auf Maprodo! Luxusmode, High Fashion und Designermode für {gender}. » Jetzt shoppen «',

  category_title: 'Luxus {gender} {category} im Online Shop auf Maprodo',
  category_meta_description:
    'Entdecke aktuelle Luxus {gender} {category} von ausgewählten Designern und Marken im Online Shop auf Maprodo! Dress different. » Jetzt {category} shoppen «',

  brand_with_category_and_gender_title:
    '{brand} {category} für {gender} entdecken auf Maprodo',
  brand_with_category_and_gender_meta_description:
    'Entdecke aktuelle {brand} {category} für {gender} im Online Shop auf Maprodo! Aktuelle {category} von {brand}. » Jetzt {brand} {category} shoppen «',

  brand_with_category_no_gender_title:
    'Aktuelle {brand} {category} entdecken auf Maprodo',
  brand_with_category_no_gender_meta_description:
    'Entdecke aktuelle {brand} {category} im Online Shop auf Maprodo! Aktuelle {category} Kollektionen von {brand}. » Jetzt {brand} {category} shoppen «',

  brand_only_with_gender_title:
    '{brand} Kollektionen für {gender} entdecken im Online Shop auf Maprodo',
  brand_only_with_gender_meta_description:
    'Entdecke {brand} Kollektionen für {gender} im Online Shop auf Maprodo! {brand} Luxusmode, High Fashion und Designermode online entdecken. » Jetzt shoppen «',

  brand_only_no_gender_title:
    'Aktuelle {brand} Kollektionen entdecken im Online Shop auf Maprodo',
  brand_only_no_gender_meta_description:
    'Entdecke aktuelle {brand} Kollektionen im Online Shop auf Maprodo! {brand} Luxusmode, High Fashion und Designermode online entdecken. » Jetzt shoppen «',
})

export default ProductList
