import React, { useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import DOMPurify from './utils/dompurify-isomorphic'

const getFilteredLinks = (node: HTMLElement) =>
  Array.from(node.querySelectorAll('a')).filter(
    (link) =>
      link.href.startsWith('https://www.maprodo.') ||
      link.hostname === window.location.hostname,
  )

interface ISanitizedHtmlProps {
  html: string
}

const SanitizedHtml = ({ html, ...props }: ISanitizedHtmlProps) => {
  const history = useHistory()
  const ref = useRef<HTMLDivElement>(null)

  const handleInternalClick = useCallback(
    (e) => {
      e.preventDefault()
      const newPath = new URL(e.target.href).pathname

      history.push(newPath)
    },
    [history],
  )

  useEffect(() => {
    if (!ref.current) return
    const links = getFilteredLinks(ref.current)
    links.forEach((node) => node.addEventListener('click', handleInternalClick))

    return () => {
      links.forEach((node) =>
        node.removeEventListener('click', handleInternalClick),
      )
    }
    // `html` is not a direct dependency but only indirectly reflected through the ref selector.
  }, [handleInternalClick, html])

  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
      {...props}
    />
  )
}

export default SanitizedHtml
