import buildUrl from 'build-url'
export const ACCESS_KEY = '5edae6f918f649b0e6782fbf552b19dd'
const categories = {
  weiblich: [
    '27weky56okebmq37',
    'z4mmeh5wqxdk6wg1',
    'qjxg6yr20oy2a321',
    'tywc4mq4p2ek70ba',
  ].join(),
  männlich: ['27weky56okebmq37', 'qjxg6yr20oy2a321', 'tywc4mq4p2ek70ba'].join(),
  neutral: [
    '27weky56okebmq37',
    'z4mmeh5wqxdk6wg1',
    'qjxg6yr20oy2a321',
    'tywc4mq4p2ek70ba',
  ],
}

const PRODUCT_URL = '/api/products'

export const buildProductDetailUrl = id => buildUrl(`${PRODUCT_URL}/${id}`)

export const buildApiUrl = (params: any = {}) =>
  buildUrl(`${PRODUCT_URL}`, {
    queryParams: {
      page_size: 24,
      faceting: ['brand_id', 'color', 'price'],
      faceting_limit: 0,
      category_id: categories[params.gender || 'neutral'],
      sorting: 'popularity',
      price_min: 50,
      ...params,
      format: 'json',
      locale: 'de_DE',
    },
  })
