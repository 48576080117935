import React from 'react'
import FilterBox from './FilterBox'
import { css } from 'glamor'
import { useCookies } from 'react-cookie'
import usePerPageSize, { PAGE_SIZES } from '../Product/hooks/usePerPageSize'

interface IPerPageProps {
  onSelect: (perPage: number) => void
}

export const PER_PAGE_COOKIE_NAME = 'user_pref_per_page'

const PerPage = ({ onSelect }: IPerPageProps) => {
  const [, setCookie] = useCookies([PER_PAGE_COOKIE_NAME])

  const pageSize = usePerPageSize()

  return (
    <FilterBox popoverWidth={130} label={`${pageSize} / Seite`} align="right">
      {PAGE_SIZES.map(num => (
        <div
          key={num}
          onClick={() => {
            onSelect(num)
            setCookie(PER_PAGE_COOKIE_NAME, num, { path: '/' })
          }}
          {...css({
            fontWeight: pageSize === num && 600,
            cursor: 'pointer',
          })}
        >
          {num} / Seite
        </div>
      ))}
    </FilterBox>
  )
}

export default PerPage
