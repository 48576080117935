import React, { FunctionComponent } from 'react'
import { css } from 'glamor'
import Logo from './Logo'

const Letterhead: FunctionComponent = ({ children }) => (
  <>
    <div
      {...css({
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px solid',
        marginTop: 50,
      })}
    >
      <div
        {...css({
          transform: 'translateY(4px)',
          background: '#fff',
          padding: '0px 5px',
        })}
      >
        <Logo />
      </div>
    </div>
    <div
      {...css({
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 600,
        margin: 30,
      })}
    >
      {children}
    </div>
  </>
)

export default Letterhead
