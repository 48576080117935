import React from 'react'
import { css } from 'glamor'
import { createMQ } from './Responsive'

const Fullpage = (props) => (
  <div
    {...css({
      height: `calc(100vh - 70px - 60px)`,
      [createMQ('desktop', 'mobile')]: {
        height: `calc(100vh - 100px - 60px)`,
      },
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    })}
    {...props}
  />
)

export default Fullpage
