import React, { ElementType, FunctionComponent, ReactNode } from 'react'
import { css } from 'glamor'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import TextTruncate from 'react-text-truncate'
import Button, { BORDER_RADIUS } from '../Button'
import HeartFilledIcon from '../icons/HeartFilled'
import HeartOutlineIcon from '../icons/HeartOutline'
import LinkButton from '../LinkButton'
import { createMQ } from '../Responsive'
import ProductDescription from './ProductDescription'
import { doTrack } from './ProductItem'
import useToggle from '../utils/hooks/useToggle'
import { Brand, Shop } from '../types'
import gold from '../utils/gold'
import { sendToNativeApp, useIsNativeApp } from '../utils/nativeApp'
import buildTrackingLink from '../utils/buildTrackingLink'

const styles = css({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  [createMQ('tablet', 'desktop')]: {
    maxWidth: '50%',
  },
})

const readmoreStyles = css({
  fontWeight: 500,
  cursor: 'pointer',
  color: gold,
})

interface IProductDetailsInfoProps {
  id: string
  brand: Brand
  title: string
  shop: Shop
  currency: string
  currentPrice: number
  oldPrice: number
  trackingLink: string
  text: string
  onClickHeart: (e: React.MouseEvent) => void
  isHeartActive: boolean
  sharingUrl: string
  image: string | null
}

const Share = ({
  component: Component,
  children,
  title,
  url,
}: {
  component: ElementType
  children: ReactNode
  title: string
  url: string
}) => {
  const isNativeApp = useIsNativeApp()

  if (isNativeApp) {
    return (
      <div onClick={() => sendToNativeApp('share', { title, url })}>
        {children}
      </div>
    )
  } else {
    return <Component url={url}>{children}</Component>
  }
}

export const ShareBar = ({ title, url }) => (
  <>
    <Share component={FacebookShareButton} title={title} url={url}>
      <FacebookIcon borderRadius={BORDER_RADIUS} size={32} />
    </Share>
    <Share component={TwitterShareButton} title={title} url={url}>
      <TwitterIcon borderRadius={BORDER_RADIUS} size={32} />
    </Share>
    <Share component={TelegramShareButton} title={title} url={url}>
      <TelegramIcon borderRadius={BORDER_RADIUS} size={32} />
    </Share>
    <Share component={WhatsappShareButton} title={title} url={url}>
      <WhatsappIcon borderRadius={BORDER_RADIUS} size={32} />
    </Share>
    <Share component={EmailShareButton} title={title} url={url}>
      <EmailIcon borderRadius={BORDER_RADIUS} size={32} />
    </Share>
  </>
)

const ProductDetailsInfo: FunctionComponent<IProductDetailsInfoProps> = ({
  id,
  brand,
  shop,
  title,
  currentPrice,
  oldPrice,
  currency,
  text,
  onClickHeart,
  isHeartActive,
  trackingLink,
  sharingUrl,
}) => {
  const [isTruncated, toggleIsTruncated] = useToggle(false)
  const isNativeApp = useIsNativeApp()

  const shareTitle = `Schau dir das an: ${brand.name} ${title}`

  return (
    <div {...styles}>
      <ProductDescription
        brand={brand}
        title={title}
        price={currentPrice}
        priceOld={oldPrice}
        currency={currency}
        titleEllipsis={false}
        // showBrand
        size="xl"
      />
      <div
        {...css({
          margin: '20px 0',
          userSelect: 'none',
          '> *': { margin: 3 },
          '> :first-child': { marginLeft: 0 },
          [createMQ('mobile')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          },
        })}
      >
        <LinkButton
          onClick={e => {
            doTrack()
            if (isNativeApp) {
              e.preventDefault()
              console.warn('Native app open tracking link', id)
              sendToNativeApp('goToProduct', trackingLink)
            }
          }}
          to={buildTrackingLink(id)}
          linkProps={{ target: '_blank', rel: 'nofollow noopener' }}
          contrast
          //{...css({ [createMQ('tablet', 'desktop')]: { marginRight: 50 } })}
        >
          {isNativeApp ? 'Jetzt Kaufen' : 'Zum Shop'}
        </LinkButton>
        <Button onClick={onClickHeart}>
          <div
            {...css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <div {...css({ transform: 'translateY(2px)' })}>
              {React.createElement(
                isHeartActive ? HeartFilledIcon : HeartOutlineIcon,
                { width: 13, height: 13, ...css({ marginRight: 10 }) }
              )}
            </div>
            <div>Merken</div>
          </div>
        </Button>
        {isNativeApp && (
          <Button
            onClick={() =>
              sendToNativeApp('share', {
                title: shareTitle,
                url: sharingUrl,
              })
            }
          >
            Teilen
          </Button>
        )}
      </div>
      <div {...css({ lineHeight: '18px' })}>
        <div
          {...css({ [createMQ('mobile')]: { textAlign: 'center' }, margin: 0 })}
        >
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            onClick={e => {
              doTrack()
              if (isNativeApp) {
                e.preventDefault()
                console.warn('Native app open tracking link', id)
                sendToNativeApp('goToProduct', trackingLink)
              }
            }}
            href={buildTrackingLink(id)}
            style={{ color: gold, fontWeight: 600 }}
            target="_blank"
            rel="nofollow noopener"
          >
            Weitere Bilder bei {shop.name}
          </a>
        </div>
        <br />
        {!isTruncated ? (
          <TextTruncate
            line={4}
            truncateText="…"
            textTruncateChild={
              <span onClick={toggleIsTruncated} {...readmoreStyles}>
                weiterlesen
              </span>
            }
            text={text}
          />
        ) : (
          <>
            {text}
            <div onClick={toggleIsTruncated} {...readmoreStyles}>
              weniger anzeigen
            </div>
          </>
        )}
      </div>
      {!isNativeApp && (
        <div
          {...css({
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
            [createMQ('mobile')]: {
              justifyContent: 'space-between',
              // rare case, if row does not fit into page (~ <350px)
              flexWrap: 'wrap',
            },
            '& .react-share__ShareButton': {
              transition: '250ms',
              cursor: 'pointer',
              [createMQ('desktop', 'tablet')]: {
                marginRight: 20,
                opacity: 0.6,
                ':hover': {
                  opacity: 1,
                },
              },
            },
          })}
        >
          <ShareBar title={shareTitle} url={sharingUrl} />
        </div>
      )}
    </div>
  )
}

export default ProductDetailsInfo
