import React, { FunctionComponent } from 'react'
import { css } from 'glamor'

import { LoadingSkeleton } from './ProductItem'
import PriceText from './PriceText'
import { Brand } from '../types'

interface IProductTitleInfoProps {
  title: string
  brandName: string
  price: number
  priceOld: number
  currency: string
  size: 'l' | 'xl'
  titleEllipsis: boolean
}

interface IProductDescriptionProps
  extends Omit<IProductTitleInfoProps, 'brandName'> {
  brand: Brand
}

const styles = {
  brandText: (size: 'l' | 'xl') =>
    css({
      fontSize: { l: 13, xl: 18 }[size],
      fontWeight: 700,
      textTransform: 'uppercase',
    }),
  brandSpacer: css({
    height: 2,
    width: 25,
    margin: '6px 0',
    background: '#000',
  }),
  titleText: (size: 'l' | 'xl', titleEllipsis?: boolean) =>
    css({
      fontSize: { l: 14, xl: 18 }[size],
      fontWeight: 400,
      whiteSpace: titleEllipsis && 'nowrap',
      overflow: titleEllipsis && 'hidden',
      textOverflow: titleEllipsis && 'ellipsis',
    }),
  priceText: (size: 'l' | 'xl') =>
    css({
      fontSize: { l: 13, xl: 18 }[size],
      display: 'flex',
      paddingTop: 5,
    }),
}

const removeBrandFromTitle = (title: string, brand: string) =>
  title.replace(new RegExp(`^${brand}[\\s-;]*`, 'i'), '')

export const ProductTitleInfo: FunctionComponent<IProductTitleInfoProps> = ({
  title,
  brandName,
  titleEllipsis,
  price,
  priceOld,
  currency,
  size,
}) => (
  <div {...css({ flex: '1 1 auto', width: '100%' })}>
    <div {...styles.brandText(size)}>{brandName}</div>
    <div {...styles.brandSpacer} />
    <div {...styles.titleText(size, titleEllipsis)} title={title}>
      {removeBrandFromTitle(title, brandName)}
    </div>
    <div {...styles.priceText(size)}>
      <PriceText price={price} currency={currency} />
      {priceOld > 0 && (
        <div {...css({ paddingLeft: 10 })}>
          <PriceText price={priceOld} currency={currency} isOld />
        </div>
      )}
    </div>
  </div>
)

export const ProductDescriptionLoadingSkeleton: FunctionComponent = () => (
  <>
    <div {...styles.brandText('l')}>
      <LoadingSkeleton width={100} />
    </div>
    <div {...styles.brandSpacer} />
    <div {...styles.titleText('l')}>
      <LoadingSkeleton width={200} />
    </div>
    <div {...styles.priceText('l')}>
      <LoadingSkeleton width={60} />
    </div>
  </>
)

const ProductDescription: FunctionComponent<IProductDescriptionProps> = ({
  brand,
  title,
  price,
  priceOld,
  currency,
  size = 'l',
  titleEllipsis = true,
  // showBrand = false,
}) => (
  <div {...css({ display: 'flex', justifyContent: 'space-between' })}>
    <ProductTitleInfo
      brandName={brand.name}
      title={title}
      price={price}
      priceOld={priceOld}
      currency={currency}
      size={size}
      titleEllipsis={titleEllipsis}
    />
    {/** @todo Brand images not provided by API anymore!? **/}
    {/*showBrand && brand.id && (
        <div {...css({ flex: '0 1 100px' })}>
          <BrandImage brand={brand} />
        </div>
      )*/}
  </div>
)

export default ProductDescription
