import React from 'react'

import { useGroupedBrands } from '../Brand/BrandOverview'
import CategoryHeading from '../Category/CategoryHeading'
import CategoryItem from '../Category/CategoryItem'
import CategoryList from '../Category/CategoryList'
import MagazineSuggestionHeader from './MagazineSuggestionHeader'
import { useSidebarContext } from './SidebarProvider'

const BrandMenu = () => {
  const brands = useGroupedBrands()
  const { close } = useSidebarContext()

  return (
    <div>
      <MagazineSuggestionHeader />
      <CategoryList>
        {brands.isSuccess &&
          Object.entries(brands.data).map(([char, brands]) => (
            <div key={char}>
              <CategoryHeading name={char} />
              {brands.map(brand => (
                <CategoryItem
                  key={brand._slug}
                  path={`/marken/${brand._slug}`}
                  onClick={close}
                >
                  {brand.name}
                </CategoryItem>
              ))}
            </div>
          ))}
      </CategoryList>
    </div>
  )
}

export default BrandMenu
