import React, { FunctionComponent } from 'react'
import { css } from 'glamor'

export interface ITitleSectionProps {
  size?: 's' | 'm' | 'l'
  appearance?: 'normal' | 'chic'
}

const TitleSection: FunctionComponent<ITitleSectionProps> = ({
  children,
  size = 'm',
  appearance = 'normal',
}) => (
  <div {...css({ borderBottom: '1px solid' })}>
    <span
      {...css({
        transform: `translateX(20px) translateY(${
          { s: 6, m: 6, l: 10 }[size]
        }px)`,
        background: '#fff',
        display: 'inline-block',
        fontSize: { s: 15, m: 20, l: 30 }[size],
        fontWeight: 400,
        textTransform: 'uppercase',
        padding: '0 7px 0px 7px',
        fontFamily: appearance === 'chic' && `'Playfair Display', serif`,
        marginBottom: 1,
      })}
    >
      {children}
    </span>
  </div>
)

export default TitleSection
