import {
  BooleanParam,
  decodeDelimitedArray,
  encodeDelimitedArray,
  NumberParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'
import { useCallback } from 'react'

const toggleArrayItem = (arr, val) => {
  const i = arr.indexOf(val)
  arr = Array.isArray(arr) ? arr : [arr]
  const copy = arr.slice()
  if (i === -1) copy.push(val)
  else copy.splice(i, 1)

  return copy
}

const DotArrayParam = {
  encode: array => encodeDelimitedArray(array, '.'),
  decode: array => decodeDelimitedArray(array, '.'),
}

export const queryParamConfig = {
  price_min: ['min_preis', NumberParam],
  price_max: ['max_preis', NumberParam],
  sale: ['sale', BooleanParam],
  page: ['seite', NumberParam],
  gender: ['geschlecht', StringParam],
  brand_id: ['marken', DotArrayParam],
  color: ['farben', DotArrayParam],
  sorting: ['sortierung', StringParam],
  page_size: ['pro_seite', NumberParam],
} as const

const useQueryFilterParams = () => {
  const qPC = queryParamConfig
  const [price_min, setPriceMin] = useQueryParam(...qPC.price_min)
  const [price_max, setPriceMax] = useQueryParam(...qPC.price_max)
  const [sale, setSale] = useQueryParam(...qPC.sale)
  const [page = 1, setPage] = useQueryParam(...qPC.page)
  const [gender, setGender] = useQueryParam(...qPC.gender)
  const [color = [], setColors] = useQueryParam(...qPC.color)
  const [perPage = 24, setPerPage] = useQueryParam(...qPC.page_size)
  const [brand_id = [], setBrandId] = useQueryParam(...qPC.brand_id)
  const [sorting = 'popularity', setSorting] = useQueryParam(...qPC.sorting)

  const pushPage = useCallback(page => setPage(page, 'pushIn'), [setPage])

  const selectBrand = useCallback(
    val => {
      page > 1 && pushPage(undefined)
      setBrandId(toggleArrayItem(brand_id, val))
    },
    [brand_id, page, pushPage, setBrandId]
  )

  const clearBrand = useCallback(() => {
    page > 1 && pushPage(undefined)
    setBrandId([])
  }, [page, pushPage, setBrandId])

  const selectColor = useCallback(
    val => {
      page > 1 && pushPage(undefined)
      setColors(toggleArrayItem(color, val))
    },
    [color, page, pushPage, setColors]
  )

  const clearColor = useCallback(() => {
    page > 1 && pushPage(undefined)
    setColors([])
  }, [page, pushPage, setColors])

  const setMinPrice = useCallback(
    (min: number | undefined) => {
      page > 1 && pushPage(undefined)
      setPriceMin(min)
    },
    [page, pushPage, setPriceMin]
  )

  const setMaxPrice = useCallback(
    (max: number | undefined) => {
      page > 1 && pushPage(undefined)
      setPriceMax(max)
    },
    [page, pushPage, setPriceMax]
  )

  const clearPriceFilter = useCallback(() => {
    page > 1 && pushPage(undefined)
    setPriceMin(undefined)
    setPriceMax(undefined)
    setSale(undefined)
  }, [page, pushPage, setPriceMax, setPriceMin, setSale])

  const selectGender = useCallback(
    val => {
      page > 1 && pushPage(undefined)
      setGender(val)
    },
    [page, pushPage, setGender]
  )

  const selectPerPage = useCallback(
    val => {
      setPerPage(val)
    },
    [setPerPage]
  )

  const removeFilters = useCallback(() => {
    setPage(undefined, 'replace')
  }, [setPage])

  const goToNextPage = useCallback(() => {
    pushPage(page + 1)
  }, [page, pushPage])

  const goToPrevPage = useCallback(() => {
    page > 1 && pushPage(page - 1)
  }, [page, pushPage])

  const hasSelectedFilters =
    price_min || price_max || brand_id.length || color.length || sale || gender

  return {
    hasSelectedFilters,
    price_min,
    price_max,
    brand_id,
    color,
    sale,
    sorting,
    page,
    perPage,
    gender,
    selectBrand,
    clearBrand,
    selectColor,
    clearColor,
    selectGender,
    selectPerPage,
    setMinPrice,
    setMaxPrice,
    clearPriceFilter,
    setSorting,
    setSale,
    removeFilters,
    goToNextPage,
    goToPrevPage,
  }
}

export default useQueryFilterParams
