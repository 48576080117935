import React, { Fragment, useCallback } from 'react'

import { doTrack } from '../Product/ProductItem'
import SidebarWrapper from './SidebarWrapper'
import { css } from 'glamor'
import { Divider, Headline } from './FilterSidebar'
import { ProductTitleInfo } from '../Product/ProductDescription'
import CloseIcon from '../icons/Close'
import EmptyWishlistIllustration from '../assets/empty-wishlist.svg'
import buildTrackingLink from '../utils/buildTrackingLink'
import useIsClient from '../utils/hooks/useIsClient'
import {
  removeFromWishlist,
  WishlistProduct,
  wishlistStore,
} from '../wishlistStore'
import { sendToNativeApp, useIsNativeApp } from '../utils/nativeApp'
import LinkButton from '../LinkButton'
import { is } from '../Responsive'

const styles = {
  wishlistWrapper: css({
    background: '#fff',
    minHeight: '100%',
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
  }),
  removeWrapper: css({
    cursor: 'pointer',
    alignSelf: 'center',
    fontWeight: 'bold',
    padding: 20,
    userSelect: 'none',
    borderLeft: '1px solid #000',
  }),
  wishlistEmptyWrapper: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}

const ItemList = ({ items }: { items: WishlistProduct[] }) => {
  const isNativeApp = useIsNativeApp()
  const handleItemClick = useCallback(
    (item: WishlistProduct) => e => {
      doTrack()
      if (isNativeApp) {
        e.preventDefault()
        sendToNativeApp('goToProduct', item.tracking)
      }
    },
    [isNativeApp]
  )

  return (
    <>
      {items.map((item, i) => (
        <Fragment key={item.id}>
          <div {...css({ display: 'flex', flexDirection: 'row' })}>
            <a
              onClick={handleItemClick(item)}
              href={buildTrackingLink(item.id)}
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              <img
                src={item.images.url}
                width={90}
                height={90}
                alt={item.title}
              />
            </a>
            <a
              {...css({
                display: 'block',
                width: '100%',
                overflow: 'hidden',
              })}
              onClick={handleItemClick(item)}
              href={buildTrackingLink(item.id)}
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              <ProductTitleInfo
                brandName={item.brand.name}
                title={item.title}
                price={item.list_price.current}
                priceOld={item.list_price.old}
                currency={item.list_price.currency}
                titleEllipsis
                size="l"
                // maxWidth={
                //   is('desktop')
                //     ? 280
                //     : is('tablet') && !is('mobile')
                //     ? 400
                //     : undefined
                // }
              />
            </a>
            <div
              {...styles.removeWrapper}
              title="Entfernen"
              onClick={() => removeFromWishlist(item)}
            >
              <CloseIcon />
            </div>
          </div>
          {i < items.length - 1 && <Divider />}
        </Fragment>
      ))}
    </>
  )
}

interface IWishlistSidebarProps {
  open: boolean
  width: string
  onRequestClose?: () => void
}

export const WishlistInner = ({
  onRequestClose,
}: {
  onRequestClose?: () => void
}) => {
  const items = wishlistStore.useSelector(items => Object.values(items))
  const isNativeApp = useIsNativeApp()

  return (
    <div {...css(styles.wishlistWrapper, { minHeight: '100vh' })}>
      <Headline onClickBack={onRequestClose}>Deine Wunschliste</Headline>
      {items.length > 0 ? (
        <ItemList items={items} />
      ) : (
        <div {...styles.wishlistEmptyWrapper}>
          <img
            src={EmptyWishlistIllustration}
            style={{ width: '50%', maxWidth: 350, opacity: 3 / 4 }}
            alt=""
          />
          <div
            {...css({ margin: 20, textAlign: 'center', '> *': { margin: 25 } })}
          >
            <div {...css({ fontSize: 22, fontWeight: 600 })}>
              Deine Wunschliste ist leer.
            </div>
            <div {...css({ fontSize: 18 })}>
              Nutze das Herz, um Artikel zu deiner Wunschliste hinzuzufügen.
            </div>
          </div>
          {
            <div
              {...css({
                visibility: !isNativeApp && 'hidden',
                fontWeight: 500,
              })}
            >
              <LinkButton
                to={`/kategorien?deviceType=${
                  is('tablet', 'desktop')
                    ? 'tablet'
                    : is('mobile')
                    ? 'handset'
                    : ''
                }`}
                contrast
              >
                <div style={{ margin: 5 }}>Jetzt shoppen</div>
              </LinkButton>
            </div>
          }
        </div>
      )}
    </div>
  )
}

const WishlistSidebar = ({
  open,
  width,
  onRequestClose,
}: IWishlistSidebarProps) => {
  const isClient = useIsClient()

  return isClient ? (
    <SidebarWrapper position="right" width={width} open={open}>
      <WishlistInner onRequestClose={onRequestClose} />
    </SidebarWrapper>
  ) : null
}

export default WishlistSidebar
