import React, { FunctionComponent } from 'react'
import { css } from 'glamor'
import Tick from '../Tick'

const styles = {
  checkbox: css({
    opacity: 0,
    position: 'absolute',
  }),
  label: css({
    display: 'flex',
  }),
  labelText: css({
    marginLeft: 6,
    fontSize: 13,
    transform: 'translateY(1px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  tick: css({
    flexShrink: 0,
    width: 16,
    height: 16,
  }),
}

export interface ICheckboxProps {
  label: string
  value: string
  onChange: (value: string, checked: boolean) => void
  checked?: boolean
}

const Checkbox: FunctionComponent<ICheckboxProps> = ({
  label,
  value,
  checked,
  onChange,
}) => (
  <div>
    <label {...styles.label}>
      <input
        {...styles.checkbox}
        type="checkbox"
        name={value}
        value={value}
        checked={checked}
        onChange={e => onChange(value, e.target.checked)}
      />
      <Tick checked={checked} {...styles.tick} />
      <div {...css(styles.labelText, checked && { fontWeight: 500 })}>
        {label}
      </div>
    </label>
  </div>
)

export default React.memo(Checkbox)
