import React from 'react'
import { css } from 'glamor'
import Letterhead from '../Letterhead'
import SanitizedHtml from '../SanitizedHtml'
import darkerGold from '../utils/darkerGold'
import { useIsNativeApp } from '../utils/nativeApp'

interface ICategoryDescriptionTextProps {
  text: string
  title?: string
  justify?: boolean
  center?: boolean
}

const CategoryDescriptionText = ({
  justify,
  title,
  text,
  center,
}: ICategoryDescriptionTextProps) =>
  useIsNativeApp() ? null : (
    <div>
      <Letterhead>{title}</Letterhead>
      <div
        {...css({
          textAlign: justify && 'justify',
          textAlignLast: center && 'center',
        })}
      >
        <SanitizedHtml
          html={text}
          {...css({
            fontSize: 13,
            letterSpacing: 0,
            '& a': {
              textDecoration: 'underline !important',
              color: `${darkerGold} !important`,
              fontWeight: '400 !important',
              letterSpacing: 0.5,
            },
            '> p': {
              wordBreak: 'break-word',
            },
            '> h1': {
              textTransform: 'uppercase',
              textAlign: 'center',
              fontSize: 20,
              fontWeight: 600,
              margin: 30,
            },
            '> h2': {
              fontSize: 16,
              textAlign: center && 'center',
              fontWeight: 600,
            },
            '> h3': {
              fontSize: 14,
              textAlign: center && 'center',
              fontWeight: 600,
            },
          })}
        />
      </div>
    </div>
  )

export default CategoryDescriptionText
