import memoize from 'memoizee'
import TreeModel, { Node } from 'tree-model'

import Tree from '../tree.json'

interface ImportedCategory {
  id: string
  slug: string
  name: string
  depth: number
  children: readonly ImportedCategory[]
}

export const getSlug = (path: string) =>
  path.split('/').slice(2).pop() as string

export const getFromPathname = (pathname: string) =>
  pathname
    // trim slashes
    .replace(/^\/|\/$/g, '')
    .split('/')
    .slice(1)

const treeModel = new TreeModel()

const parsedTree = treeModel.parse<ImportedCategory>(Tree)

// e.g. findPath === ['bekleidung', 'waesche', 'unterhosen', 'hipster']
export const getCategoryPath = (findPath: string[] = []) => {
  let out: ImportedCategory[] = []
  const len = findPath.length

  parsedTree.walk(node => {
    if (node.model.slug === findPath[len - 1]) {
      out = node
        .getPath()
        .slice(1)
        .map(item => item.model)

      return false
    }
    return true
  })

  return out
}

export const getCategoryFromPathname = (pathname: string) =>
  getCategoryPath(getFromPathname(pathname)).pop()

export const getTopLevelTree = whitelist => {
  const categories: any = []

  parsedTree.walk(node => {
    if (
      node.model.depth > 0 &&
      whitelist.includes(node.model.id) &&
      node.model.depth < 2
    ) {
      const { children, ...rest } = node.model

      // could do better job filtering the children by whitelist here ...
      categories.push({
        ...rest,
        children: children
          .filter(child => whitelist.includes(child.id))
          .map(({ children, ...rest }) => ({
            ...rest,
            children: children.filter(child => whitelist.includes(child.id)),
          })),
      })
    }
    return true
  })

  return categories
}

interface NestedCategory {
  parent: ImportedCategory & {
    path: string
    leaf: boolean
  }
  children: (ImportedCategory & {
    path: string
    leaf: boolean
  })[]
}

const getCategoriesByImpl = (
  test: (node: Node<ImportedCategory>) => boolean,
  whitelist: string[],
  pathPrefix = ''
): NestedCategory[] => {
  let categories = []
  const whitelistFilter = child =>
    child.depth <= 1 || whitelist.includes(child.id)

  parsedTree.walk(node => {
    if (test(node)) {
      let basePath = pathPrefix
      categories = node.getPath().reduce((acc: any, item: typeof node) => {
        const {
          children: nodeChildren,
          ...category
        } = item.model as ImportedCategory
        basePath += category.depth > 0 ? `/${category.slug}` : ''

        const parent = {
          ...category,
          path: basePath,
          leaf: nodeChildren.length === 0,
        }

        const children = nodeChildren
          // drop everything not inside the whitelist
          .filter(element => whitelistFilter(element))
          // drop children from node and add full path
          .map(({ children, ...child }) => ({
            ...child,
            leaf:
              children.filter(element => whitelistFilter(element)).length === 0,
            path: `${basePath}/${child.slug}`,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))

        return children.length ? [...acc, { parent, children }] : acc
      }, [])
      return false
    }
    return true
  })

  return categories
}

const getCategoriesBy = memoize(getCategoriesByImpl)

export const getCategoriesBySlug = (
  slug: string,
  whitelist: string[],
  pathPrefix?: string
) =>
  getCategoriesBy(
    node => node.model.slug === slug || !slug,
    whitelist,
    pathPrefix
  )

// const __getCategoriesBySlugImpl = (slug, whitelist, pathPrefix = '') => {
//   let categories = []
//   const whitelistFilter = child =>
//     child.depth <= 1 || whitelist.includes(child.id)
//
//   walk(Tree, node => {
//     // if no slug was provided bail out on upmost level
//     if (node.model.slug === slug || !slug) {
//       let basePath = pathPrefix
//       categories = node.getPath().reduce((acc, item) => {
//         const { children: nodeChildren, ...category } = item.model
//         basePath += category.depth > 0 ? `/${category.slug}` : ''
//
//         const parent = {
//           ...category,
//           path: basePath,
//           leaf: nodeChildren === 0,
//         }
//
//         const children = nodeChildren
//           // drop everything not inside the whitelist
//           .filter(whitelistFilter)
//           // drop children from node and add full path
//           .map(({ children, ...child }) => ({
//             ...child,
//             children: children.filter(whitelistFilter),
//             leaf: children.filter(whitelistFilter).length === 0,
//             path: `${basePath}/${child.slug}`,
//           }))
//           .sort((a, b) => a.name.localeCompare(b.name))
//
//         return children.length ? [...acc, { parent, children }] : acc
//       }, [])
//       return false
//     }
//   })
//
//   return categories
// }
//
// export const getCategoriesBySlug = memoize(getCategoriesBySlugImpl)
