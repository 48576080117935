import React, { useCallback, useEffect, useRef, useState } from 'react'
import Collapsible from 'react-collapsible'
import { css, keyframes } from 'glamor'
import { deepEqual } from 'fast-equals'
import { LazyImage } from 'react-lazy-images'

import LogoImg from '../assets/logo.svg'
import HeartFilledIcon from '../icons/HeartFilled'
import CloseIcon from '../icons/Close'
import Responsive, { createMQ, is } from '../Responsive'
import decodeHtmlChars from '../utils/decodeHtmlChars'
import ProductDescription, {
  ProductDescriptionLoadingSkeleton,
} from './ProductDescription'
import buildTrackingLink from '../utils/buildTrackingLink'
import { getSharingUrl } from './ProductDetailPage'
import ProductDetailsInfo from './ProductDetailsInfo'
import ProductImage from './ProductImage'
import { Product } from '../types'
import { toggleWishlistItem, useAddedToWishlist } from '../wishlistStore'
import { sendToNativeApp, useIsNativeApp } from '../utils/nativeApp'
import { useInitialUrlContext } from '../UrlContext'

export const GUTTER = 10

const skeletonPulsate = keyframes('pulsate', {
  '0%': { background: '#f8f8f8' },
  '50%': { background: '#f5f5f5' },
  '75%': { background: '#f8f8f8' },
})

export const logoPulsate = keyframes('logo-pulsate', {
  '0%': { transform: 'scale(1)' },
  '50%': { transform: 'scale(1.25)' },
  '100%': { transform: 'scale(1)' },
})

const heartPulsate = keyframes('heart-pulsate', {
  '0%': { transform: 'scale(1)' },
  '50%': { transform: 'scale(1.25)' },
  '100%': { transform: 'scale(1)' },
})

const productInfoClass = 'product-detail'

/*
Research:

Je nach Anzahl der Spalten und geklicktem Element in der Reihe muss das angezeigte Element um x nach
links verschoben werden und die Breite um die Anzahl der Spalten erhöht werden

1 spalte: max-width: 519px
2 spalten: min-width: 520px, max-width: 739px
3 spalten: min-width: 740px, max-width: 1159px && min-width: 1201px, max-width: 1359px
4 spalten: min-width: 1160px, max-width: 1200px && min-width: 1360px

1 Spalte
[data-css-1y21nq9] {
}

2 Spalten (2. Element):
[data-css-theulk]:nth-of-type(2n) [data-css-1y21nq9] {
  width: calc(200% + 1 * 20px);
  margin-left: calc(-100% - 1 * 20px);
}

3 Spalten (2. Element):
[data-css-theulk]:nth-of-type(3n-1) [data-css-wv9s7h] {
  width: calc(300% + 2 * 20px);
  margin-left: calc(-100% - 1 * 20px);
}

4 Spalten (2. Element):
[data-css-theulk]:nth-of-type(4n-2) [data-css-wv9s7h] {
  width: calc(400% + 3 * 20px);
  margin-left: calc(-100% - 1 * 20px); // element * -100
}

===>

breite:  spalten * 100% + (spalten - 1) * 20px
abstand links: ((element - 1) * -100%) - ((element - 1) * 20px)
 */

// unfortunately needs to be here rather than on the parent,
// couldn't get it running on the parent 😢
const generateColumnSelectors = (columns: number) => {
  const selectors = Array.from({ length: columns }, (_, i) => {
    const element = i + 1
    const relativeElement = columns - element

    return {
      [`:nth-child(${columns}n-${relativeElement}) .${productInfoClass}`]: {
        width: `calc(${columns * 100}% + ${(columns - 1) * (GUTTER * 2)}px)`,
        marginLeft: `calc(${i * -100}% - ${i * GUTTER * 2}px)`,
      },
    }
  })

  // 'flatten' [ { foo: 1}, { bar: 2 }, ...] => { foo:1 , bar: 2 }
  return Object.assign({}, ...selectors)
}

export const mqColumnSelector = {
  2: '(min-width: 520px) and (max-width: 739px)',
  3: '(min-width: 740px) and (max-width: 1159px), (min-width: 1201px) and (max-width: 1359px)',
  4: '(min-width: 1160px) and (max-width: 1200px), (min-width: 1360px)',
}

const mediaQueries = {
  // 1 column (no styles needed)
  // '@media (max-width: 519px)': {},

  // 2 columns
  [`@media ${mqColumnSelector[2]}`]: generateColumnSelectors(2),

  // 3 columns
  [`@media ${mqColumnSelector[3]}`]: generateColumnSelectors(3),

  // 4 columns
  [`@media ${mqColumnSelector[4]}`]: generateColumnSelectors(4),
}

const styles = {
  wrapper: css(mediaQueries, {
    margin: `30px ${GUTTER}px`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
    maxWidth: 'calc(100vw - 100px)',
    flex: 1,
    flexBasis: 200,
  }),
  productContainer: (isOpen?: boolean) =>
    css({
      transition: 'transform 150ms ease-in-out',
      transform: isOpen && 'scale3d(1.06, 1.06, 1.06)',
      [createMQ('desktop')]: {
        ':hover': {
          transform: !isOpen && 'scale3d(1.06, 1.06, 1.06)',
        },
      },
    }),
  wrapperWithoutDetails: css({ position: 'relative', cursor: 'pointer' }),
  imageWrapper: css({
    minHeight: 220,
    display: 'flex',
    justifyContent: 'center',
  }),
  shopLinkAndHeart: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(255, 255, 255, .5)',
    padding: 8,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  }),
  heartIcon: css({
    cursor: 'pointer',
    [createMQ('desktop')]: {
      ':hover': {
        animation: `1s ${heartPulsate} infinite`,
      },
    },
  }),
  descriptionWrapper: css({
    position: 'relative',
    borderTop: '2px solid #000',
    padding: '0 10px',
    paddingTop: 13,
    cursor: 'pointer',
  }),
  triangle: (isOpen?: boolean) =>
    css({
      ':after': {
        content: `''`,
        position: 'absolute',
        opacity: isOpen ? 1 : 0,
        transition: 'opacity 250ms',
        transform: 'translateY(11px)',
        left: 25,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 7.5px 11px 7.5px',
        borderColor: 'transparent transparent #000000 transparent',
      },
    }),
  productDetailsWrapper: (isOpen?: boolean) =>
    css({
      paddingTop: isOpen && 22 + GUTTER,
      transitionProperty: 'padding-top',
      transitionDelay: !isOpen && '250ms',
      display: !isOpen && 'none',
    }),
  productDetails: css({
    display: 'flex',
    position: 'relative',
    borderRadius: 6,
    border: '4px solid #000',
    padding: 15,
    justifyContent: 'space-around',
    alignItems: 'center',
    [createMQ('mobile')]: {
      flexWrap: 'wrap',
    },
    // at 520px two products will be shown in one row
    // before that give the products more room to breath
    [`@media (max-width: 520px)`]: {
      margin: '0 -30px',
    },
  }),
  productDetailsClose: css({
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 10,
  }),
  skeleton: (width) =>
    css({
      height: 15,
      width,
      background: '#f8f8f8',
      animation: `${skeletonPulsate} 1.5s infinite`,
    }),
  imageSkeleton: css({
    width: 220,
    height: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  logoPulsate: css({
    animation: `${logoPulsate} 1.5s infinite`,
  }),
}

export const doTrack = () => {
  window.fbq('track', 'InitiateCheckout')
  window.gtag('event', 'conversion')
}

export const LoadingSkeleton = ({ width }) => (
  <div {...styles.skeleton(width)} />
)

const Placeholder = React.forwardRef<HTMLDivElement, {}>((_props, ref) => (
  <div ref={ref} {...styles.imageSkeleton}>
    <img src={LogoImg} width={34} height={28} alt="" {...styles.logoPulsate} />
  </div>
))

export const ProductItemSkeleton = (props) => (
  <div {...styles.wrapper} {...props}>
    <div {...css({ position: 'relative' })}>
      <div {...styles.imageWrapper}>
        <Placeholder />
      </div>
      <div {...styles.shopLinkAndHeart}>
        <LoadingSkeleton width={75} />
      </div>
    </div>
    <div {...styles.descriptionWrapper}>
      <ProductDescriptionLoadingSkeleton />
    </div>
  </div>
)

interface IProductItemProps {
  onSelect: (itemId: string) => void
  onRequestClose?: () => void
  isOpen: boolean
  item: Product
  listIndex: number
}

// @todo
class LazyImageErrorBoundary extends React.Component {
  componentDidCatch(error) {
    if (process.env.NODE_ENV === 'production') return

    // prevent a bug in react-lazy-images/react-intersection-observer breaking the page
    // not sure when happens. probably when too fast switching of images
    if (/react-intersection-observer: No DOM node found/.test(error.message)) {
      console.groupCollapsed(
        'Caught react-lazy-images/react-intersection-observer bug',
      )
      console.log(error)
      console.groupEnd()
      return
    }
  }

  render() {
    return this.props.children
  }
}

const ProductItem = ({
  item,
  isOpen,
  onSelect,
  onRequestClose,
  listIndex,
}: IProductItemProps) => {
  const { title, list_price, brand, images, description } = item
  const productItemRef = useRef<HTMLDivElement>(null)
  const productDetailsRef = useRef<HTMLDivElement>(null)
  const isNativeApp = useIsNativeApp()
  const isInWishlist = useAddedToWishlist(item.id)

  const [shouldScroll, setShouldScroll] = useState(true)

  useEffect(() => {
    if (is('mobile') && isOpen && productDetailsRef.current) {
      const top =
        productDetailsRef.current.getBoundingClientRect().top +
        window.scrollY -
        140

      window.scrollTo({ top, behavior: 'smooth' })
    }
  }, [isOpen])

  const handleOpen = useCallback(() => {
    // only scroll once, possible bug in react-collapsible
    // handleOpen is trigger when react image zoom is hovered 🤷‍
    shouldScroll &&
      productItemRef.current &&
      productItemRef.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      })
    setShouldScroll(false)
  }, [shouldScroll])
  const handleClose = useCallback(() => {
    setShouldScroll(true)
  }, [setShouldScroll])

  const handleDetailsClick = useCallback(() => {
    onSelect(item.id)
  }, [item.id, onSelect])

  const handleClickHeart = useCallback(
    (e) => {
      e.stopPropagation()
      toggleWishlistItem(item)
    },
    [item],
  )
  const { origin } = useInitialUrlContext()

  const renderProductDetails = () => {
    const imageSrc = images.url_template.replace(/{0\}/g, '600')
    const text = decodeHtmlChars(description)

    return (
      <div {...styles.productDetails}>
        <div {...css({ alignSelf: 'flex-start' })}>
          <ProductImage src={imageSrc} title={title} />
        </div>
        <ProductDetailsInfo
          id={item.id}
          text={text}
          image={imageSrc}
          brand={brand}
          shop={item.shop}
          currentPrice={list_price.current}
          currency={list_price.currency}
          title={title}
          oldPrice={list_price.old}
          onClickHeart={handleClickHeart}
          trackingLink={item.tracking}
          isHeartActive={isInWishlist}
          sharingUrl={getSharingUrl(item, origin)}
        />
        <div {...styles.productDetailsClose} onClick={onRequestClose}>
          <CloseIcon width={24} height={24} />
        </div>
      </div>
    )
  }

  const listImage = images.url_template
    ? images.url_template.replace(/{0\}/g, '350')
    : images.url

  return (
    <div {...styles.wrapper} className="product-item" ref={productItemRef}>
      <div {...css(styles.productContainer(isOpen))}>
        <div {...styles.wrapperWithoutDetails} onClick={handleDetailsClick}>
          <div {...styles.imageWrapper}>
            <LazyImageErrorBoundary>
              <LazyImage
                src={listImage}
                loadEagerly={listIndex <= 4}
                observerProps={{ rootMargin: '100px 0px', threshold: 0.3 }}
                actual={({ imageProps }) => (
                  <img
                    title={title}
                    alt={title}
                    style={{ width: 220, height: 220 }}
                    {...imageProps}
                  />
                )}
                error={() => <div>error</div>}
                placeholder={({ ref }) => <Placeholder ref={ref} />}
              />
            </LazyImageErrorBoundary>
          </div>
          <div {...styles.shopLinkAndHeart}>
            {isNativeApp ? (
              <div
                // hitboxerle
                style={{ padding: 12, margin: -12 }}
                onClick={(e) => {
                  e.stopPropagation()
                  doTrack()
                  sendToNativeApp('goToProduct', item.tracking)
                }}
              >
                Jetzt Kaufen
              </div>
            ) : (
              /* eslint-disable-next-line react/jsx-no-target-blank */
              <a
                href={buildTrackingLink(item.id)}
                target="_blank"
                rel="nofollow noopener"
                onClick={(e) => {
                  e.stopPropagation()
                  doTrack()
                }}
              >
                Zum Shop
              </a>
            )}
            <div
              {...css({
                padding: 10,
                margin: -10,
              })}
              onClick={handleClickHeart}
            >
              {isInWishlist ? (
                <HeartFilledIcon {...styles.heartIcon} />
              ) : (
                <img
                  src="/assets/icons/heartOutline_16x16.svg"
                  alt=""
                  {...styles.heartIcon}
                />
              )}
            </div>
          </div>
        </div>
        <div
          onClick={handleDetailsClick}
          {...styles.descriptionWrapper}
          {...styles.triangle(isOpen)}
        >
          <div {...css({ maxWidth: 220 })}>
            <ProductDescription
              brand={brand}
              title={title}
              price={list_price.current}
              priceOld={list_price.old}
              currency={list_price.currency}
              titleEllipsis
              size="l"
            />
          </div>
        </div>
      </div>
      <div
        {...styles.productDetailsWrapper(isOpen)}
        className={productInfoClass}
        ref={productDetailsRef}
      >
        <Responsive desktop tablet>
          <Collapsible
            trigger={<></>}
            easing="ease-in-out"
            transitionTime={150}
            transitionCloseTime={1}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            lazyRender
            {...css({ position: 'relative' })}
          >
            {renderProductDetails()}
          </Collapsible>
        </Responsive>
        {isOpen && <Responsive mobile>{renderProductDetails()}</Responsive>}
      </div>
    </div>
  )
}

export default React.memo(ProductItem, deepEqual)
