import React, {
  AllHTMLAttributes,
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { css } from 'glamor'
import { useHistory, useLocation } from 'react-router'

import { createMQ } from './Responsive'
import CloseIcon from './icons/Close'
import SearchIcon from './icons/Search'
import Layout from './Layout'

const styles = {
  wrapper: css({
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: '#fff',
    marginTop: 100,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 50,
    [createMQ('mobile', 'tablet')]: {
      marginTop: 70,
    },
  }),
  content: css({
    width: '80%',
    maxWidth: 450,
  }),
  inputWrapper: css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  }),
  input: css({
    outline: 0,
    background: 'rgba(255, 255, 255, 0)',
    height: 50,
    padding: '0 30px 0 0',
    fontSize: 30,
    fontWeight: 600,
    border: 0,
    borderBottom: '3px solid #000',
    borderRadius: 0,
    width: '100%',
    transition: '250ms',
    '::placeholder': {
      color: '#b0b0b0',
    },
    ':placeholder-shown': {
      borderColor: '#b0b0b0',
    },
  }),
  xIcon: css({
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
  }),
  description: css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350,
    alignItems: 'center',
  }),
}

interface ISearchInputProps extends AllHTMLAttributes<HTMLInputElement> {
  value?: string
  onClickX: () => void
}

export const SearchInput = React.forwardRef<
  HTMLInputElement,
  ISearchInputProps
>(({ value = '', onClickX, ...props }, ref) => (
  <div {...styles.inputWrapper}>
    <input
      type="text"
      inputMode="search"
      {...styles.input}
      onKeyPress={props.onKeyPress}
      ref={ref}
      value={value}
      {...props}
    />
    {value.length > 0 && (
      <CloseIcon onClick={onClickX} width={30} height={30} {...styles.xIcon} />
    )}
  </div>
))

export const executeSearch = (history, term) => {
  if (!term || term === '') return

  window.fbq('track', 'Search', { value: term })
  history.push(`/suche/${term}`)
}

interface IFullpageSearchProps {
  searchTerm?: string
  onRequestClose: () => void
}

const FullPageSearch: FunctionComponent<IFullpageSearchProps> = ({
  searchTerm = '',
  onRequestClose,
}) => {
  const location = useLocation()
  const history = useHistory()
  const [searchValue, setSearchValue] = useState(searchTerm)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 50)

    return () => void document.body.style.removeProperty('overflow') // 🤓
  }, [])
  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    } else {
      onRequestClose()
    }
  }, [location.pathname, onRequestClose])

  const doSearch = () => {
    if (searchValue) {
      executeSearch(history, searchValue)
      onRequestClose()
    }
  }

  return (
    <div {...styles.wrapper}>
      <div {...Layout.left()} />
      <div {...css(Layout.middle, { justifyContent: 'center' })}>
        <div {...styles.content}>
          <SearchInput
            ref={inputRef}
            placeholder="Suche…"
            value={searchValue}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                doSearch()
                e.stopPropagation()
              }
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                doSearch()
              }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
            onClickX={() => {
              if (searchValue.length > 0) {
                setSearchValue('')
                inputRef.current && inputRef.current.focus()
              } else {
                onRequestClose()
              }
            }}
          />

          <div {...css({ display: 'flex', justifyContent: 'center' })}>
            <div {...styles.description}>
              <div {...css({ marginTop: 80 })}>
                <SearchIcon
                  onClick={doSearch}
                  width={70}
                  height={70}
                  {...css({ cursor: 'pointer' })}
                />
              </div>
              <div
                {...css({
                  marginTop: 50,
                  fontSize: 20,
                  lineHeight: 1.5,
                  fontWeight: 500,
                  textAlign: 'center',
                })}
              >
                Durchsuche Produkte und Marken aus über 100 ausgewählten
                Luxus-Shops
              </div>
            </div>
          </div>
        </div>
      </div>
      <div {...Layout.right} />
    </div>
  )
}

export default FullPageSearch
