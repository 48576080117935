import React, { CSSProperties, FunctionComponent } from 'react'
import { css } from 'glamor'
import Checkbox, { ICheckboxProps } from './Checkbox'

const itemStyle = css({
  borderBottom: '1px solid #000',
  padding: '6px 0 4px 10px',
})

interface IFilterItemProps extends ICheckboxProps {
  withCheckbox?: boolean
  style: CSSProperties
}

const FilterItem: FunctionComponent<IFilterItemProps> = ({
  label,
  value,
  onChange,
  checked,
  withCheckbox = true,
  style = {},
}) => (
  <div {...itemStyle} style={style} title={label}>
    {withCheckbox ? (
      <Checkbox
        label={label}
        onChange={onChange}
        checked={checked}
        value={value}
      />
    ) : (
      label
    )}
  </div>
)

export default FilterItem
