import React, { useEffect, useRef } from 'react'
import { css } from 'glamor'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet-async'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { Page } from '../Layout'
import { createMQ } from '../Responsive'
import { Article } from '../types'
import gold from '../utils/gold'
import useDatabaseQuery from '../utils/useDatabaseQuery'
import FullPageLoading from '../FullPageLoading'
import { ShareBar } from '../Product/ProductDetailsInfo'
import { useInitialUrlContext } from '../UrlContext'

const MagazineDetail = () => {
  const { slug } = useParams<{ slug: string }>()

  const article = useDatabaseQuery<Article>(`/magazine/articles/${slug}`)
  const { origin } = useInitialUrlContext()

  if (article.isLoading) return <FullPageLoading />

  // @todo error
  if (!article.isSuccess || !article.data) return null

  const sharingUrl = `${origin}/magazin/${article.data.slug}`

  const image = article.data.image_url

  return (
    <Page>
      <Helmet>
        <title>Maprodo Magazin ‒ {article.data.title}</title>
        <meta name="title" content={article.data.meta_title} />
        <meta name="description" content={article.data.meta_description} />
        <meta property="og:image" content={article.data.image_url} />
        <meta property="og:url" content={sharingUrl.toString()} />
        <meta property="og:title" content={article.data.title} />
        <meta property="og:description" content={article.data.snippet} />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "${article.data.title}",
  "image": [
    "${article.data.image_url}"
  ],
  "datePublished": "${new Date(article.data.published_at).toISOString()}",
  "dateModified": "${new Date(article.data.updated_at).toISOString()}",
  "author": {
    "@type": "Person",
    "name": "${article.data.author}"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Maprodo",
    "logo": {
      "@type": "ImageObject",
      "url": "${origin}/assets/maprodo.png"
    }
  },
  "description": "${article.data.snippet}"
}`}
        </script>
      </Helmet>
      <div
        {...css({
          minWidth: '100%',
          [createMQ('mobile')]: { margin: '0 -50px' },
        })}
      >
        <div
          {...css({
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${article.data.image_url})`,
            height: 275,
          })}
        />
        <div
          {...css({
            padding: '0 25px',
            [createMQ('tablet', 'desktop')]: { padding: '0 100px' },
            userSelect: 'none',
            letterSpacing: 0,
          })}
        >
          <div
            id="magazine-header"
            {...css({
              [createMQ('desktop', 'tablet')]: {
                position: 'sticky',
                top: 0,
              },
              [createMQ('tablet')]: {
                top: 70,
              },
              background: '#fff',
            })}
          >
            <h1
              {...css({
                fontFamily: `'Playfair Display', serif`,
                fontSize: 44,
                [createMQ('mobile')]: {
                  fontSize: 26,
                },
                [createMQ('tablet')]: {
                  fontSize: 34,
                },
                textAlign: 'center',
                fontWeight: 400,
              })}
            >
              {article.data.title}
            </h1>
            <div
              {...css({
                display: 'flex',
                justifyContent: 'flex-end',
                transform: 'translateX(4px)',
                '> *': { padding: '0 4px', cursor: 'pointer', margin: '0 5px' },
              })}
            >
              <ShareBar url={sharingUrl} title={article.data.title} />
            </div>
            <hr {...css({ border: 0, borderTop: '1px solid #000' })} />
          </div>
          <div
            {...css({
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              '> *': {
                margin: '10px 0',
              },
            })}
          >
            <img
              src={article.data.author_image}
              alt={article.data.author}
              style={{
                borderRadius: '50%',
                width: 75,
                height: 75,
              }}
            />
            <span>
              von <strong style={{ color: gold }}>{article.data.author}</strong>
            </span>
          </div>
          <UnsafeHtmlContent
            isServerLoaded={!article.isFetchedAfterMount}
            html={article.data.text}
            {...css({
              '& img': {
                maxWidth: '100%',
                height: 'auto !important',
              },
              '& .instagram-media': {
                margin: '0 auto !important',
              },
              // this fucked up our layout! sad
              '& .instagram-media p': {
                whiteSpace: 'pre-wrap !important',
              },
              '& h2': {
                fontWeight: 300,
                fontSize: 24,
              },
              '& h3': {
                fontWeight: 300,
                fontSize: 20,
              },
              '& p': {
                textAlign: 'justify',
              },
            })}
          />
        </div>
      </div>
    </Page>
  )
}

function UnsafeHtmlContent({ html = '', isServerLoaded, ...props }) {
  const ref = useRef<HTMLDivElement | null>(null)

  // dangerouslySetInnerHtml does not execute <script> tags, createContextualFragment does
  const innerHtmlProp =
    process.env.BUILD_TARGET === 'server' || isServerLoaded
      ? { dangerouslySetInnerHTML: { __html: html } }
      : {}

  useEffect(() => {
    if (!isServerLoaded && ref.current) {
      const fragment = document.createRange().createContextualFragment(html)

      ref.current.innerHTML = ''
      ref.current.appendChild(fragment)
    }
  }, [html, isServerLoaded])

  return (
    <div suppressHydrationWarning ref={ref} {...innerHtmlProp} {...props} />
  )
}

export default MagazineDetail
