import { css, Rule } from 'glamor'
import React, { ReactNode } from 'react'
import Link from './Link'
import { createMQ } from './Responsive'

const Box = ({ image, children }) => (
  <div
    {...css({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#fff',
      fontFamily: `'Playfair Display', serif`,
      textTransform: 'uppercase',
      fontWeight: 400,
      position: 'relative',
      transition: '250ms',
      cursor: 'pointer',
      userSelect: 'none',
      background: 'rgba(0,0,0,0)',
      ':after': {
        position: 'absolute',
        content: `''`,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: image && `url(${image})`,
        backgroundSize: 'cover',
        zIndex: -1,
      },
      [createMQ('desktop')]: {
        background: 'rgba(0,0,0,0.5)',
        ':hover': {
          background: 'rgba(0,0,0,0)',
          transition: '250ms',
        },
        ':hover .box-subtitle': {
          textShadow: '2px 2px 2px #252525',
        },
        ':hover .box-title': {
          fontSize: 26,
          textShadow: '2px 2px 2px #252525',
        },
      },
    })}
  >
    {children}
  </div>
)

Box.Link = (props) => (
  <Link
    {...css({
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    })}
    color="#fff"
    {...props}
  />
)

Box.Wrapper = ({ children, style }: { children: ReactNode; style?: Rule }) => (
  <div
    {...css([
      {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 1fr)',
        gridAutoRows: 'minmax(33.33333vh, auto)',
      },
      style,
    ])}
  >
    {children}
  </div>
)

Box.Title = ({ children }) => (
  <div
    className="box-title"
    {...css({
      border: '2px solid #fff',
      borderLeft: 'none',
      transition: '150ms',
      fontSize: 21,
      borderRight: 'none',
      margin: '10px 20px',
      padding: 10,
      fontWeight: 600,
      [createMQ('mobile', 'tablet')]: {
        textShadow: '2px 2px 2px #252525',
      },
    })}
  >
    {children}
  </div>
)

Box.Subtitle = (props) => (
  <div
    className="box-subtitle"
    {...css({
      [createMQ('mobile', 'tablet')]: {
        textShadow: '2px 2px 2px #252525',
      },
    })}
    {...props}
  />
)

export default Box
