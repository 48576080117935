import React from 'react'
import { Route, Switch } from 'react-router-dom'

import CategoryMenu from './CategoryMenu'
import BrandCategoryMenu from '../Brand/BrandCategoryMenu'
import BrandMenu from '../Sidebar/BrandMenu'

const CategoryRoutes = ({ prefixPath = '' }: { prefixPath?: string }) => (
  <Switch>
    <Route path="/marken/:brand/:gender(damen|herren)?">
      <BrandCategoryMenu />
    </Route>
    <Route
      path={[
        '/suche',
        `${prefixPath}/:gender(damen|herren)?/:cat1?/:cat2?/:cat3?/:cat4?`,
      ]}
    >
      <CategoryMenu prefixPath={prefixPath} />
    </Route>
    {/* No side category for search :] */}
    <Route path="/marken" exact>
      <BrandMenu />
    </Route>
    {/* This is so the menu for static pages also has some content. */}
    <Route>
      <CategoryMenu />
    </Route>
  </Switch>
)

export default CategoryRoutes
