import React from 'react'

const BaselineChevronLeft = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export default BaselineChevronLeft
