import React from 'react'
import { Helmet } from 'react-helmet-async'
import Trademark from './icons/Trademark'
import InternalLink from './Link'
import { css } from 'glamor'
import { createMQ } from './Responsive'
import { BORDER_RADIUS } from './Button'
const stylesSummer = {
  head: css({
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    justifyContent: 'space-around',
    padding: 20,
    backgroundImage: 'url(/assets/summer_2019_header_mobile.jpg)',
    height: 'calc(75vh - 70px)',
    [createMQ('tablet')]: {
      backgroundImage: 'url(/assets/summer_2019_header_tablet.jpg)',
    },
    [createMQ('desktop')]: {
      backgroundImage: 'url(/assets/summer_2019_header_desktop.jpg)',
    },
    '::before': {
      content: `''`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      borderStyle: 'solid',
      borderWidth: '49vh 49vw 0 49vw',
      borderColor:
        'rgba(255, 255, 255, 0.1) transparent transparent transparent',
    },
  }),
  headTitle: css({
    fontFamily: `'Playfair Display', serif`,
    textShadow: '2px 2px 4px #424242',
    fontSize: 30,
    [createMQ('desktop', 'tablet')]: { fontSize: 40 },
    margin: '30px 15px',
    textAlign: 'center',
  }),
  buttonContainer: css({
    display: 'flex',
    maxWidth: 600,
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
    margin: -10,
  }),
  buttonLink: css({
    overflow: 'hidden',
    margin: 10,
  }),
  button: css({
    borderRadius: BORDER_RADIUS,
    padding: '10px 30px',
    border: '1px solid #fff',
    transition: '250ms ease-in-out',
    overflow: 'hidden',
    background: 'rgba(0, 0, 0, 0.5)',
    [createMQ('desktop')]: {
      ':after': {
        background: '#fff',
        content: `''`,
        width: 50,
        height: 155,
        left: -75,
        top: -50,
        opacity: 0.2,
        position: 'absolute',
        transform: 'rotate(35deg)',
        transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      },
      ':hover': {
        background: 'rgba(255, 255, 255, 1)',
        color: '#000',
      },
      ':hover:after': {
        left: '120%',
        transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      },
    },
  }),
}

const stylesAutumn = {
  head: css({
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    justifyContent: 'space-around',
    padding: 20,
    backgroundImage: 'url(/assets/headerbild_herbst_maprodo_mobile.jpg)',
    height: 'calc(75vh - 70px)',
    [createMQ('tablet')]: {
      backgroundImage: 'url(/assets/headerbild_herbst_maprodo_tablet.jpg)',
    },
    [createMQ('desktop')]: {
      backgroundImage: 'url(/assets/headerbild_herbst_maprodo.jpg)',
    },
    '::before': {
      content: `''`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      borderStyle: 'solid',
      borderWidth: '49vh 49vw 0 49vw',
      borderColor:
        'rgba(255, 255, 255, 0.1) transparent transparent transparent',
    },
  }),
  headTitle: css({
    fontFamily: `'Playfair Display', serif`,
    textShadow: '2px 2px 4px #424242',
    fontSize: 30,
    [createMQ('desktop', 'tablet')]: { fontSize: 40 },
    margin: '30px 15px',
    textAlign: 'center',
  }),
  buttonContainer: css({
    display: 'flex',
    maxWidth: 600,
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
    margin: -10,
  }),
  buttonLink: css({
    overflow: 'hidden',
    margin: 10,
  }),
  button: css({
    borderRadius: BORDER_RADIUS,
    padding: '10px 30px',
    border: '1px solid #fff',
    transition: '250ms ease-in-out',
    overflow: 'hidden',
    background: 'rgba(0, 0, 0, 0.5)',
    [createMQ('desktop')]: {
      ':after': {
        background: '#fff',
        content: `''`,
        width: 50,
        height: 155,
        left: -75,
        top: -50,
        opacity: 0.2,
        position: 'absolute',
        transform: 'rotate(35deg)',
        transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      },
      ':hover': {
        background: 'rgba(255, 255, 255, 1)',
        color: '#000',
      },
      ':hover:after': {
        left: '120%',
        transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      },
    },
  }),
}

const stylesSpring = {
  head: css({
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    justifyContent: 'space-around',
    padding: 20,
    paddingBottom: 60,
    backgroundImage: 'url(/assets/spring_2019_header_mobile.jpg)',
    height: 'calc(75vh - 70px)',
    [createMQ('tablet')]: {
      backgroundImage: 'url(/assets/spring_2019_header_tablet.jpg)',
    },
    [createMQ('desktop')]: {
      height: 'calc(75vh - 100px)',
      backgroundImage: 'url(/assets/spring_2019_header_desktop.jpg)',
    },
  }),
  headTitle: css({
    fontFamily: `'Playfair Display', serif`,
    textShadow: '2px 2px 4px #424242',
    fontSize: 30,
    [createMQ('desktop', 'tablet')]: { fontSize: 40 },
    margin: '30px 15px',
    textAlign: 'center',
  }),
  buttonContainer: css({
    display: 'flex',
    maxWidth: 600,
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
    margin: -10,
  }),
  buttonLink: css({
    overflow: 'hidden',
    margin: 10,
  }),
  button: css({
    borderRadius: BORDER_RADIUS,
    padding: '10px 30px',
    border: '1px solid #fff',
    transition: '250ms ease-in-out',
    overflow: 'hidden',
    background: 'rgba(188, 143, 143, 0.7)',
    [createMQ('desktop')]: {
      ':after': {
        background: '#fff',
        content: `''`,
        width: 50,
        height: 155,
        left: -75,
        top: -50,
        opacity: 0.2,
        position: 'absolute',
        transform: 'rotate(35deg)',
        transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      },
      ':hover': {
        background: 'rgba(255, 255, 255, 1)',
        color: '#000',
      },
      ':hover:after': {
        left: '120%',
        transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      },
    },
  }),
}

const HomeSpring = () => (
  <>
    <Helmet>
      <meta
        property="og:image"
        content="https://www.maprodo.de/assets/maprodo_social_spring.jpg"
      />
      <meta name="robots" content="index,follow" />
    </Helmet>
    <div {...stylesSpring.head}>
      <Trademark scale={0.8} />
      <div {...stylesSpring.headTitle}>Alles Neu macht der Frühling</div>

      <div {...stylesSpring.buttonContainer}>
        <InternalLink
          to="/damen/bekleidung"
          color="#fff"
          {...stylesSpring.buttonLink}
        >
          <div {...stylesSpring.button}>Frühlingsmode für Damen</div>
        </InternalLink>
        <InternalLink
          to="/herren/bekleidung"
          color="#fff"
          {...stylesSpring.buttonLink}
        >
          <div {...stylesSpring.button}>Frühlingsmode für Herren</div>
        </InternalLink>
      </div>
    </div>
  </>
)

const HomeSummer = () => (
  <>
    <Helmet>
      <meta
        property="og:image"
        content="https://www.maprodo.de/assets/maprodo_social_summer.jpg"
      />
      <meta name="robots" content="index,follow" />
    </Helmet>
    <div {...stylesSummer.head}>
      <Trademark scale={0.8} />
      <div {...stylesSummer.headTitle}>Inspired by summer</div>

      <div {...stylesSummer.buttonContainer}>
        <InternalLink to="/magazin" color="#fff" {...stylesSummer.buttonLink}>
          <div {...stylesSummer.button}>Magazin</div>
        </InternalLink>
      </div>
    </div>
  </>
)

const HomeAutumnWinter = () => (
  <>
    <Helmet>
      <meta
        property="og:image"
        content="https://www.maprodo.de/assets/maprodo_social_autumn.jpg"
      />
      <meta name="robots" content="index,follow" />
    </Helmet>
    <div {...stylesAutumn.head}>
      <Trademark scale={0.8} />
      <div {...stylesAutumn.headTitle}>Herbst ist, was du daraus machst.</div>

      <div {...stylesAutumn.buttonContainer}>
        <InternalLink to="/magazin" color="#fff" {...stylesAutumn.buttonLink}>
          <div {...stylesAutumn.button}>Magazin</div>
        </InternalLink>
      </div>
    </div>
  </>
)

const HomeHead = () => {
  const month = new Date().getMonth()

  switch (true) {
    case month >= 9 || month < 1:
      return <HomeAutumnWinter />
    case month >= 5:
      return <HomeSummer />
    case month >= 2:
    default:
      return <HomeSpring />
  }
}

export default HomeHead
