import at from './at'

export const getTLD = (hostname: string) => {
  const domain =
    at(
      hostname.replace(/^www\./, '').split('.'),
      hostname.endsWith('.test') ? 0 : -1,
    ) ?? 'de'

  return ['de', 'ch', 'at'].includes(domain) ? domain : 'de'
}
