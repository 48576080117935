import 'react-app-polyfill/stable'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import ReactDOM from 'react-dom'
import ky from 'ky-universal'
import joinUrl from 'url-join'
import firebase from 'firebase/app'

import loadPolyfills from './loadPolyfills'
import App from './App'
import firebaseConfig from './firebaseConfig.json'
import { rehydrate as rehydrateStyles } from 'glamor'
import * as Sentry from '@sentry/browser'
import QueryProvider from './QueryProvider'
import { Hydrate } from 'react-query/hydration'
import { QueryClient } from 'react-query'
import { CookiesProvider } from 'react-cookie'
import { InitialUrlContextProvider } from './UrlContext'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://b34c065e3a6346e5b43836fcb69faf0a@sentry.io/1269114',
    autoSessionTracking: true,
  })
}

firebase.initializeApp(firebaseConfig)

rehydrateStyles(window.__STYLES__)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000,
      queryFn: ({ queryKey }) =>
        ky(joinUrl(...queryKey), { timeout: 1000, retry: 0 }).json(),
      refetchOnWindowFocus: false,
      retry: 2,
      refetchOnReconnect: false,
      // suspense: true,
    },
  },
})

loadPolyfills().then(() =>
  ReactDOM.hydrate(
    <QueryProvider client={queryClient}>
      <Hydrate state={window.__DATA__}>
        <CookiesProvider>
          <IntlProvider locale="de" messages={window.__MESSAGES__}>
            <HelmetProvider>
              <BrowserRouter>
                <InitialUrlContextProvider
                  url={window.location.origin + window.location.pathname}
                >
                  <App />
                </InitialUrlContextProvider>
              </BrowserRouter>
            </HelmetProvider>
          </IntlProvider>
        </CookiesProvider>
      </Hydrate>
    </QueryProvider>,
    document.querySelector('#root'),
  ),
)

if ((module as any).hot) {
  ;(module as any).hot.accept()
}
