import RemoveShoppingIcon from '../icons/RemoveShopping'
import Button from '../Button'
import React from 'react'
import useQueryFilterParams from '../utils/hooks/useQueryFilterParams'
import { useHistory } from 'react-router'
import { HighlightBoxWrapper } from '../HighlightBox/HighlightBox'
import { createMQ } from '../Responsive'
import { css } from 'glamor'

const ProductNoResults = () => {
  const history = useHistory()
  const { removeFilters } = useQueryFilterParams()

  return (
    <HighlightBoxWrapper
      styles={{
        flex: '1 0 275px',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        {...css({
          [createMQ('desktop')]: { maxWidth: '50%' },
          justifyContent: 'space-between',
          flexDirection: 'column',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <span style={{ fontSize: 20, fontWeight: 500 }}>
          Leider konnten wir keine geeigneten Produkte für dich finden.
        </span>
        <RemoveShoppingIcon width={50} height={50} />
        <div>
          <Button onClick={removeFilters}>Filter entfernen</Button>
          &nbsp;
          <Button onClick={history.goBack}>&laquo; Zurück</Button>
        </div>
      </div>
    </HighlightBoxWrapper>
  )
}

export default ProductNoResults
