import { PER_PAGE_COOKIE_NAME } from '../../FilterBox/PerPage'
import { useCookies } from 'react-cookie'

export const PAGE_SIZES = [24, 48, 60]

const usePerPageSize = () => {
  const [cookies] = useCookies([PER_PAGE_COOKIE_NAME])
  const pageSize = Number(cookies[PER_PAGE_COOKIE_NAME])

  return PAGE_SIZES.includes(pageSize) ? pageSize : 24
}

export default usePerPageSize
