import React from 'react'
import { css } from 'glamor'
import FullHeight from './FullPage'

import Logo from './Logo'
import { logoPulsate } from './Product/ProductItem'

const FullPageLoading = () => (
  <FullHeight>
    <Logo scale={2} {...css({ animation: `${logoPulsate} 1.5s infinite` })} />
  </FullHeight>
)

export default FullPageLoading
