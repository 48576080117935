import React from 'react'

const Menu = props => (
  <svg fill="#e8e9e8" height={24} viewBox="0 0 24 24" width={24} {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
)

export default Menu
