import React, { FunctionComponent, ReactNode } from 'react'
import { css } from 'glamor'

import { createMQ } from './Responsive'
import { useIsNativeApp } from './utils/nativeApp'

export const MAX_WIDTH = 1480
export const ASIDE_WIDTH = 200
export const TWO_COLUMNS_MQ = '@media (max-width: 1200px)'
const MIDDLE_WIDTH = MAX_WIDTH - 2 * ASIDE_WIDTH

const LayoutStyles = {
  left: (middleColumnOnly = false) =>
    css({
      flex: `0 0 ${ASIDE_WIDTH}px`,
      [createMQ('mobile', 'tablet')]: {
        display: 'none',
      },
      // [TWO_COLUMNS_MQ]: {
      display: middleColumnOnly && 'none',
      // },
    }),
  middle: css({
    display: 'flex',
    flex: `0 1 ${MIDDLE_WIDTH}px`,
  }),
  right: css({
    flex: `0 0 ${ASIDE_WIDTH}px`,
    [TWO_COLUMNS_MQ]: {
      display: 'none',
    },
  }),
}

interface IPageProps {
  left?: ReactNode
  right?: ReactNode
  middleColumnOnly?: boolean
}

export const Page: FunctionComponent<IPageProps> = ({
  left,
  right,
  children,
  middleColumnOnly = false,
}) => (
  <>
    <div {...LayoutStyles.left(useIsNativeApp() ? true : middleColumnOnly)}>
      {left}
    </div>
    <div {...LayoutStyles.middle}>{children}</div>
    <div {...LayoutStyles.right}>{right}</div>
  </>
)

export default LayoutStyles
