import React from 'react'
import { Link as RouterLink, LinkProps, useRouteMatch } from 'react-router-dom'
import { css } from 'glamor'

const activeStyle = {
  textDecoration: 'none',
  ':before': {
    left: 0,
    transform: 'translateY(5px)',
    opacity: 1,
    width: '100%',
  },
}

const style = (color, hover) =>
  css({
    color: `${color} !important`,
    textDecoration: 'none',
    position: 'relative',
    ':before': {
      position: 'absolute',
      content: `""`,
      width: '0',
      height: 2,
      background: color,
      bottom: 0,
      opacity: 0,
      left: '50%',
      transform: 'translateY(0)',
      transition: '200ms ease-in-out',
    },
    ':hover': hover && activeStyle,
    // borderBottom: '2px solid #fff',
    // paddingBottom: 5,
  })

interface ILinkProps extends LinkProps {
  to: string
  color?: string
  hover?: boolean
  showActive?: boolean
}

const Link = ({
  color = '#000',
  hover = false,
  to,
  showActive = true,
  ...props
}: ILinkProps) => {
  const match = useRouteMatch(to)

  return (
    <RouterLink
      to={to}
      {...css(style(color, hover), showActive && !!match && activeStyle)}
      {...props}
    />
  )
}

export default Link
