import React, { useContext } from 'react'
import { ValueOf } from '../types'
import { createMQString } from '../Responsive'

type SidebarTypes = ValueOf<typeof SIDEBAR> | undefined

type SidebarContextType = {
  open: (type: SidebarTypes) => void
  close: () => void
  current: SidebarTypes
}

const SidebarContext = React.createContext<SidebarContextType>({
  open: () => {},
  close: () => {},
  current: undefined,
})

export const useSidebarContext = () => useContext(SidebarContext)

export const SIDEBAR = {
  CATEGORY: 'category',
  FILTER: 'filter',
  WISHLIST: 'wishlist',
} as const

/*
 * This needs to be a class component because of the use of `getSnapshotBeforeUpdate`
 */
export default class SidebarProvider extends React.Component<
  {},
  SidebarContextType
> {
  mql?: MediaQueryList

  state = {
    open: (type: SidebarTypes) => {
      this.setState({ current: type })
    },
    close: () => {
      if (this.state.current !== undefined) {
        this.setState({ current: undefined })
      }
    },
    current: undefined,
  }

  handleMediaQueryChange = (e) => {
    const isDesktop = e.matches
    if (isDesktop && this.state.current !== undefined) {
      this.setState({ current: undefined })
    }
  }

  getTopOffsetFromBodyStyle = () =>
    parseFloat(document.body.style.top!.replace(/-?(\d+)px/, '$1'))

  componentDidMount() {
    this.mql = window.matchMedia(createMQString('desktop'))
    this.mql.addEventListener('change', this.handleMediaQueryChange)
  }

  componentWillUnmount() {
    this.mql?.removeEventListener('change', this.handleMediaQueryChange)
  }

  componentDidUpdate(_prevProps, prevState, snapshot) {
    if (this.state.current !== undefined && prevState.current === undefined) {
      document.body.style.top = `-${snapshot.top}px`
      document.body.style.height = `${snapshot.height}px`
      document.body.style.position = 'fixed'
    } else if (
      this.state.current === undefined &&
      prevState.current !== undefined
    ) {
      const top = this.getTopOffsetFromBodyStyle()
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('height')
      if (top) {
        window.scrollTo(0, top)
      }
    }
  }

  getSnapshotBeforeUpdate(_prevProps, prevState) {
    if (
      this.state.current !== prevState.current &&
      this.state.current !== undefined
    ) {
      const top = window.pageYOffset
      const height = document.body.scrollHeight
      return { top, height }
    }
    return null
  }

  render() {
    return (
      <SidebarContext.Provider value={this.state}>
        {this.props.children}
      </SidebarContext.Provider>
    )
  }
}
