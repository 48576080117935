import React, { FunctionComponent, ReactNode } from 'react'
import { css } from 'glamor'
import Link from '../Link'
import { createMQ } from '../Responsive'
import { mqColumnSelector } from '../Product/ProductItem'
import TitleSection, { ITitleSectionProps } from '../TitleSection'

interface ListBoxComponents {
  Item: FunctionComponent<IListBoxItemProps>
  Wrapper: FunctionComponent
}

interface IListBoxProps extends ITitleSectionProps {
  title: ReactNode
}

const ListBox: ListBoxComponents & FunctionComponent<IListBoxProps> = ({
  title,
  appearance,
  size,
  children,
}) => (
  <div
    {...css({
      paddingRight: 30,
      width: '100%',
      [`@media ${mqColumnSelector[2]}`]: { width: '50%' },
      [`@media ${mqColumnSelector[3]}`]: { width: '33.333%' },
      [`@media ${mqColumnSelector[4]}`]: { width: '25%' },
    })}
  >
    <TitleSection appearance={appearance} size={size}>
      {title}
    </TitleSection>
    <div {...css({ marginTop: 20, marginBottom: 20 })}>
      <ul {...css({ paddingLeft: 27, textTransform: 'uppercase' })}>
        {children}
      </ul>
    </div>
  </div>
)

interface IListBoxItemProps {
  link: string
  title: string
  size?: 'm' | 'l'
}

ListBox.Item = ({ link, title, children, size = 'l' }) => (
  <li
    {...css({
      marginBottom: 7,
      fontSize: { m: 12, l: 14 }[size],
      [createMQ('mobile', 'tablet')]: { marginBottom: 10 },
    })}
  >
    <Link
      to={link}
      title={title}
      {...css({ ':hover': { fontWeight: 600, transition: '150ms' } })}
    >
      {children}
    </Link>
  </li>
)

ListBox.Wrapper = props => (
  <div
    {...css({
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      padding: '20px 0',
      flexWrap: 'wrap',
      marginRight: -30,
    })}
    {...props}
  />
)

export default ListBox
