import React from 'react'
import ImageMagnify from 'react-image-magnify'

export const DEFAULT_MAGNIFY_OPTIONS = {
  enlargedImagePosition: 'over',
  isHintEnabled: true,
  hintTextMouse: 'Zoomen',
  hintTextTouch: 'Gedrückt halten zum zoomen',
  shouldHideHintAfterFirstActivation: false,
}

interface IProductImage {
  src: string
  title?: string
}

const ProductImage = ({ src, title }: IProductImage) => (
  <ImageMagnify
    {...{
      ...DEFAULT_MAGNIFY_OPTIONS,
      style: { textAlign: 'center', width: 350, height: 350 },
      // mobile fixing, otherwise would overlap screen
      imageStyle: {
        maxWidth: 'calc(100vw - 110px)',
        objectFit: 'contain',
      },
      smallImage: {
        alt: title,
        src,
        width: 350,
        height: 350,
      },
      largeImage: {
        alt: title,
        src,
        width: 800,
        height: 800,
      },
    }}
  />
)

export default ProductImage
