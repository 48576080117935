import React, { ReactNode, useCallback } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import isCurrentPath from '../utils/isCurrentPath'
import { ValueOf } from '../types'

const getMode = (path: string) => {
  if (isCurrentPath('/marken', path)) {
    return PRODUCT_TYPES.BRAND
  } else if (isCurrentPath('/(damen|herren)', path)) {
    return PRODUCT_TYPES.CATEGORY
  } else if (isCurrentPath('/suche', path)) {
    return PRODUCT_TYPES.SEARCH
  }
  return undefined
}

export const PRODUCT_TYPES = {
  BRAND: 'brand',
  CATEGORY: 'category',
  SEARCH: 'search',
} as const

interface IProductModeProps {
  children: ReactNode
  type?: ValueOf<typeof PRODUCT_TYPES>
}

const ProductMode = (props: IProductModeProps) => {
  const renderFn = useCallback(
    type => () => type === props.type && props.children,
    [props.children, props.type]
  )
  const { pathname } = useLocation()

  return typeof props.children === 'function' ? (
    props.children(getMode(pathname))
  ) : (
    <Switch>
      <Route path="/marken" render={renderFn(PRODUCT_TYPES.BRAND)} />
      <Route
        path="/:gender(damen|herren)"
        render={renderFn(PRODUCT_TYPES.CATEGORY)}
      />
      <Route path="/suche" render={renderFn(PRODUCT_TYPES.SEARCH)} />
    </Switch>
  )
}

export default ProductMode
