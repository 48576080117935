import firebase from 'firebase/app'
import 'firebase/database'

export type ModifierType = (
  ref: firebase.database.Reference,
) => firebase.database.Query

export const databaseCall = async <Result extends any = any>(
  path: string,
  modifier: ModifierType = (ref) => ref,
  databaseUrl?: string,
) => {
  const db = firebase.app().database(databaseUrl)
  const ref = modifier(db.ref(path))

  const snapshot = await ref.once('value')
  return snapshot.val() as Result
}
