import React, { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { css } from 'glamor'
import shuffleSeed from 'shuffle-seed'
import pickBy from 'lodash/pickBy'
import upperFirst from 'lodash/upperFirst'

import HighlightBox from '../HighlightBox/HighlightBox'
import RenderBlocker from '../utils/RenderBlocker'
import { BrandWithSlug, CategoryBrands, CategoryWithSlug } from '../types'
import gold from '../utils/gold'
import useDatabaseQuery from '../utils/useDatabaseQuery'

interface IPopularBrandCategoriesProps {
  category: CategoryWithSlug
  selectedBrand: BrandWithSlug
  gender?: string
}

const PopularBrandCategories = ({
  selectedBrand,
  category,
  gender,
}: IPopularBrandCategoriesProps) => {
  const brands = useDatabaseQuery<Record<string, BrandWithSlug>>('/brands')
  const categoryBrands = useDatabaseQuery<CategoryBrands>(
    `/category-brands/${category.slug}`
  )

  const shuffled = useMemo(() => {
    if (
      !categoryBrands.isSuccess ||
      !brands.isSuccess ||
      !brands.data ||
      !categoryBrands.data
    ) {
      return
    }

    const newData = gender
      ? pickBy(
          categoryBrands.data,
          cat => cat[gender === 'damen' ? 'female' : 'male'] === true
        )
      : categoryBrands.data

    const shuffleSeededBrands = shuffleSeed
      .shuffle(Object.keys(newData), selectedBrand.id)
      .map(key => brands.data![key])
    shuffleSeededBrands.length = 15

    return shuffleSeededBrands
  }, [
    brands.isSuccess,
    brands.data,
    categoryBrands.data,
    categoryBrands.isSuccess,
    selectedBrand.id,
    gender,
  ])

  if (!brands.data) return null

  if (category) {
    return shuffled && shuffled.length > 0 ? (
      <HighlightBox
        title={
          <>
            Weitere beliebte Marken für {upperFirst(gender || '')}{' '}
            {category.name}
          </>
        }
      >
        {shuffled.filter(Boolean).map((brandCategory, index) => (
          <span key={brandCategory.id}>
            {!!index && ' · '}
            <Link
              // prettier-ignore
              title={`${brandCategory.name} ${upperFirst(gender || '')} ${category.name}`}
              // prettier-ignore
              to={`/marken/${brandCategory._slug}/${gender ? gender + '/' : ''}${category.slug}`}
            >
              <span {...css({ color: gold })}>{brandCategory.name}</span>
              &nbsp;
              <span {...css({ color: '#353535' })}>
                {gender ? `${upperFirst(gender)} ` : ''}
                {category.name}
              </span>
            </Link>
          </span>
        ))}
      </HighlightBox>
    ) : null
  } else {
    return (
      <HighlightBox
        title={
          <>
            Weitere beliebte Marken
            {gender ? ` für ${upperFirst(gender)}` : ''}
          </>
        }
      >
        <RenderBlocker key={selectedBrand.id}>
          {brands.isSuccess &&
            shuffleSeed
              .shuffle(Object.values(brands.data), selectedBrand.id)
              .slice(0, 15)
              .map((brand, index) => (
                <Fragment key={brand.id}>
                  {!!index && ' · '}
                  <Link
                    title={`${brand.name} ${
                      gender ? `${upperFirst(gender)}mode` : 'Online Shop'
                    }`}
                    to={`/marken/${brand._slug}${gender ? '/' + gender : ''}`}
                  >
                    <span {...css({ color: gold })}>{brand.name}</span>
                    &nbsp;
                    <span {...css({ color: '#353535' })}>
                      {gender ? `${upperFirst(gender)}mode` : 'Online Shop'}
                    </span>
                  </Link>
                </Fragment>
              ))}
        </RenderBlocker>
      </HighlightBox>
    )
  }
}

export default PopularBrandCategories
