import React from 'react'
import { css } from 'glamor'
import Link from '../Link'
import { createMQ } from '../Responsive'

const style = firstItem =>
  css({
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 400,
    [createMQ('mobile', 'tablet')]: {
      background: 'rgba(176, 151, 109, 0.5)',
      color: '#fff',
      padding: '20px 10px',
      borderBottom: '1px solid #d2d2d2',
      boxShadow: 'inset -1px 1px 3px 0px #bba37f',
      paddingLeft: 25,
    },
    [createMQ('desktop')]: {
      padding: '10px 0',
      marginTop: firstItem && 0,
    },
  })

interface ICategoryHeadingProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  path?: string
  firstItem?: boolean
}

const CategoryHeading = ({
  path,
  name,
  firstItem = false,
  ...props
}: ICategoryHeadingProps) => (
  <div {...style(firstItem)} {...props}>
    {path ? (
      <Link to={path} showActive={false}>
        <span
          {...css({
            color: '#fff',
            [createMQ('desktop')]: { color: '#000 !important' },
          })}
        >
          {name}
        </span>
      </Link>
    ) : (
      name
    )}
  </div>
)

export default CategoryHeading
