import React, { FunctionComponent } from 'react'
import { css } from 'glamor'
import { Link, Route } from 'react-router-dom'

import FilterBox, { IFilterBoxProps } from '../FilterBox/FilterBox'
import { createMQ } from '../Responsive'

const linkStyle = active =>
  css({
    display: 'inline-block',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    padding: '5px 0',
    fontWeight: active && 500,
  })

const BrandGenderFilterBox: FunctionComponent<
  Omit<IFilterBoxProps, 'label'>
> = props => (
  <FilterBox
    label="Geschlecht"
    {...css({ [createMQ('mobile')]: { textAlign: 'center' } })}
    {...props}
  >
    <Route
      path="/marken/:brand/:gender(damen|herren)?/:category?"
      render={({ match: { params } }) => (
        <div
          {...css({
            [createMQ('mobile')]: {
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            },
          })}
        >
          <Link
            to={`/marken/${[
              params.brand,
              params.gender !== 'damen' && 'damen',
              params.category,
            ]
              .filter(Boolean)
              .join('/')}`}
            {...linkStyle(params.gender === 'damen')}
            {...css({
              borderBottom: '1px solid #000',
            })}
          >
            Damen
          </Link>
          <Link
            to={`/marken/${[
              params.brand,
              params.gender !== 'herren' && 'herren',
              params.category,
            ]
              .filter(Boolean)
              .join('/')}`}
            {...linkStyle(params.gender === 'herren')}
          >
            Herren
          </Link>
        </div>
      )}
    />
  </FilterBox>
)

export default BrandGenderFilterBox
