import React from 'react'
import { css } from 'glamor'

const style = css({
  flexShrink: 0,
  width: '100%',
  '> .category-item:last-child a': {
    boxShadow: 'none',
  },
})

const CategoryList = props => <div {...style} {...props} />

export default CategoryList
