import React, { ReactNode } from 'react'
import { css } from 'glamor'
import { SIDEBAR, useSidebarContext } from './Sidebar/SidebarProvider'
import CategorySidebar from './Sidebar/CategorySidebar'
import FilterSidebar from './Sidebar/FilterSidebar'
import WishlistSidebar from './Sidebar/WishlistSidebar'
import Responsive, { is } from './Responsive'

const SIDEBAR_WIDTH = {
  [SIDEBAR.CATEGORY]: '300px',
  [SIDEBAR.FILTER]: '75vw',
  // not for wishlist as this pushes the page layout, so do it there manually LOL
  // [SIDEBAR.WISHLIST]: '75vw',
} as const

const styles = {
  wrapper: css({ display: 'flex' }),
  page: (isSidebarOpen, sidebarPosition, sidebarWidth) =>
    css({
      transition: 'transform 250ms ease-in-out',
      width: '100%',
      overflow: isSidebarOpen && 'hidden',
      willChange: 'transform',
      transform:
        isSidebarOpen &&
        sidebarWidth &&
        `translate3d(${
          sidebarPosition === 'left' ? '' : '-'
        }${sidebarWidth}, 0, 0)`,
    }),
  pageOverlay: (isSidebarOpen) =>
    css({
      ':after': {
        transition: 'background 250ms ease-in-out',
        content: `''`,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: isSidebarOpen && 'hidden',
        background: `rgba(0, 0, 0, ${isSidebarOpen ? 0.5 : 0})`,
        position: 'absolute',
        zIndex: 1000,
        pointerEvents: !isSidebarOpen && 'none',
      },
    }),
}

const PageLayout = ({ children }: { children: ReactNode }) => {
  const { close, current } = useSidebarContext()
  const isSidebarOpen = current !== undefined
  const sidebarWidth = current !== undefined ? SIDEBAR_WIDTH[current] : 0
  const sidebarPosition = current === SIDEBAR.CATEGORY ? 'left' : 'right'

  return (
    <div {...styles.wrapper}>
      <Responsive mobile tablet wrap={false}>
        <CategorySidebar
          onRequestClose={close}
          open={current === SIDEBAR.CATEGORY}
          width={SIDEBAR_WIDTH[SIDEBAR.CATEGORY]}
        />
      </Responsive>
      <Responsive mobile wrap={false}>
        <FilterSidebar
          onRequestClose={close}
          open={current === SIDEBAR.FILTER}
          width={SIDEBAR_WIDTH[SIDEBAR.FILTER]}
        />
      </Responsive>
      <WishlistSidebar
        onRequestClose={close}
        open={current === SIDEBAR.WISHLIST}
        width={is('desktop') ? '440px' : '75vw'}
      />
      <div {...styles.page(isSidebarOpen, sidebarPosition, sidebarWidth)}>
        <div onClick={close} {...styles.pageOverlay(isSidebarOpen)}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageLayout
