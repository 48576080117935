import React, { Fragment, useMemo } from 'react'
import { css } from 'glamor'
import pickBy from 'lodash/pickBy'
import keyBy from 'lodash/keyBy'
import StickyBox from 'react-sticky-box'
import SimpleViewSlider from 'react-view-slider/simple'

import CategoryHeading from './CategoryHeading'
import CategoryList from './CategoryList'
import CategoryItem from './CategoryItem'
import { createMQ } from '../Responsive'
import { getCategoriesBySlug, getSlug, getTopLevelTree } from './utils'
import { WhitelistCategory } from '../types'
import { useSidebarContext } from '../Sidebar/SidebarProvider'
import MagazineSuggestionHeader from '../Sidebar/MagazineSuggestionHeader'
import useDatabaseQuery from '../utils/useDatabaseQuery'
import useProductListInfo, {
  ParamGender,
  paramToDbGender,
} from '../Product/hooks/useProductListInfo'
import { useParams } from 'react-router'
import { useIsNativeApp } from '../utils/nativeApp'

const styles = {
  wrapper: css({
    [createMQ('desktop')]: { maxWidth: 185 },
  }),
  separator: css({
    [createMQ('desktop')]: {
      paddingBottom: 5,
      marginBottom: 5,
      borderBottom: '1px solid #000',
    },
  }),
}

const TopLevelCategories = ({ categories, gender, onSelect, prefixPath }) => {
  const isNativeApp = useIsNativeApp()
  return (
    <CategoryList>
      {categories.map((category, i) => (
        <Fragment key={category.id}>
          <CategoryHeading
            path={`${prefixPath}/${gender}/${category.slug}`}
            firstItem={i === 0}
            name={category.name}
          />
          {!isNativeApp && i === 0 && <MagazineSuggestionHeader />}
          <div {...css({ [createMQ('desktop')]: { paddingLeft: 15 } })}>
            {category.children.map(subCategory => {
              const hasSubs = subCategory.children.length > 0

              return (
                <CategoryItem
                  key={subCategory.slug}
                  path={`${hasSubs ? prefixPath : ''}/${gender}/${
                    category.slug
                  }/${subCategory.slug}`}
                  onClick={onSelect}
                  hasSubs={hasSubs}
                >
                  {subCategory.name}
                </CategoryItem>
              )
            })}
          </div>
        </Fragment>
      ))}
    </CategoryList>
  )
}

const CategoryMenu = ({ prefixPath = '' }: { prefixPath?: string }) => {
  const { pathname } = useProductListInfo()
  const params = useParams<{ gender?: ParamGender }>()
  const gender = params.gender ?? 'damen'
  const { close } = useSidebarContext()

  const categoryList = useDatabaseQuery<Record<string, WhitelistCategory>>(
    '/whitelist',
    { select: data => keyBy(data, 'categoryId') }
  )

  const categories = useMemo(() => {
    if (!categoryList.isSuccess) return

    const whitelist = Object.values(
      pickBy(categoryList.data, [paramToDbGender(gender), true])
    ).map(c => c.categoryId)

    const handleSelect = (hasSubs: boolean) => {
      if (!hasSubs) close()
    }

    const slug = getSlug(pathname)

    // - Bekleidung
    // -- Tops
    // -- ...
    // - Accessoires
    // -- ...
    const root = getTopLevelTree(whitelist)
    // top level displays two levels at the same time, so chop them off here
    const sub = getCategoriesBySlug(slug, whitelist, `/${gender}`).slice(2)

    return [
      <TopLevelCategories
        prefixPath={prefixPath}
        key="root"
        categories={root}
        gender={gender}
        onSelect={handleSelect}
      />,
      ...sub.map(({ parent, children }) => (
        <CategoryList key={parent.id}>
          <CategoryHeading
            firstItem
            name="&laquo; Zurück"
            path={
              prefixPath +
              parent.path
                .split('/')
                .slice(0, parent.depth === 2 ? -2 : -1)
                .join('/')
            }
            {...styles.separator}
          />
          <CategoryItem path={prefixPath + parent.path} {...styles.separator}>
            Alle {parent.name}
          </CategoryItem>
          {children.map(child => (
            <CategoryItem
              key={child.id}
              path={child.path}
              hasSubs={!child.leaf}
              onClick={handleSelect}
            >
              {child.name}
            </CategoryItem>
          ))}
        </CategoryList>
      )),
    ]
  }, [
    categoryList.isSuccess,
    categoryList.data,
    gender,
    pathname,
    prefixPath,
    close,
  ])

  if (!categories) return null

  const index = categories.length - 1

  return (
    <StickyBox>
      <SimpleViewSlider
        className={styles.wrapper.toString()}
        // fix "Warning: Prop `style` did not match. Server:"
        prefixer={{ prefix: d => d }}
      >
        <div key={index}>{categories[index]}</div>
      </SimpleViewSlider>
    </StickyBox>
  )
}

export default CategoryMenu
