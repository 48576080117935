import React, { useRef } from 'react'
import { css } from 'glamor'
import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import BrandOverview from './Brand/BrandOverview'
import CookieConsent from './CookieConsent'
import Footer from './Footer'
import Header from './Header/Header'
import SubHeader from './Header/SubHeader'
import Home from './Home'
import { MAX_WIDTH } from './Layout'
import Modal from './Modal'
import NotFound from './NotFound'
import PageLayout from './PageLayout'
import Responsive, { is } from './Responsive'
import StaticPage from './StaticPage'
import useScrollToTop from './utils/hooks/useScrollToTop'
import { defineMessages, useIntl } from 'react-intl'
import MagazineOverview from './Magazine/MagazineOverview'
import MagazineDetail from './Magazine/MagazineDetail'
import ProductPage from './Product/ProductPage'
import BrandStrip from './Brand/BrandStrip'
import {
  NativeCategoryMenu,
  NativeCategoryOverview,
} from './Sidebar/NativeCategory'
import {
  useIsNativeApp,
  useRegisterNativeCommunicator,
} from './utils/nativeApp'
import { WishlistInner } from './Sidebar/WishlistSidebar'
import { ACCESS_KEY } from './utils/buildApiUrl'
import FullPageSearch from './FullPageSearch'
import { useInitialUrlContext } from './UrlContext'

export const styles = {
  wrapper: css({
    maxWidth: MAX_WIDTH,
    margin: '0 auto',
  }),
  page: css({
    display: 'flex',
    padding: '0 50px',
  }),
}

const messages = defineMessages({
  default_title: 'Luxusmode, High Fashion und Designermode auf Maprodo',
  default_meta_description:
    'Entdecke Luxusmode, High Fashion und ausgewählte Designermode für Damen und Herren aus über 100 Shops. » Dress Different. «',
})

const Main = function () {
  const scrollAnchorRef = useRef<HTMLElement>(null)
  const { formatMessage } = useIntl()
  const isNativeApp = useIsNativeApp()
  const { origin } = useInitialUrlContext()

  useScrollToTop(is('mobile') ? { current: document.body } : scrollAnchorRef)
  useRegisterNativeCommunicator()

  return (
    <>
      {!isNativeApp && (
        <Modal>
          <CookieConsent />
        </Modal>
      )}
      <Helmet>
        <meta property="og:site_name" content="Maprodo" />
        <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": "${origin}",
              "potentialAction": {
              "@type": "SearchAction",
              "target": "${origin}/suche/{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
          `}</script>
        <title>{formatMessage(messages.default_title)}</title>
        <meta
          name="description"
          content={formatMessage(messages.default_meta_description)}
        />
        <link rel="canonical" href={origin} />
      </Helmet>
      <PageLayout>
        {/* Has to be in PageLayout so that native menu can show correctly */}
        {!isNativeApp && <Header />}
        {!isNativeApp && (
          <Route path="/marken" exact>
            <BrandStrip />
          </Route>
        )}
        <div>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/magazin">
            <Helmet>
              <script
                src={`https://scripts.tracdelight.io/tracdelight.js?accesskey=${ACCESS_KEY}`}
                async
              />
            </Helmet>
          </Route>
          <Route path="/magazin" exact>
            <MagazineOverview />
          </Route>
          <Switch>
            <Route path="/kategorien" exact>
              <NativeCategoryOverview />
            </Route>
            <Route path="/kategorien/:gender(damen|herren)?">
              <NativeCategoryMenu />
            </Route>
            <Route path="/wunschliste">
              <WishlistInner />
            </Route>
            <Route path="/suche-native">
              <FullPageSearch onRequestClose={() => {}} />
            </Route>
          </Switch>
          <div {...styles.wrapper}>
            <Responsive tablet desktop>
              <Route
                path={[
                  '/produkt',
                  '/:gender(damen|herren)',
                  '/marken/:brand',
                  '/suche',
                ]}
              >
                <SubHeader />
              </Route>
            </Responsive>
            <div {...styles.page}>
              <Switch>
                {/* To prevent that the 404 page shows up, render noop components */}
                <Route path={['/', '/magazin', '/suche-native']} exact />
                <Route path={['/kategorien', '/wunschliste']} />
                <Route path="/:page(impressum|datenschutz)" exact>
                  <StaticPage noIndex />
                </Route>
                <Route path="/magazin/:slug">
                  <MagazineDetail />
                </Route>
                <Route path="/marken" exact>
                  <BrandOverview />
                </Route>
                <Route path="/:type(produkt|damen|herren|marken|suche)">
                  <ProductPage scrollAnchorRef={scrollAnchorRef} />
                </Route>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </PageLayout>
    </>
  )
}

export default Main
