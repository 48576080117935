import React, { useCallback, useEffect, useRef, useState } from 'react'
import { css } from 'glamor'
import { createMQ } from './Responsive'
import BlockPageScroll from './BlockPageScroll'

const HIDDEN_EDGE = {
  NONE: -1,
  LEFT: 0,
  RIGHT: 1,
}

const styles = {
  wrapper: (hiddenEdge) =>
    css({
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      [createMQ('mobile', 'tablet')]: {
        ':before,:after': {
          content: `''`,
          opacity: 0,
          position: 'absolute',
          zIndex: 1,
          width: 10,
          top: '5%',
          height: '90%',
          borderRadius: '5px / 100px',
          boxShadow: '0 0 13px rgba(0,0,0,0.6)',
          transition: '250ms',
        },
        ':before': {
          opacity: hiddenEdge !== HIDDEN_EDGE.LEFT && 1,
          left: -10,
        },
        ':after': {
          opacity: hiddenEdge !== HIDDEN_EDGE.RIGHT && 1,
          right: -10,
        },
      },
    }),
  inner: css({
    display: 'flex',
    overflow: 'hidden',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '> *': {
      flexShrink: 0,
    },
  }),
}

const HorizontalScrollerHook = ({ children }) => {
  const [hiddenEdge, setHiddenEdge] = useState(HIDDEN_EDGE.LEFT)
  const [isDragging, setIsDragging] = useState(false)
  const [isPageScrollBlocked, setIsPageScrollBlocked] = useState(true)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const initialX = useRef<number | null>(null)
  const lastClientX = useRef<number | null>(null)
  const shouldPropagateClick = useRef(false)

  const handleWrapperCaptureClick = useCallback((e) => {
    !shouldPropagateClick.current && e.stopPropagation()
  }, [])

  const handleDocumentMouseMove = useCallback(
    (e: any) => {
      if (isDragging && wrapperRef.current && lastClientX.current) {
        wrapperRef.current.scrollLeft -= -lastClientX.current + e.clientX
        lastClientX.current = e.clientX
      }
    },
    [isDragging],
  )

  const handleMouseOut = useCallback(() => {
    setIsDragging(false)
  }, [])

  const handleMouseDown = useCallback(
    (e: any) => {
      // button prop 0 is left mouse click
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#Return_value
      if (e.button === 0 && !isDragging) {
        e.preventDefault()
        setIsDragging(true)
        initialX.current = e.clientX
        lastClientX.current = e.clientX
      }
    },
    [isDragging],
  )

  const handleDocumentMouseOut = useCallback(
    (e: any) => {
      // copy & paster: https://stackoverflow.com/a/9229957/612202
      if (isDragging && e.toElement == null && e.relatedTarget == null) {
        setIsDragging(false)
      }
    },
    [isDragging],
  )

  const handleCarouselScroll = useCallback(
    (e) => {
      const node = e.target as HTMLDivElement

      const scrollRight =
        node.scrollWidth - (node.scrollLeft + node.clientWidth)
      const scrollLeft = node.scrollLeft

      if (scrollLeft < 50) {
        setHiddenEdge(HIDDEN_EDGE.LEFT)
      } else if (scrollRight < 50) {
        setHiddenEdge(HIDDEN_EDGE.RIGHT)
      } else if (hiddenEdge !== HIDDEN_EDGE.NONE) {
        setHiddenEdge(HIDDEN_EDGE.NONE)
      }
    },
    [hiddenEdge],
  )

  const handleMouseUp = useCallback(
    (e) => {
      if (isDragging && initialX.current) {
        // checks whether the product click should be propagated
        // distance must've moved less than 3px to trigger product click
        // click is blocked/propagated to `handleWrapperCaptureClick`
        shouldPropagateClick.current =
          Math.abs(e.clientX - initialX.current) < 3
        setIsDragging(false)
      }
    },
    [isDragging],
  )

  const handleScrollWheel = useCallback((e) => {
    // wheelDeltaY > 0: scroll up
    // wheelDeltaY < 0: scroll down

    const r = wrapperRef.current

    if (!r) return

    const deltaY = e.wheelDeltaY || e.nativeEvent.wheelDeltaY || -e.deltaY * 40

    if (
      // scrolling up without the left side being reached
      (deltaY > 0 && r.scrollLeft > 0) ||
      // scrolling down without the right side being reached
      (deltaY < 0 && r.clientWidth + r.scrollLeft < r.scrollWidth)
    ) {
      e.preventDefault()
      // noinspection JSSuspiciousNameCombination
      r.scrollLeft -= deltaY
      setIsPageScrollBlocked(true)
    } else {
      setIsPageScrollBlocked(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousemove', handleDocumentMouseMove)
    document.addEventListener('mouseout', handleDocumentMouseOut)

    return () => {
      document.removeEventListener('mousemove', handleDocumentMouseMove)
      document.removeEventListener('mouseout', handleDocumentMouseOut)
    }
  }, [handleDocumentMouseMove, handleDocumentMouseOut])

  return (
    <BlockPageScroll
      enabled={isPageScrollBlocked}
      onClickCapture={handleWrapperCaptureClick}
      {...styles.wrapper(hiddenEdge)}
    >
      <div
        {...styles.inner}
        ref={wrapperRef}
        onWheel={handleScrollWheel}
        onScroll={handleCarouselScroll}
        onMouseUp={handleMouseUp}
        onMouseMove={handleDocumentMouseMove}
        onMouseDown={handleMouseDown}
        onMouseOut={handleMouseOut}
      >
        {children}
      </div>
    </BlockPageScroll>
  )
}

export default HorizontalScrollerHook
