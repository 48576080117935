import useProductListInfo from './useProductListInfo'
import { BrandWithSlug } from '../../types'
import useDatabaseQuery from '../../utils/useDatabaseQuery'

const useBrand = () => {
  const { params } = useProductListInfo()
  const { brand } = params

  return useDatabaseQuery<BrandWithSlug>(`/brands/${brand}`, {
    enabled: Boolean(params.brand),
  })
}

export default useBrand
