import { polyfill as smoothscroll } from 'smoothscroll-polyfill'
import fastclick from 'react-fastclick'
import 'cross-fetch/polyfill'

export default function loadPolyfills() {
  const polyfills: Promise<any>[] = []

  smoothscroll()
  fastclick()

  if (!global.Intl) {
    polyfills.push(import('intl'))
    polyfills.push(import('intl/locale-data/jsonp/de.js'))
  }

  if (!supportsIntersectionObserver()) {
    polyfills.push(import('intersection-observer'))
  }

  return Promise.all(polyfills)
}

function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  )
}
