import React, { FunctionComponent } from 'react'
import { css } from 'glamor'
import Currency from 'react-currency-formatter'

const priceStyles = {
  new: css({
    fontWeight: 700,
  }),
  old: css({
    fontWeight: 300,
    fontStyle: 'italic',
    textDecoration: 'line-through',
  }),
}

interface IPriceTextProps {
  price: number
  currency: string
  isOld?: boolean
}

// default prop EUR for currency does not work as `null` is passed
const PriceText: FunctionComponent<IPriceTextProps> = ({
  price,
  currency,
  isOld = false,
}) => (
  <div {...css(isOld ? priceStyles.old : priceStyles.new)}>
    <Currency quantity={price} currency={currency} locale="de_DE" />
  </div>
)

export default PriceText
