import { css } from 'glamor'
import React, { FunctionComponent, ReactNode } from 'react'
import { createMQ } from '../Responsive'

const styles = {
  wrapper: (image?: string) =>
    css({
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      position: 'relative',
      background: image && `url(${image}) center`,
      backgroundSize: 'cover',
      ':before': {
        content: "''",
        display: 'block',
        // height / width
        paddingBottom: `calc(300 / 980 * 100%)`,
      },
      [createMQ('mobile')]: {
        margin: '0 -50px',
      },
    }),
  box: css({
    width: '35%',
    background: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
    userSelect: 'none',
    borderLeft: '10px solid #fff',
    [createMQ('mobile')]: {
      width: '100%',
      borderLeft: 'none',
      background: 'rgba(0, 0, 0, 0.35)',
    },
  }),
  h1: css({
    fontFamily: `'Playfair Display', serif`,
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 400,
  }),
  subtitle: css({
    fontSize: 15,
  }),
  title: hasSubtitle =>
    css({
      border: '2px solid #fff',
      borderLeft: 'none',
      fontSize: 21,
      borderRight: 'none',
      margin: '10px 20px',
      padding: 10,
      fontWeight: 600,
      [createMQ('mobile')]: {
        borderBottom: hasSubtitle && 'none',
      },
    }),
}

interface IHeroHeaderProps {
  title: ReactNode
  subtitle: ReactNode
  image?: string
}

const HeroHeader: FunctionComponent<IHeroHeaderProps> = ({
  image,
  title,
  subtitle,
}) => {
  return (
    <div {...styles.wrapper(image)}>
      <div {...styles.box}>
        <h1 {...styles.h1}>
          {subtitle && <span {...styles.subtitle}>{subtitle}</span>}
          <div {...styles.title(!!subtitle)}>{title}</div>
        </h1>
      </div>
    </div>
  )
}

export default HeroHeader
