import { RefObject, useEffect, useState } from 'react'

const useFixedTopOffset = (ref: RefObject<HTMLElement>, isFixed: boolean) => {
  const [topOffset, setTopOffset] = useState<number | null>(null)

  useEffect(() => {
    if (ref.current) {
      setTopOffset(isFixed ? -ref.current.getBoundingClientRect().top : null)
    }
  }, [isFixed, ref])

  return topOffset
}

export default useFixedTopOffset
