import React from 'react'

const DoubleArrowDown = props => (
  <svg width={8} height={8} viewBox="0 0 10 10" {...props}>
    <path
      d="M4.994 7.667L.752 4.689l-.504.728 4.605 3.621h.316l4.582-3.621-.504-.728-4.253 2.978zM9.248.472L4.994 3.449.752.472l-.504.715 4.605 3.62h.316l4.582-3.62-.503-.715z"
      fill="#1E1E1C"
    />
  </svg>
)

export default DoubleArrowDown
