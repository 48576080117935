import React, { FunctionComponent, MouseEvent } from 'react'
import { css } from 'glamor'

export const BORDER_RADIUS = 3

const styles = {
  wrapper: (contrast: boolean, noPadding: boolean, hasClick: boolean) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      padding: !noPadding && 10,
      borderRadius: BORDER_RADIUS,
      background: contrast ? '#000' : '#fff',
      color: !contrast ? '#000' : '#fff',
      border: '1px solid #000',
      textTransform: 'uppercase',
      cursor: hasClick && 'pointer',
    }),
}

export interface IButtonProps {
  contrast?: boolean
  noPadding?: boolean
  onClick?: (e: MouseEvent) => void
}

const Button: FunctionComponent<IButtonProps> = ({
  noPadding = false,
  contrast = false,
  onClick,
  children,
  ...props
}) => {
  return (
    <div
      {...css(styles.wrapper(contrast, noPadding, !!onClick))}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  )
}

export default Button
