import React from 'react'
import { css } from 'glamor'

import { MAX_WIDTH } from './Layout'
import { createMQ } from './Responsive'
import Link from './Link'
import gold from './utils/gold'
import { useIsNativeApp } from './utils/nativeApp'
// import Logo from './Logo'

export const MARGIN = 60

const styles = {
  wrapper: css({
    background: '#000',
    display: 'flex',
    color: '#fff',
    padding: '90px 50px',
    [createMQ('mobile')]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    marginTop: MARGIN,
    fontFamily: `'Playfair Display'`,
  }),
  inner: css({
    width: '100%',
    margin: '0 auto',
    maxWidth: MAX_WIDTH,
  }),
}

const Footer = () =>
  useIsNativeApp() ? null : (
    <div {...styles.wrapper}>
      <div {...styles.inner}>
        <div {...css({ margin: '0 20px' })}>
          <div {...css({ display: 'flex', flexDirection: 'column' })}>
            <div
              {...css({
                display: 'flex',
                fontSize: 18,
                alignItems: 'center',
                flexDirection: 'column',
                userSelect: 'none',
              })}
            >
              <div {...css({ color: gold })}>Dress Different.</div>
              <div
                {...css({
                  fontSize: 70,
                  marginBottom: 80,
                })}
              >
                Maprodo
              </div>
            </div>
            <div
              {...css({
                display: 'flex',
                [createMQ('mobile', 'tablet')]: {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              })}
            >
              <div
                {...css({
                  flex: 1,
                  textAlign: 'center',
                })}
              >
                <ul
                  {...css({
                    display: 'flex',
                    [createMQ('mobile', 'tablet')]: {
                      flexDirection: 'column',
                    },
                    '> li': {
                      paddingRight: 15,
                      [createMQ('mobile', 'tablet')]: { paddingBottom: 15 },
                    },
                  })}
                >
                  <li>
                    <Link to="/magazin" color="#c1c1c1" showActive={false}>
                      Magazin
                    </Link>
                  </li>
                  <li>
                    <Link to="/impressum" color="#c1c1c1" showActive={false}>
                      Impressum
                    </Link>
                  </li>
                  <li>
                    <Link to="/datenschutz" color="#c1c1c1" showActive={false}>
                      Datenschutz
                    </Link>
                  </li>
                  {/*<li>
                  <Link to="/kontakt" color="#c1c1c1" showActive={false}>
                    Kontakt
                  </Link>
                </li>
                <li>
                  <Link to="/presse" color="#c1c1c1" showActive={false}>
                    Presse
                  </Link>
                </li>*/}
                </ul>
              </div>
              <div {...css({ flex: 1 })}>
                <ul
                  {...css({
                    display: 'flex',
                    justifyContent: 'center',
                    '> li': {
                      padding: '0 15px',
                    },
                  })}
                >
                  <li>
                    <a
                      href="https://www.facebook.com/maprodo"
                      target="_blank"
                      rel="noopener noreferrer"
                      {...css({ color: '#c1c1c1 !important' })}
                    >
                      {`// facebook`}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/maprodo.de"
                      target="_blank"
                      rel="noopener noreferrer"
                      {...css({ color: '#c1c1c1 !important' })}
                    >
                      {`// instagram`}
                    </a>
                  </li>
                </ul>
              </div>
              <div
                {...css({
                  flex: 1,
                  textAlign: 'right',
                  color: '#c1c1c1',
                  [createMQ('mobile', 'tablet')]: {
                    marginTop: 80,
                    textAlign: 'center',
                  },
                })}
              >
                © {new Date().getFullYear()} by Maprodo. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

export default Footer
