import React, { Fragment } from 'react'
import { css } from 'glamor'
import { Link, Route, useLocation, useParams } from 'react-router-dom'

import LayoutStyles from '../Layout'
import ProductMode from '../Product/ProductMode'
import Responsive, { createMQ } from '../Responsive'
import { getCategoryPath, getFromPathname } from '../Category/utils'
import BrandCrumbs from './BrandCrumbs'

const styles = {
  wrapper: css({
    display: 'flex',
    padding: '25px 50px',
    [createMQ('mobile')]: {
      padding: 25,
    },
  }),
  spacer: css({
    height: 2,
    background: 'black',
    margin: 20,
    marginTop: 0,
  }),
  inner: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [createMQ('mobile')]: {
      justifyContent: 'center',
    },
  }),
}

const genderMap = {
  damen: 'Damen',
  herren: 'Herren',
}

const CategoryBreadCrumbs = () => {
  const { pathname } = useLocation()
  const { gender } = useParams<{ gender: string }>()

  const breadcrumbs = getCategoryPath(getFromPathname(pathname))
  let link = `/${gender}`

  return breadcrumbs.length >= 1 ? (
    <div>
      <Link to={link}>{genderMap[gender]}</Link>
      &nbsp;&raquo;&nbsp;
      {breadcrumbs.map((category, index) => {
        link += `/${category.slug}`
        return (
          <Fragment key={category.slug}>
            <Link to={link}>{category.name}</Link>
            {index < breadcrumbs.length - 1 && <>&nbsp;&raquo;&nbsp;</>}
          </Fragment>
        )
      })}
    </div>
  ) : (
    <div>&nbsp;</div>
  )
}

const SubHeader = () => (
  <>
    <div {...styles.wrapper}>
      <div {...LayoutStyles.left()} />
      <div {...css(LayoutStyles.middle, styles.inner)}>
        <Responsive tablet desktop>
          <ProductMode type="category">
            <CategoryBreadCrumbs />
          </ProductMode>
          <ProductMode type="brand">
            <Route path="/marken/:brand/:gender(damen|herren)?/:category?">
              <BrandCrumbs />
            </Route>
          </ProductMode>
          <Route path="/produkt">
            <div>&nbsp;</div>
          </Route>
        </Responsive>
      </div>
      <div {...LayoutStyles.right} />
    </div>
    <div {...styles.spacer} />
  </>
)

export default React.memo(SubHeader)
