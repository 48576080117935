import 'core-js/features/string/includes'
import 'core-js/features/object/values'
import 'core-js/features/object/entries'
import 'core-js/features/array/includes'
import 'core-js/features/array/from'
import 'core-js/features/symbol'
import 'core-js/features/set'
import 'core-js/features/map'

import React, { StrictMode } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { Route } from 'react-router'

import ErrorBoundary from './ErrorBoundary'
import SidebarProvider from './Sidebar/SidebarProvider'
import Main from './Main'

import './assets/_normalize.css'
import './assets/index.css'

const App = () => (
  <StrictMode>
    <ErrorBoundary>
      <QueryParamProvider ReactRouterRoute={Route}>
        <SidebarProvider>
          <Main />
        </SidebarProvider>
      </QueryParamProvider>
    </ErrorBoundary>
  </StrictMode>
)

export default App
