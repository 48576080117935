import { useLocation, useRouteMatch } from 'react-router'

export type ParamGender = 'damen' | 'herren'
type ApiGender = 'weiblich' | 'männlich'
type DbGender = 'female' | 'male'
// type DbGenderNeutral = 'female' | 'male' | 'neutral'
type FormattedGender = 'Damen' | 'Herren'

export const paramToDbGender = (gender: ParamGender): DbGender =>
  gender === 'damen' ? 'female' : 'male'

export const anyToApGender = (gender?: ParamGender | ApiGender | DbGender) => {
  switch (gender) {
    case 'damen':
    case 'weiblich':
    case 'female':
      return 'weiblich'
    case 'herren':
    case 'männlich':
    case 'male':
      return 'männlich'
    default:
      return undefined
  }
}

export const paramToFormattedGender = (gender: ParamGender) =>
  ({ damen: 'Damen', herren: 'Herren' }[gender] as FormattedGender)

export enum ProductListMode {
  GENDER_ONLY = 'GENDER_ONLY',
  GENDER_CATEGORY = 'GENDER_CATEGORY',
  BRAND_ONLY = 'BRAND_ONLY',
  BRAND_CATEGORY = 'BRAND_CATEGORY',
  BRAND_GENDER = 'BRAND_GENDER',
  BRAND_GENDER_CATEGORY = 'BRAND_GENDER_CATEGORY',
  SEARCH = 'SEARCH',
}

interface IRouteParams {
  brand?: string
  category?: string
  gender?: ParamGender
  searchTerm?: string
  cat1?: string
  cat2?: string
  cat3?: string
  cat4?: string
}

const hasCategory = (params: IRouteParams) => Boolean(params.cat1)

const getMode = (params?: IRouteParams): ProductListMode => {
  if (!params) {
    return ProductListMode.GENDER_ONLY
  }

  if (!params.brand && hasCategory(params) && params.gender) {
    return ProductListMode.GENDER_CATEGORY
  } else if (!params.brand && !hasCategory(params) && params.gender) {
    return ProductListMode.GENDER_ONLY
  } else if (params.brand && params.gender && hasCategory(params)) {
    return ProductListMode.BRAND_GENDER_CATEGORY
  } else if (params.brand && !params.gender && hasCategory(params)) {
    return ProductListMode.BRAND_CATEGORY
  } else if (params.brand && params.gender && !hasCategory(params)) {
    return ProductListMode.BRAND_GENDER
  } else if (params.brand && !params.gender && !hasCategory(params)) {
    return ProductListMode.BRAND_ONLY
  } else if (params.searchTerm) {
    return ProductListMode.SEARCH
  }
  return ProductListMode.GENDER_ONLY
}

const useProductListInfo = () => {
  const { search, pathname } = useLocation()

  const genderList = useRouteMatch(
    '/:gender(damen|herren)/:cat1?/:cat2?/:cat3?/:cat4?'
  )
  const brandList = useRouteMatch(
    '/marken/:brand/:gender(damen|herren)?/:cat1?'
  )
  const searchList = useRouteMatch('/suche/:searchTerm')

  const params: IRouteParams =
    genderList?.params || brandList?.params || searchList?.params || {}
  const mode = getMode(params)

  return {
    mode,
    params,
    search,
    pathname,
  }
}

export default useProductListInfo
