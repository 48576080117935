import React from 'react'
import { css } from 'glamor'
import { useHistory } from 'react-router'
import HorizontalScoller from '../HorizontalScoller'
import { getSlug } from './ProductDetailPage'
import ProductItem from './ProductItem'
import { Product } from '../types'

interface IProductInlineListProps {
  products?: Product[]
  loading?: boolean
}

const ProductInlineList = ({
  products = [],
  loading = false,
}: IProductInlineListProps) => {
  const history = useHistory()
  return (
    <HorizontalScoller>
      {loading ? (
        <div {...css({ height: 358 })} />
      ) : (
        products.length > 0 &&
        products.map((product, index) => (
          <ProductItem
            key={product.id}
            onSelect={() =>
              history.push(`/produkt/${product.id}/${getSlug(product)}`)
            }
            onRequestClose={undefined}
            isOpen={false}
            item={product}
            listIndex={index}
          />
        ))
      )}
    </HorizontalScoller>
  )
}

export default ProductInlineList
