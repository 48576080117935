import { decodeQueryParams, parse } from 'use-query-params'
import { queryParamConfig } from './hooks/useQueryFilterParams'

type ParamConfigType = typeof queryParamConfig

const queryParamToApiParamMap = Object.entries(queryParamConfig).reduce(
  (all, [key, val]) => ({ ...all, [val[0]]: key }),
  {} as { [T in keyof ParamConfigType as ParamConfigType[T][0]]: T }
)

function renameKeys<T>(obj: T, newKeys) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key
    return { [newKey]: obj[key] }
  })
  return Object.assign({}, ...keyValues)
}

const getApiParams = queryString => {
  const mappedConfig = Object.values(queryParamConfig).reduce(
    (allParams, [queryParam, type]) => ({ ...allParams, [queryParam]: type }),
    {} as {
      [T in keyof ParamConfigType as ParamConfigType[T][0]]: ParamConfigType[T][1]
    }
  )

  const decoded = decodeQueryParams(mappedConfig, parse(queryString))
  // map to API params
  return renameKeys(decoded, queryParamToApiParamMap)
}

export default getApiParams
