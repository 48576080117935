import React, { FunctionComponent } from 'react'
import FilterBox, { IFilterBoxProps } from '../FilterBox/FilterBox'
import Tick from '../Tick'
import { css } from 'glamor'
import { createMQ, is } from '../Responsive'
import { ColorFacet } from '../types'

export const colorMap = {
  Schwarz: '#000000',
  Braun: '#795548',
  Beige: '#fbfbcc',
  Grau: '#9E9E9E',
  Weiß: '#FFFFFF',
  Blau: '#2196F3',
  Hellblau: '#03A9F4',
  Türkis: '#00BCD4',
  Hellgrün: '#8BC34A',
  Grün: '#4CAF50',
  Gelb: '#FFEB3B',
  Orange: '#FF9800',
  Rot: '#F44336',
  Rosa: '#E91E63',
  Lila: '#9C27B0',
  Gold: 'gold',
  Silber: 'silver',
  Mehrfarbig: undefined,
} as const

interface IColorFilterBoxProps extends Omit<IFilterBoxProps, 'label'> {
  onSelect: (name: string) => void
  colors?: ColorFacet[]
  selected?: string[]
}

const ColorFilterBox: FunctionComponent<IColorFilterBoxProps> = ({
  onSelect,
  selected = [],
  colors = [],
  ...props
}) => (
  <FilterBox
    label={`Farbe${selected?.length > 0 ? ` (${selected.length})` : ''} `}
    clearable={selected?.length > 0}
    {...props}
  >
    <div
      {...css({
        display: 'flex',
        flexWrap: 'wrap',
        [createMQ('mobile')]: {
          justifyContent: 'center',
        },
      })}
    >
      {colors.map(({ name }) => (
        <div
          key={name}
          title={name}
          {...css({ cursor: 'pointer' })}
          onClick={() => onSelect(name)}
        >
          {name === 'Mehrfarbig' ? (
            ''
          ) : (
            <Tick
              className={css({
                width: 32,
                height: 32,
                [createMQ('mobile')]: { width: 45, height: 45 },
              }).toString()}
              tickStrokeWidth={0.75}
              circleStrokeWidth={['Weiß', 'Beige'].includes(name) ? 0.5 : 0}
              strokeColor={
                ['Weiß', 'Beige', 'Gelb', 'Gold'].includes(name)
                  ? 'black'
                  : 'white'
              }
              // radius={is('mobile') ? 50 : 32}
              checked={selected.includes(name)}
              fill={colorMap[name]}
            />
          )}
        </div>
      ))}
    </div>
  </FilterBox>
)

export default ColorFilterBox
