import React, { ReactNode } from 'react'
import { css } from 'glamor'

import ChevronLeftIcon from '../icons/BaselineChevronLeft'
import GenderFilterBox from '../Product/BrandGenderFilterBox'
import ProductMode from '../Product/ProductMode'
import SidebarWrapper from './SidebarWrapper'
import PriceFilterBox from '../Product/PriceFilterBox'
import useQueryFilterParams from '../utils/hooks/useQueryFilterParams'
import SortingBox from '../Product/SortingBox'
import ColorFilterBox from '../Product/ColorFilterBox'
import BrandFilterBox from '../Product/BrandFilterBox'
import Button from '../Button'
import useFacets from '../Product/hooks/useFacets'
import useProducts from '../Product/hooks/useProducts'

export const Divider = ({ height = 1 }) => (
  <div
    {...css({
      margin: '5px 0',
      background: '#979797',
      height,
    })}
  />
)

export const Headline = ({
  onClickBack,
  children,
}: {
  onClickBack?: (e: React.MouseEvent) => void
  children: ReactNode
}) => (
  <div
    {...css({
      display: 'flex',
      flexDirection: 'row',
      padding: '22px 20px',
      position: 'relative',
    })}
  >
    {onClickBack && (
      <div
        onClick={onClickBack}
        {...css({ position: 'absolute', cursor: 'pointer' })}
      >
        <ChevronLeftIcon />
      </div>
    )}
    <div
      {...css({
        fontSize: 20,
        flex: 1,
        fontWeight: 400,
        textAlign: 'center',
        textTransform: 'uppercase',
      })}
    >
      {children}
    </div>
  </div>
)

interface IFilterSidebarProps {
  open: boolean
  width: string | number
  onRequestClose: () => void
}

const FilterSidebar = ({
  open,
  width,
  onRequestClose,
}: IFilterSidebarProps) => {
  const facets = useFacets()
  const params = useQueryFilterParams()
  const products = useProducts()

  return facets.isSuccess ? (
    <SidebarWrapper
      position="right"
      open={open}
      width={width}
      bottomArea={
        <Button
          contrast
          {...css({
            width: '100%',
            fontWeight: 400,
            textAlign: 'center',
          })}
          onClick={onRequestClose}
        >
          {products.isSuccess && products.data.count} Ergebnisse anzeigen
        </Button>
      }
    >
      <div {...css({ padding: '0 20px', marginBottom: 60 })}>
        <Headline onClickBack={onRequestClose}>Filter</Headline>
        <Divider />
        <PriceFilterBox
          onMinPriceChange={params.setMinPrice}
          onMaxPriceChange={params.setMaxPrice}
          priceRange={[facets.data.price.min, facets.data.price.max]}
          selectedRange={[
            params.price_min || facets.data.price.min,
            params.price_max || facets.data.price.max,
          ]}
          hasUserSelectedRange={Boolean(params.price_min || params.price_max)}
          onClear={params.clearPriceFilter}
          onSaleChange={(_val, checked) => params.setSale(checked || undefined)}
          isSaleChecked={params.sale}
          forceOpen
        />
        <Divider />
        <ProductMode type="category">
          <BrandFilterBox
            onSelect={params.selectBrand}
            selected={params.brand_id}
            brands={facets.data.brand_id}
            onClear={params.clearBrand}
            forceOpen
          />
        </ProductMode>
        <ProductMode type="brand">
          <GenderFilterBox
            // onSelect={params.selectGender}
            // selected={params.gender}
            forceOpen
          />
        </ProductMode>
        <Divider />
        <ColorFilterBox
          onSelect={params.selectColor}
          selected={params.color}
          colors={facets.data.color}
          onClear={params.clearColor}
          forceOpen
        />
        <Divider />
        <SortingBox
          onSelect={params.setSorting}
          selected={params.sorting}
          forceOpen
        />
      </div>
    </SidebarWrapper>
  ) : null
}

export default FilterSidebar
