import { Link } from 'react-router-dom'
import { css } from 'glamor'
import { createMQ } from '../Responsive'
import React from 'react'

const MagazineSuggestionHeader = () => (
  <Link to="/magazin">
    <img
      alt="Maprodo Magazin"
      src="/assets/kategorie_magazin.jpg"
      {...css({
        width: '100%',
        cursor: 'pointer',
        transform: 'translateY(-1px)',
        [createMQ('desktop')]: {
          display: 'none',
        },
      })}
    />
  </Link>
)

export default MagazineSuggestionHeader
