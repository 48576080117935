import useProductListInfo, {
  paramToDbGender,
  ProductListMode,
} from './useProductListInfo'
import { getCategoryFromPathname } from '../../Category/utils'
import useDatabaseQuery from '../../utils/useDatabaseQuery'

const useDescriptionText = () => {
  const { mode, params, pathname } = useProductListInfo()
  const category = getCategoryFromPathname(pathname)
  const gender = paramToDbGender(params.gender ?? 'damen')

  let enabled = false

  if (gender) {
    enabled = true
  }
  if (params.cat1 && category) {
    enabled = true
  }

  if (params.cat1 && !category) {
    enabled = false
  }

  const path = category
    ? params.brand
      ? `/descriptions/brand-category/${params.brand}/${category.slug}/${gender}`
      : `/descriptions/category/${category.slug}/${gender}`
    : params.brand
    ? `/descriptions/brand/${params.brand}/${gender}`
    : `/descriptions/category/_${gender}`

  return useDatabaseQuery<string>(path, {
    enabled:
      enabled &&
      [
        ProductListMode.GENDER_ONLY,
        ProductListMode.GENDER_CATEGORY,
        ProductListMode.BRAND_CATEGORY,
        ProductListMode.BRAND_GENDER_CATEGORY,
      ].includes(mode),
  })
}

export default useDescriptionText
