import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { css } from 'glamor'
import { createMQ } from '../Responsive'
// import ArrowsRight from '../icons/PageNext'

const styles = {
  wrapper: css({
    [createMQ('desktop')]: {
      paddingBottom: 5,
    },
  }),
  link: css({
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'uppercase',
    textDecoration: 'none',
    [createMQ('mobile', 'tablet')]: {
      width: '100%',
      height: '100%',
      padding: '16px 20px 16px 35px',
      borderBottom: '1px solid #d4d4d4',
      boxShadow: '0 1px 2px 0px #ececec',
      display: 'flex',
      justifyContent: 'space-between',
    },
    [createMQ('desktop')]: {
      ':hover': {
        fontWeight: 600,
      },
    },
  }),
}

interface ICategoryItemProps {
  hasSubs?: boolean
  onClick?: (hasSubs: boolean) => void
  path: string
  children: ReactNode
}

const CategoryItem = ({
  hasSubs = false,
  onClick = () => {},
  path,
  children,
  ...props
}: ICategoryItemProps) => (
  <div {...styles.wrapper} className="category-item" {...props}>
    <NavLink
      activeStyle={{ fontWeight: 600 }}
      {...styles.link}
      exact
      to={path}
      onClick={() => onClick(hasSubs)}
    >
      <span>{children}</span>
      {hasSubs && (
        <>
          &nbsp;
          <img src="/assets/icons/arrowRight_8x8.svg" alt="" />
        </>
      )}
    </NavLink>
  </div>
)

export default CategoryItem
