import React, { Fragment, useMemo } from 'react'
import Link from '../Link'
import useProductListInfo, {
  paramToFormattedGender,
} from '../Product/hooks/useProductListInfo'
import useBrand from '../Product/hooks/useBrand'
import useBrandCategories from '../Product/hooks/useBrandCategories'

const BrandCrumbs = () => {
  const { params } = useProductListInfo()

  const brand = useBrand()
  const brandCategories = useBrandCategories()

  const brandCategory = useMemo(() => {
    if (!brandCategories.isSuccess || !brandCategories.data) return

    return Object.values(brandCategories.data).find(
      category => category.slug === params.cat1
    )
  }, [brandCategories.data, brandCategories.isSuccess, params.cat1])

  const crumbs = useMemo(() => {
    const crumbs = [['marken', 'Marken']]
    if (brand.isSuccess && brand.data) {
      crumbs.push([brand.data._slug, brand.data.name])
    }

    if (params.gender) {
      crumbs.push([params.gender, paramToFormattedGender(params.gender)])
    }

    if (brandCategory) {
      crumbs.push([brandCategory.slug, brandCategory.name])
    }

    return crumbs
  }, [brand.isSuccess, brand.data, brandCategory, params.gender])

  if (!brand.isSuccess) return null

  let path = ''
  return (
    <div>
      {crumbs.map(([subPath, name], index) => {
        path += `/${subPath}`
        return (
          <Fragment key={path}>
            <Link to={path} showActive={false}>
              {name}
            </Link>
            {index < crumbs.length - 1 && <>&nbsp;&raquo;&nbsp;</>}
          </Fragment>
        )
      })}
    </div>
  )
}

export default BrandCrumbs
