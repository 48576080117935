import React, { ReactNode } from 'react'
import { css } from 'glamor'

const sidebarStyle = (open, position, width) =>
  css({
    overflow: 'auto',
    position: 'fixed',
    width,
    top: 0,
    zIndex: 3,
    left: position === 'left' && 0,
    right: position === 'right' && 0,
    bottom: 0,
    transition: 'transform 250ms ease-in-out',
    backfaceVisibility: 'hidden',
    boxShadow:
      open &&
      `${position === 'left' ? '' : '-'}2px 0px 5px 0px rgba(70, 70, 70, 0.35)`,
    WebkitOverflowScrolling: 'touch',
    willChange: 'transform',
    transform: !open
      ? `translate3d(${position === 'left' ? '-' : ''}${width}, 0, 0)`
      : 'translate3d(0, 0, 0)',
  })

const bottomAreaStyle = (open, position, width) =>
  css({
    width,
    background: '#fff',
    height: 60,
    bottom: 0,
    position: 'fixed',
    [position]: 0,
    zIndex: 12,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform:
      !open && `translate3d(${position === 'left' ? '-' : ''}${width}, 0, 0)`,
    transition: 'transform 250ms ease-in-out',
  })

interface ISidebarWrapperProps {
  children: ReactNode
  open: boolean
  position: 'left' | 'right'
  width: string | number
  bottomArea?: ReactNode
}

const SidebarWrapper = ({
  open,
  position,
  width,
  children,
  bottomArea,
  ...props
}: ISidebarWrapperProps) => {
  return (
    <>
      <div {...sidebarStyle(open, position, width)} {...props}>
        {children}
      </div>
      {bottomArea && (
        <div {...bottomAreaStyle(open, position, width)}>{bottomArea}</div>
      )}
    </>
  )
}

export default SidebarWrapper
