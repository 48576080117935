import React, { useRef, useState } from 'react'
import { css } from 'glamor'
import { useHistory, useRouteMatch } from 'react-router'
import Link from '../Link'
import { Link as RouterLink } from 'react-router-dom'
import LayoutStyles from '../Layout'

import HeartIcon from '../icons/HeartFilled'
import MenuIcon from '../icons/Menu'
import SearchIcon from '../icons/Search'
import Responsive, { createMQ, VERY_SMALL_MQ } from '../Responsive'
import FullPageSearch, { executeSearch } from '../FullPageSearch'
import useFixedTopOffset from '../utils/hooks/useFixedTopOffset'
import { useSidebarContext } from '../Sidebar/SidebarProvider'
import { useIsNativeApp } from '../utils/nativeApp'

const styles = {
  header: css({
    background: '#000',
    height: 100,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 300,
    transition: 'height 500ms',
    [createMQ('mobile', 'tablet')]: {
      height: 70,
      zIndex: 10,
      position: 'sticky',
      top: 0,
    },
  }),
  headerFixed: top =>
    css({
      [createMQ('mobile', 'tablet')]: {
        position: 'relative',
        top,
      },
    }),
  headerInner: css({
    display: 'flex',
    justifyContent: 'space-between',
    [createMQ('mobile', 'tablet')]: {
      paddingLeft: 25,
      paddingRight: 25,
    },
    [createMQ('desktop')]: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 20,
    },
  }),
  mobileMenuIcon: css({
    padding: 20,
    margin: -20,
  }),
  topMenu: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  }),
  topMenuInner: css({
    display: 'flex',
    flex: 1,
    maxWidth: 275,
    textTransform: 'uppercase',
    justifyContent: 'space-between',
  }),
  logo: css({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    [createMQ('desktop')]: {
      marginTop: -12,
    },
  }),
  logoImage: css({
    height: 40,
    [VERY_SMALL_MQ]: {
      height: 30,
    },
  }),
  wishlist: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  inputStyle: css({
    color: '#fff',
    padding: 0,
    margin: 0,
    background: 'transparent',
    border: 'none',
    width: '100%',
    '::placeholder': {
      textTransform: 'uppercase',
      color: '#fff',
    },
  }),
  hitbox: css({
    cursor: 'pointer',
    [createMQ('mobile')]: {
      padding: 10,
      margin: -10,
    },
  }),
}

const Header = () => {
  const history = useHistory()
  const match = useRouteMatch<{ searchTerm?: string }>('/suche/:searchTerm')
  const [searchValue, setSearchValue] = useState(
    match ? match.params?.searchTerm : ''
  )
  const { open, current } = useSidebarContext()
  const isFixed = current !== undefined

  const handleMenuClick = () => open('category')
  const handleWishlistClick = () => open('wishlist')

  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const headerRef = useRef<HTMLDivElement>(null)
  const topOffset = useFixedTopOffset(headerRef, isFixed)

  return (
    <>
      {isSearchOpen && (
        <FullPageSearch
          searchTerm={searchValue}
          onRequestClose={() => setIsSearchOpen(false)}
        />
      )}
      <div
        {...css(styles.header, isFixed && styles.headerFixed(topOffset))}
        ref={headerRef}
      >
        <div {...LayoutStyles.left()} />
        <div {...css(styles.headerInner, LayoutStyles.middle)}>
          <div {...styles.topMenu}>
            <Responsive mobile tablet>
              <div {...styles.mobileMenuIcon} onClick={handleMenuClick}>
                <MenuIcon />
              </div>
            </Responsive>
            <Responsive desktop wrap={false}>
              <div {...styles.topMenuInner}>
                <div>
                  <Link hover to="/damen" color="#fff">
                    Damen
                  </Link>
                </div>
                <div>
                  <Link hover to="/herren" color="#fff">
                    Herren
                  </Link>
                </div>
                <div>
                  <Link hover to="/marken" color="#fff">
                    Marken
                  </Link>
                </div>
              </div>
            </Responsive>
          </div>
          <div {...styles.logo}>
            <RouterLink to="/">
              <img src="/assets/maprodo.png" alt="Logo" {...styles.logoImage} />
            </RouterLink>
          </div>
          <div {...styles.wishlist}>
            <div
              {...css({
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                maxWidth: 275,
                justifyContent: 'flex-end',
              })}
            >
              <Responsive tablet desktop>
                <div
                  {...css({
                    display: 'flex',
                    borderBottom: '1px solid #fff',
                    width: '100%',
                  })}
                >
                  <input
                    type="text"
                    value={searchValue}
                    placeholder="Suche…"
                    onChange={e => setSearchValue(e.target.value)}
                    onKeyUp={e =>
                      e.key === 'Enter' &&
                      searchValue &&
                      executeSearch(history, searchValue)
                    }
                    {...styles.inputStyle}
                  />
                  <SearchIcon
                    style={{ cursor: 'pointer' }}
                    fill="#fff"
                    onClick={() => executeSearch(history, searchValue)}
                  />
                </div>
              </Responsive>
              <Responsive mobile wrap={false}>
                <div
                  {...styles.hitbox}
                  onClick={() => setIsSearchOpen(!isSearchOpen)}
                >
                  <SearchIcon fill="#fff" />
                </div>
              </Responsive>

              <div {...css({ margin: '0 10px' })} />
              <div onClick={handleWishlistClick} {...styles.hitbox}>
                <HeartIcon width={20} height={20} />
              </div>
            </div>
          </div>
        </div>
        <div {...LayoutStyles.right} />
      </div>
    </>
  )
}

export default Header
