import React, { createContext, ReactNode, useContext, useMemo } from 'react'
const InitialUrlContext = createContext({
  hostname: '',
  origin: '',
  pathname: '',
  protocol: '',
  href: '',
})

export const useInitialUrlContext = () => useContext(InitialUrlContext)

export const InitialUrlContextProvider = ({
  url,
  children,
}: {
  url: string
  children: ReactNode
}) => {
  const { origin, hostname, protocol, pathname, href } = new URL(url)

  const value = useMemo(
    () => ({ hostname, origin, pathname, protocol, href }),
    [hostname, origin, pathname, protocol, href],
  )

  return (
    <InitialUrlContext.Provider value={value}>
      {children}
    </InitialUrlContext.Provider>
  )
}
