import React, { useRef, useEffect } from 'react'

const stopScroll = (e) => e.preventDefault()

const BlockPageScroll = ({ enabled, ...props }) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const el = scrollRef.current
    if (!el) return

    if (enabled) {
      el.addEventListener('wheel', stopScroll)
    }
    return () => {
      if (enabled) {
        el.removeEventListener('wheel', stopScroll)
      }
    }
  }, [enabled])

  return <div ref={scrollRef} {...props} />
}

export default BlockPageScroll
