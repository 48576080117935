import { newRidgeState } from 'react-ridge-state'
import omit from 'lodash/omit'

import { Product } from './types'
import { clientIsNative, sendToNativeApp } from './utils/nativeApp'

const WISHLIST_KEY = 'wishlist'

let wishlist = {}

if (process.env.BUILD_TARGET === 'client') {
  try {
    wishlist = JSON.parse(localStorage.getItem(WISHLIST_KEY) || '{}')
    if (clientIsNative()) {
      sendToNativeApp('wishlist', wishlist)
    }
  } catch {}
}

export type WishlistProduct = Omit<
  Product,
  'category' | 'description' | 'tags' | 'topseller' | 'url'
>

type StoreType = Record<string, WishlistProduct>

const cleanup = (item: Product) =>
  omit(item, 'category', 'description', 'tags', 'topseller', 'url')

const persist = (store: StoreType) =>
  localStorage.setItem(WISHLIST_KEY, JSON.stringify(store))

// would be nice to have these as a side effect like `persist`
// but ridge state doesn't provide public listeners as of now
const trackItemRemove = () => {
  window.gtag('event', 'remove_from_cart')
}

const trackItemAdd = (item: Product) => {
  window.fbq('track', 'AddToWishlist', {
    content_ids: [item.id],
    content_category: item.category.name,
    content_name: item.title,
    content_type: 'product',
  })
  window.gtag('event', 'add_to_cart', {
    items: [
      {
        id: item.id,
        name: item.title,
        brand: item.brand.name,
        category: item.category.name,
        quantity: 1,
        price: item.list_price.current,
      },
    ],
  })
}

const wishlistStore = newRidgeState<StoreType>(wishlist, {
  onSet: async (newState) => {
    persist(newState)
    if (clientIsNative()) {
      sendToNativeApp('wishlist', newState)
    }
  },
})

const addToWishlist = (item: Product) => {
  trackItemAdd(item)
  wishlistStore.set((prev) => ({ ...prev, [item.id]: cleanup(item) }))
}

const removeFromWishlist = (item: WishlistProduct) => {
  trackItemRemove()
  wishlistStore.set((prev) => {
    delete prev[item.id]
    return prev
  })
}

const toggleWishlistItem = (item: Product) => {
  if (item.id in wishlistStore.get()) {
    removeFromWishlist(item)
  } else {
    addToWishlist(item)
  }
}

const useAddedToWishlist = (itemId: string) =>
  wishlistStore.useSelector((state) => itemId in state)

export {
  wishlistStore,
  addToWishlist,
  removeFromWishlist,
  toggleWishlistItem,
  useAddedToWishlist,
}
