import React, { ReactNode } from 'react'
import json2mq from 'json2mq'
import { css } from 'glamor'
import Media from 'react-media'

const MQ = {
  mobile: { maxWidth: 779 },
  tablet: { minWidth: 780, maxWidth: 959 },
  desktop: { minWidth: 960 },
} as const

export const VERY_SMALL_MQ = `@media ${json2mq({ maxWidth: 480 })}`

type DeviceType = keyof typeof MQ

export const createMQString = (...devices: readonly DeviceType[]) => {
  devices = Array.isArray(devices[0]) ? devices[0] : [...devices]

  return json2mq(devices.map((device) => MQ[device]))
}

export const createMQ = (...devices: readonly DeviceType[]) =>
  `@media ${createMQString(...devices)}`

const matches = (mq: string) => window.matchMedia(mq).matches
export const is = (...screenSize: readonly DeviceType[]) =>
  typeof window !== 'undefined' && matches(createMQString(...screenSize))

const displayStyle = (mq: string) =>
  css({ [`@media ${mq}`]: { display: 'none !important' } })

interface IResponsiveProps {
  children: ReactNode
  mobile?: boolean
  tablet?: boolean
  desktop?: boolean
  onlyRenderOnMatch?: boolean
  wrap?: boolean
}

const Responsive = ({
  children,
  mobile = false,
  tablet = false,
  desktop = false,
  onlyRenderOnMatch = false,
  wrap = true,
}: IResponsiveProps) => {
  const bp: { [id: string]: number }[] = []

  if (onlyRenderOnMatch) {
    mobile && bp.push(MQ.mobile)
    tablet && bp.push(MQ.tablet)
    desktop && bp.push(MQ.desktop)

    return <Media query={bp} render={() => children} />
  } else {
    // negate media queries because the logic inverts when using `display: none`
    !mobile && bp.push(MQ.mobile)
    !tablet && bp.push(MQ.tablet)
    !desktop && bp.push(MQ.desktop)
    const style = displayStyle(json2mq(bp))

    if (wrap) {
      return <div {...style}>{children}</div>
    } else if (typeof children === 'function') {
      return children(style)
    } else if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        ...children.props,
        ...style,
      })
    }
  }
  return null
}

export default React.memo(Responsive)
