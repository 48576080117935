import React, { ReactNode } from 'react'
import { css } from 'glamor'
import { useQuery } from 'react-query'

import { createMQ } from '../Responsive'
import { styles } from '../Main'
import CategoryDescriptionText from '../Category/CategoryDescriptionText'
import { Helmet } from 'react-helmet-async'
import { defineMessages, useIntl } from 'react-intl'
import firebase from 'firebase/app'
import { ArticleListEntry, MagazineMeta } from '../types'
import gold from '../utils/gold'
import useDatabaseQuery from '../utils/useDatabaseQuery'
import Link from '../Link'
import FullPageLoading from '../FullPageLoading'
import useDatabaseUrl from '../utils/useDatabaseUrl'

const messages = defineMessages({
  magazine_meta_description: 'Maprodo Magazin',
  magazine_meta_title: 'Maprodo Magazin',
})

const Header = () => (
  <div
    {...css({
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [createMQ('desktop')]: {
        backgroundImage: 'url(/assets/magazin_header_desktop.jpg)',
      },
      [createMQ('tablet')]: {
        backgroundImage: 'url(/assets/magazin_header_tablet.jpg)',
      },
      [createMQ('mobile')]: {
        backgroundImage: 'url(/assets/magazin_header_mobile.jpg)',
      },
      height: '50vh',
      maxHeight: 275,
      transition: '250ms',
      [createMQ('tablet', 'desktop')]: {
        maxHeight: 350,
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#fff',
      justifyContent: 'flex-end',
    })}
  >
    <h1
      {...css({
        margin: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 1.2,
        userSelect: 'none',
        fontFamily: `'Playfair Display', serif`,
        fontSize: 60,
        [createMQ('tablet', 'desktop')]: {
          fontSize: 80,
        },
        transform: 'translateY(45%)',
        transition: '250ms',
        background: `linear-gradient(to bottom, #fff 0%, #fff 60%, #000 60%, #000 100%)`,
        backgroundClip: 'text',
        textFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      })}
    >
      Magazin
    </h1>
  </div>
)

interface IArticleProps
  extends Omit<
    ArticleListEntry,
    'author_image' | 'published_at' | 'image_url' | 'updated_at'
  > {
  date: number
  image: string
  promoted: boolean
}

const Article = ({
  author,
  slug,
  category,
  date,
  snippet,
  title,
  image,
  promoted,
}: IArticleProps) => (
  <article
    {...css({
      gridColumn: promoted ? 'span 3' : 'span 2',
      display: 'flex',
      flexDirection: 'column',

      [createMQ('mobile')]: {
        gridColumn: promoted ? 'span 6' : 'span 3',
      },
      '@media (max-width: 590px)': {
        gridColumn: 'span 6',
      },
      [createMQ('desktop')]: {
        cursor: 'pointer',
        transition: '250ms',
        ':hover .article-image': {
          transform: 'scale(1.05)',
        },
      },
    })}
  >
    <Link to={`/magazin/${slug}`}>
      <div
        className="article-image-wrapper"
        {...css({
          width: '100%',
          height: 350,
          transition: '250ms',
          // boxShadow: '0 0 8px rgba(0, 0, 0, 0.25)',
          overflow: 'hidden',
        })}
      >
        <div
          className="article-image"
          {...css({
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom center',
            backgroundImage: `url(${image})`,
            transition: '250ms',
            transformOrigin: 'left center',
          })}
        />
      </div>
    </Link>
    <div
      {...css({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: 10,
        '> *': { padding: '10px 0' },
      })}
    >
      <div {...css({ fontSize: 11, color: gold, textAlign: 'center' })}>
        {category}
      </div>
      <Link to={`/magazin/${slug}`}>
        <div
          {...css({
            fontFamily: `'Playfair Display', serif`,
            fontSize: 34,
            [createMQ('mobile')]: {
              fontSize: 26,
            },
            marginBottom: 20,
          })}
        >
          {title}
        </div>
        <div
          {...css({
            fontSize: 16,
            textAlign: 'justify',
            letterSpacing: 0,
          })}
        >
          {snippet}
        </div>
      </Link>
      <div
        {...css({
          fontSize: 12,
          textAlign: 'right',
          color: '#8c8c8c',
          borderTop: '1px solid #e4e4e4',
          marginTop: 'auto',
        })}
      >
        {author} &middot;{' '}
        {new Date(date).toLocaleDateString('de-DE', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </div>
    </div>
  </article>
)

const Quote = ({ children, by }: { children: ReactNode; by: ReactNode }) => (
  <div
    {...css({
      userSelect: 'none',
      fontSize: 16,
      textAlign: 'justify',
      letterSpacing: 1.5,
      lineHeight: 1.5,
      position: 'relative',
      margin: '70px auto',
      marginTop: '95px',
      [createMQ('mobile', 'tablet')]: {
        marginLeft: 15,
      },
      [createMQ('desktop')]: {
        letterSpacing: 3,
        lineHeight: 1.5,
        width: '50%',
      },
      ':after': {
        position: 'absolute',
        top: 0,
        left: 0,
        fontFamily: `'Playfair Display', serif`,
        fontSize: 180,
        lineHeight: 0.7,
        transform: 'translate(-25px, -25px)',
        color: 'rgba(176, 151, 109, 0.2)',
        content: '“',
      },
    })}
  >
    {children}
    <span style={{ color: gold, whiteSpace: 'nowrap' }}> &mdash; {by}</span>
  </div>
)

const MagazineOverview = () => {
  const { formatMessage } = useIntl()

  const meta = useDatabaseQuery<MagazineMeta>('/magazine/meta')
  const databaseUrl = useDatabaseUrl()

  const articleList = useQuery(
    '/magazine/article_list',
    async ({ queryKey }) => {
      const snapshot = await firebase
        .app()
        .database(databaseUrl)
        .ref(queryKey[0])
        .orderByChild('published_at')
        .once('value')

      // this query needs special treatment because it's ordered and
      // the object returned by the snapshot loses its order
      const articles: ArticleListEntry[] = []
      snapshot.forEach((article) => {
        articles.push(article.val() as ArticleListEntry)
      })

      return articles.reverse()
    },
  )

  if (articleList.isLoading) return <FullPageLoading />

  if (!articleList.isSuccess || !meta.isSuccess) return null

  return (
    <>
      <Helmet>
        <title>Maprodo Magazin</title>
        <meta
          name="description"
          content={formatMessage(messages.magazine_meta_description)}
        />
        <meta
          name="title"
          content={formatMessage(messages.magazine_meta_title)}
        />
      </Helmet>
      <Header />
      <div {...styles.wrapper} {...css({ padding: '0 25px' })}>
        {meta.data && (
          <Quote by={meta.data.quote_by}>{meta.data.quote_text}</Quote>
        )}
        <div
          {...css({
            display: 'grid',
            width: '100%',
            gridGap: 10,
            gridTemplateColumns: 'repeat(6, 1fr)',
            gridAutoFlow: 'row dense',
          })}
        >
          {articleList.data.map((article, i) => (
            <Article
              key={article.slug}
              author={article.author}
              category={article.category}
              title={article.title}
              slug={article.slug}
              snippet={article.snippet}
              date={article.published_at}
              image={article.image_url}
              promoted={i < 2}
            />
          ))}
        </div>
        {meta.data && (
          <CategoryDescriptionText
            text={meta.data.description}
            center
            justify
          />
        )}
      </div>
    </>
  )
}

export default MagazineOverview
