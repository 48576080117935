import React from 'react'

const HeartOutline = props => (
  <svg width={16} height={16} viewBox="0 0 18 18" {...props}>
    <path
      d="M17 5.62C17 3.069 15.104 1 12.765 1 11.114 1 9.699 2.263 9 3.766 8.302 2.263 6.887 1 5.237 1 2.897 1 1 3.068 1 5.62c0 1.5.665 2.82 1.681 3.665l-.004.017L9 16l6.732-7.087.044-.046.028-.03-.002-.005A4.822 4.822 0 0 0 17 5.621z"
      stroke="#1E1E1C"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default HeartOutline
