import React from 'react'

const HeartFilled = props => (
  <svg width={16} height={16} viewBox="0 0 22 22" {...props}>
    <path
      d="M21.291 6.358c0-3.228-2.439-5.845-5.448-5.845-2.124 0-3.944 1.597-4.843 3.499C10.102 2.11 8.281.513 6.158.513 3.148.513.709 3.13.709 6.358c0 1.897.855 3.567 2.162 4.635l-.006.021L11 19.486l8.66-8.964.056-.057.036-.038-.002-.006a6.045 6.045 0 0 0 1.541-4.063"
      fillRule="nonzero"
      fill="#E60000"
    />
  </svg>
)

export default HeartFilled
