import { getCategoryFromPathname } from '../../Category/utils'
import getApiParams from '../../utils/getApiParams'
import { useQuery } from 'react-query'
import { TracdelightResponse } from '../../types'
import { buildApiUrl } from '../../utils/buildApiUrl'
import useProductListInfo, { anyToApGender } from './useProductListInfo'
import useBrand from './useBrand'

const useFacets = () => {
  const { search, params, pathname } = useProductListInfo()
  const brand = useBrand()
  const isBrandPage = Boolean(params.brand)

  const category = getCategoryFromPathname(pathname)

  const filterParams = getApiParams(search)
  const gender = anyToApGender(filterParams.gender || params.gender)

  const apiQueryParams = {
    ...filterParams,
    ...(gender ? { gender } : {}),
    page_size: 1,
  }

  if (category?.id) {
    apiQueryParams.category_id = category.id
  }

  if (isBrandPage) {
    // brand page needs the brand id facet
    apiQueryParams.brand_id = brand.data?.id
  } else {
    // normal product page on the other hand must not have it
    delete apiQueryParams.brand_id
  }

  delete apiQueryParams.color
  delete apiQueryParams.price_min

  if (params.searchTerm) {
    apiQueryParams.search = params.searchTerm
    delete apiQueryParams.category_id
    delete apiQueryParams.gender
  }

  return useQuery(buildApiUrl(apiQueryParams), {
    keepPreviousData: true,
    enabled: !isBrandPage || (isBrandPage && !!brand.data),
    select: (data: TracdelightResponse) => data.facets,
  })
}

export default useFacets
