import useProductListInfo, { anyToApGender } from './useProductListInfo'
import { getCategoryFromPathname } from '../../Category/utils'
import getApiParams from '../../utils/getApiParams'
import { useQuery } from 'react-query'
import { TracdelightResponse } from '../../types'
import { buildApiUrl } from '../../utils/buildApiUrl'
import useBrand from './useBrand'
import usePerPageSize from './usePerPageSize'

const useProducts = () => {
  const { search, params, pathname } = useProductListInfo()
  const brand = useBrand()

  const pageSize = usePerPageSize()

  const category = getCategoryFromPathname(pathname)
  const isBrandPage = Boolean(params.brand)

  const filterParams = getApiParams(search)
  const gender = anyToApGender(filterParams.gender || params.gender)

  const apiQueryParams = {
    ...filterParams,
    ...(gender ? { gender } : {}),
  }

  apiQueryParams.page_size = pageSize

  //  query is disabled as long it's not a brand page
  if (isBrandPage) {
    apiQueryParams.brand_id = brand.data?.id
  }

  if (category?.id) {
    apiQueryParams.category_id = category.id
  }

  if (params.searchTerm) {
    apiQueryParams.search = params.searchTerm
    delete apiQueryParams.category_id
    delete apiQueryParams.gender
  }

  const url = buildApiUrl(apiQueryParams)

  return useQuery<TracdelightResponse>(url, {
    keepPreviousData: true,
    enabled: !isBrandPage || (isBrandPage && !!brand.data),
  })
}

export default useProducts
