import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import { css } from 'glamor'
import PageBackIcon from '../icons/PageBack'
import PageNextIcon from '../icons/PageNext'
import { createMQ, is } from '../Responsive'
import { useIsStuck } from '../utils/hooks/useIsStuck'
import { useIsNativeApp } from '../utils/nativeApp'

const PAGER_OFFSET_DESKTOP = -1
const PAGER_OFFSET_MOBILE = 69

const styles = {
  paginationWrapper: (shouldStick, isStuck) =>
    css({
      display: 'flex',
      justifyContent: 'center',
      position: shouldStick && 'sticky',
      top: PAGER_OFFSET_DESKTOP,
      zIndex: 1,
      padding: 10,
      background: '#fff',
      ':after': shouldStick &&
        isStuck && {
          display: 'block',
          content: `''`,
          position: 'absolute',
          height: 5,
          width: '100%',
          background:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0))',
          bottom: -5,
          left: 0,
          right: 0,
        },
      [createMQ('mobile', 'tablet')]: {
        margin: '0 -50px',
        padding: '20px 0',
        top: PAGER_OFFSET_MOBILE,
      },
    }),
  paginationInner: css({
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    maxWidth: 250,
  }),
  paginationArrow: showArrow =>
    css({
      opacity: showArrow ? 1 : 0,
      cursor: showArrow && 'pointer',
      padding: 10,
      margin: -10,
    }),
}

interface IPagerProps {
  current: number
  total: number
  onPrevious: (e: MouseEvent) => void
  onNext: (e: MouseEvent) => void
  shouldStick?: boolean
}

const shadowClass = css({
  ':after': {
    display: 'block',
    content: `''`,
    position: 'absolute',
    height: 5,
    width: '100%',
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0))',
    bottom: -5,
    left: 0,
    right: 0,
  },
}).toString()

const Pager = ({
  shouldStick,
  current,
  total,
  onPrevious,
  onNext,
}: IPagerProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const isNativeApp = useIsNativeApp()
  const offset =
    is('mobile', 'tablet') && !isNativeApp
      ? PAGER_OFFSET_MOBILE
      : PAGER_OFFSET_DESKTOP

  const isStuck = useIsStuck(wrapperRef, offset)

  return (
    <div
      ref={wrapperRef}
      {...css(styles.paginationWrapper(shouldStick, isStuck), {
        top: isNativeApp && '-1px !important',
      })}
    >
      <div {...styles.paginationInner}>
        <div
          onClick={current > 1 ? onPrevious : undefined}
          {...styles.paginationArrow(current > 1)}
        >
          <PageBackIcon />
        </div>
        <div>
          Seite {current} von {total || '…'}
        </div>
        <div
          onClick={current < total ? onNext : undefined}
          {...styles.paginationArrow(current < total)}
        >
          <PageNextIcon />
        </div>
      </div>
    </div>
  )
}

export default Pager
