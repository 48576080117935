import React from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: undefined,
    eventId: undefined,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV !== 'production') {
      return (
        <>
          <h1>Something went wrong!</h1>
          <div>{JSON.stringify(this.state.error)}</div>
        </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
