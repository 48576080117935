import { Link } from 'react-router-dom'
import React from 'react'
import { css } from 'glamor'

import HorizontalScoller from '../HorizontalScoller'

const BrandStrip = ({ style }: { style?: object }) => (
  <div
    {...css([
      {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        height: 120,
        background: 'url(/assets/brands/footer.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
      style,
    ])}
  >
    <HorizontalScoller>
      {[
        'balenciaga',
        'burberry',
        'givenchy',
        'gucci',
        'jimmy-choo',
        'moncler',
        'prada',
        'tommy-hilfiger',
        'ugg-australia',
        'versace',
      ].map(brand => (
        <Link to={`/marken/${brand}`} key={brand}>
          <img
            src={`/assets/brands/${brand}.png`}
            alt={brand}
            {...css({ height: 60, margin: '0 25px' })}
          />
        </Link>
      ))}
    </HorizontalScoller>
  </div>
)

export default BrandStrip
