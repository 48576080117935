import { css } from 'glamor'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

import Button from '../Button'
import { CategorySidebarInner } from './CategorySidebar'
import Box from '../Box'
import { Headline } from './FilterSidebar'
import { getFormattedGender } from '../Product/ProductList'

const CHROME_ONLY_MQ = `@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)`

export const NativeCategoryMenu = () => {
  const { pathname } = useLocation()
  const { gender } = useParams<{ gender: string }>()
  const history = useHistory()

  return (
    <div>
      <Headline onClickBack={() => history.goBack()}>
        {getFormattedGender(gender)}
      </Headline>
      <CategorySidebarInner
        hideTopBar
        prefixPath="/kategorien"
        style={{
          [CHROME_ONLY_MQ]: {
            paddingBottom: 0,
            minHeight: '100vh',
          },
        }}
      />
      <Button
        contrast
        {...css(
          { width: '100%', fontWeight: 400, textAlign: 'center' },
          {
            // unfortunately Chrome/Safari behave differently here
            [CHROME_ONLY_MQ]: { position: 'sticky' },
            position: 'fixed',
            borderRadius: 0,
            background: '#bbbbbb',
            bottom: 0,
            border: 0,
            padding: 17,
          }
        )}
        onClick={() => {
          history.push(pathname.replace(/^\/kategorien/, ''))
        }}
      >
        Übernehmen
      </Button>
    </div>
  )
}

type NativeTypes = 'handset' | 'tablet' | 'tv' | 'unknown'

export const NativeCategoryOverview = () => {
  const { search } = useLocation()

  const deviceType = new URLSearchParams(search).get('deviceType') as
    | NativeTypes
    | undefined

  const prefixPath = deviceType === 'handset' ? '/kategorien' : ''

  return (
    <Box.Wrapper>
      <Box image="/assets/damenmode_500x500.jpg">
        <Box.Link to={`${prefixPath}/damen`}>
          <Box.Title>Damenmode</Box.Title>
          <Box.Subtitle>Neueste Kollektion</Box.Subtitle>
        </Box.Link>
      </Box>
      <Box image="/assets/herrenmode_500x500.jpg">
        <Box.Link to={`${prefixPath}/herren`}>
          <Box.Title>Herrenmode</Box.Title>
          <Box.Subtitle>Die neuesten Klassiker</Box.Subtitle>
        </Box.Link>
      </Box>
      <Box image="/assets/marken_500x500.jpg">
        <Box.Link to="/marken">
          <Box.Title>Marken</Box.Title>
          <Box.Subtitle>Designer & Marken</Box.Subtitle>
        </Box.Link>
      </Box>
    </Box.Wrapper>
  )
}
