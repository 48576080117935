import { RefObject, useEffect } from 'react'
import { useLocation } from 'react-router'

const useScrollToTop = (ref: RefObject<HTMLElement>) => {
  const { search, pathname } = useLocation()

  useEffect(() => {
    if (!ref.current || !document.body.contains(ref.current)) {
      window.scrollTo({ top: 0 })
      return
    }

    const { top } = ref.current.getBoundingClientRect()

    if (top < 0) {
      window.scrollBy({ top })
    }
  }, [pathname, ref, search])
}

export default useScrollToTop
