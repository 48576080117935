import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { css } from 'glamor'

import Link from './Link'
import gold from './utils/gold'

const styles = {
  wrapper: css({
    display: 'flex',
    zIndex: 5000,
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    padding: 20,
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    fontSize: 12,
    fontWeight: 500,
  }),
  text: css({
    marginRight: 10,
  }),
  button: css({
    border: '2px solid #fff',
    padding: 10,
    flexShrink: 0,
    height: 35,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    transition: '250ms ease-in-out',
    ':hover': {
      background: '#fff',
      color: '#000',
    },
  }),
}

const COOKIE_NAME = 'cookie_consent'

const CookieConsent = () => {
  const [showHint, setShowHint] = useState(false)

  const [cookies, setCookie] = useCookies([COOKIE_NAME])

  useEffect(() => {
    if (cookies[COOKIE_NAME] === undefined) {
      setShowHint(true)
    }
  }, [cookies])

  const handleButtonClick = () => {
    setShowHint(false)
    setCookie(COOKIE_NAME, 1, { path: '/', maxAge: 60 * 60 * 24 * 365 })
  }

  if (!showHint) {
    return null
  }
  return (
    <div {...styles.wrapper}>
      <div {...styles.text}>
        Maprodo verwendet Cookies, um Dir die bestmöglichen Shopping-Erfahrungen
        zu gewährleisten. Mit dem Verbleib auf Maprodo stimmst Du der
        Cookie-Nutzung zu.&nbsp;
        <Link showActive={false} to="/datenschutz" color={gold}>
          Mehr Informationen.
        </Link>
      </div>
      <div {...styles.button} onClick={handleButtonClick}>
        Einverstanden
      </div>
    </div>
  )
}

export default React.memo(CookieConsent)
