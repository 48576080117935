import React, { ChangeEvent, useRef, useState } from 'react'
import { css } from 'glamor'
import { Helmet } from 'react-helmet-async'
import ImageMagnify from 'react-image-magnify'
import { Redirect, useHistory, useParams } from 'react-router'
import speakingurl from 'speakingurl'
import { executeSearch, SearchInput } from '../FullPageSearch'
import HighlightBox from '../HighlightBox/HighlightBox'
import { ASIDE_WIDTH, MAX_WIDTH, TWO_COLUMNS_MQ } from '../Layout'

import Letterhead from '../Letterhead'
import Responsive, { createMQ } from '../Responsive'
import { buildApiUrl, buildProductDetailUrl } from '../utils/buildApiUrl'
import decodeHtmlChars from '../utils/decodeHtmlChars'
import ProductDetailsInfo from './ProductDetailsInfo'
import { DEFAULT_MAGNIFY_OPTIONS } from './ProductImage'
import ProductInlineList from './ProductInlineList'
import { Product, TracdelightResponse } from '../types'
import sampleSize from 'lodash/sampleSize'
import { useQuery } from 'react-query'
import { toggleWishlistItem, useAddedToWishlist } from '../wishlistStore'
import { useInitialUrlContext } from '../UrlContext'

export const getSlug = (product: Product) =>
  speakingurl(`${product.brand.name} ${product.title}`)

export const getSharingUrl = (product: Product, origin: string) =>
  `${origin}/produkt/${product.id}/${getSlug(product)}`

const RelatedProductListWrapper = (props) => (
  <div
    {...css({
      // 100vw  - 2 columns - page padding => big layout
      width: `calc(100vw - ${ASIDE_WIDTH * 2}px - 100px)`,
      maxWidth: `calc(${MAX_WIDTH - ASIDE_WIDTH * 2}px - 100px)`,
      // 100vw  - 1 column - page padding => slim layout
      [TWO_COLUMNS_MQ]: {
        width: `calc(100vw - ${ASIDE_WIDTH}px - 100px)`,
      },
      [createMQ('mobile', 'tablet')]: {
        width: '100vw',
        marginLeft: -50,
        marginRight: -50,
      },
    })}
    {...props}
  />
)

const ProductDetailImage = ({ title, src }) => (
  <>
    <Responsive mobile>
      <img
        alt={title}
        src={src}
        {...css({
          width: '100vw',
          height: '100vw',
          maxWidth: 600,
          maxHeight: 600,
          margin: '0 -50px',
          objectFit: 'contain',
        })}
      />
    </Responsive>
    <Responsive desktop tablet>
      <ImageMagnify
        {...{
          ...DEFAULT_MAGNIFY_OPTIONS,
          style: { textAlign: 'center' }, // mobile fixing, otherwise would overlap screen
          imageStyle: { objectFit: 'contain' },
          smallImage: {
            alt: title,
            src,
            width: 350,
            height: 350,
          },
          largeImage: {
            alt: title,
            src,
            width: 800,
            height: 800,
          },
        }}
      />
    </Responsive>
  </>
)

const ProductDetailPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const { id, slug } = useParams<{ id: string; slug: string }>()
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()

  const url = buildProductDetailUrl(id)
  const product = useQuery<Product>(url, {
    keepPreviousData: true,
    retry: false,
  })

  const relatedProducts = useQuery<TracdelightResponse, unknown, Product[]>(
    !product.isError
      ? buildApiUrl({
          search: searchValue || undefined,
          page_size: 20,
          faceting: undefined,
          gender: product.data?.tags.gender,
        })
      : buildApiUrl({ faceting: undefined, page_size: 20 }),
    {
      enabled: product.isSuccess || product.isError,
      select: (data) => sampleSize(data.results, 12),
    },
  )

  const isInWishlist = useAddedToWishlist(id)
  const { origin } = useInitialUrlContext()

  if (product.isLoading) return null

  if (product.isError || !product.data) {
    return (
      <div
        {...css({ display: 'flex', flexDirection: 'column', marginTop: 30 })}
      >
        <HighlightBox
          fontStyle={{ fontWeight: 300, textTransform: 'none', fontSize: 18 }}
        >
          <div
            {...css({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              flexDirection: 'column',
            })}
          >
            Das gewünschte Produkt ist leider nicht mehr verfügbar.
            <br />
            Tippe einen Suchbegriff ein, um Alternativen zu finden:
            <br />
            <div {...css({ padding: '25px 0px' })}>
              <SearchInput
                value={searchValue}
                ref={inputRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(e.target.value)
                }
                onClickX={() => {
                  setSearchValue('')
                  inputRef.current && inputRef.current.focus()
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && searchValue) {
                    executeSearch(history, searchValue)
                  }
                }}
                placeholder="Suchen…"
              />
            </div>
          </div>
        </HighlightBox>
        <Letterhead>Das könnte dir auch gefallen</Letterhead>
        {relatedProducts.isSuccess && (
          <RelatedProductListWrapper>
            <ProductInlineList products={relatedProducts.data} />
          </RelatedProductListWrapper>
        )}
      </div>
    )
  }

  const imageSrc = product.data.images.url_template.replace(/{0\}/g, '600')
  const productSlug = getSlug(product.data)

  return (
    <div>
      {process.env.BUILD_TARGET === 'server' && slug !== productSlug && (
        <Redirect to={`/produkt/${product.data.id}/${productSlug}`} />
      )}
      {product.isSuccess && (
        <>
          <div
            {...css({
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap',
            })}
          >
            <Helmet>
              {imageSrc && <meta property="og:image" content={imageSrc} />}
              <meta property="og:title" content={product.data.title} />
              <meta
                property="og:description"
                content={product.data.description}
              />
              <meta property="og:image:width" content="600" />
              <meta property="og:image:height" content="600" />
              <meta name="robots" content="noindex,follow" />
            </Helmet>
            {imageSrc && (
              <ProductDetailImage title={product.data.title} src={imageSrc} />
            )}
            <ProductDetailsInfo
              id={product.data.id}
              currency={product.data.list_price.currency}
              brand={product.data.brand}
              shop={product.data.shop}
              text={decodeHtmlChars(product.data.description)}
              currentPrice={product.data.list_price.current}
              oldPrice={product.data.list_price.old}
              onClickHeart={() => toggleWishlistItem(product.data)}
              isHeartActive={isInWishlist}
              title={product.data.title}
              trackingLink={product.data.id}
              sharingUrl={getSharingUrl(product.data, origin)}
              image={imageSrc}
            />
          </div>
          <div>
            <Letterhead>Das könnte dir auch gefallen</Letterhead>
            <RelatedProductListWrapper style={{ marginTop: -40 }}>
              <ProductInlineList products={relatedProducts.data} />
            </RelatedProductListWrapper>
          </div>
        </>
      )}
    </div>
  )
}

export default ProductDetailPage
