import { css } from 'glamor'
import React, { CSSProperties, ReactNode } from 'react'

interface IHighlightBoxProps {
  title?: ReactNode
  fontStyle?: CSSProperties
  titleFontStyle?: CSSProperties
  children: ReactNode
}

export const HighlightBoxWrapper = ({
  children,
  styles,
}: {
  children: ReactNode
  styles?: CSSProperties
}) => (
  <div
    {...css([
      {
        border: '4px double #e4e4e4',
        padding: 30,
        textAlign: 'center',
      },
      styles,
    ])}
  >
    {children}
  </div>
)

const HighlightBox = ({
  title,
  children,
  titleFontStyle = {},
  fontStyle = {},
}: IHighlightBoxProps) => (
  <HighlightBoxWrapper>
    {title && (
      <div
        {...css({
          fontWeight: 500,
          textTransform: 'uppercase',
          marginBottom: 20,
          fontSize: 13,
          ...titleFontStyle,
        })}
      >
        {title}
      </div>
    )}
    <div
      {...css({
        textTransform: 'uppercase',
        fontWeight: 600,
        lineHeight: 1.5,
        ...fontStyle,
      })}
    >
      {children}
    </div>
  </HighlightBoxWrapper>
)

export default HighlightBox
