import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { css } from 'glamor'

import ArrowDown from '../icons/DoubleArrowDown'
import { createMQ } from '../Responsive'
import TimesCircle from '../icons/TimesCircle'

const styles = {
  wrapper: css({
    position: 'relative',
    margin: '0 10px',
    userSelect: 'none',
  }),
  popover: (isOpen, align, width = 220) =>
    css({
      [createMQ('tablet', 'desktop')]: {
        width,
        position: 'absolute',
        opacity: isOpen ? 1 : 0,
        pointerEvents: !isOpen && 'none',
        transition: '.25s',
        zIndex: 2,
        left: align === 'left' && 0,
        right: align === 'right' && 0,
        top: 0,
        background: 'white',
        outline: '1px solid',
        padding: '5px 10px',
        margin: '-5px -10px',
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
      },
    }),
  label: css({ paddingRight: 5 }),
  title: align =>
    css({
      display: 'flex',
      textTransform: 'uppercase',
      justifyContent: align === 'right' && 'flex-end',
      cursor: 'pointer',
      [createMQ('tablet', 'desktop')]: {
        borderBottom: '2px solid black',
      },
      [createMQ('mobile')]: {
        justifyContent: 'center',
        fontWeight: 600,
      },
      paddingBottom: 5,
      marginBottom: 5,
    }),
  arrows: open =>
    css({
      transition: '.25s',
      transform: open ? 'translateY(4px) rotate(-180deg)' : 'translateY(4px)',
    }),
}

export interface IFilterBoxProps {
  label: ReactNode
  align?: 'left' | 'right'
  forceOpen?: boolean
  clearable?: boolean
  onClear?: () => void
  alwaysRenderChildren?: boolean
  popoverWidth?: number
}

const FilterBox: FunctionComponent<IFilterBoxProps> = ({
  label,
  children,
  clearable,
  onClear,
  forceOpen = false,
  align = 'left',
  alwaysRenderChildren = false,
  popoverWidth,
}) => {
  const [isOpen, setIsOpen] = useState(forceOpen)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(
    e => {
      if (!isOpen || forceOpen) return

      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    },
    [forceOpen, isOpen]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    document.addEventListener('touchend', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
      document.removeEventListener('touchend', handleClick, true)
    }
  }, [handleClick])

  const renderTitle = () => (
    <div {...styles.title(align)} onClick={() => setIsOpen(!isOpen)}>
      {clearable && (
        <div
          title="Filter entfernen"
          onClick={e => {
            e.stopPropagation()
            onClear?.()
          }}
        >
          <TimesCircle
            width={12}
            style={{ marginRight: 5 }}
            color="rgba(0, 0, 0, 0.3)"
          />
        </div>
      )}
      <div {...styles.label}>{label}</div>
      {!forceOpen && <ArrowDown {...styles.arrows(isOpen)} />}
    </div>
  )

  return (
    <div ref={wrapperRef} {...styles.wrapper}>
      {!forceOpen && renderTitle()}
      <div {...styles.popover(isOpen, align, popoverWidth)}>
        {renderTitle()}
        {alwaysRenderChildren || (!alwaysRenderChildren && isOpen) ? (
          <div {...css({ textAlign: align })}>{children}</div>
        ) : null}
      </div>
    </div>
  )
}

export default FilterBox
