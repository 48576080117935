import React from 'react'

interface ITickProps extends React.SVGProps<SVGSVGElement> {
  checked?: boolean
  radius?: number
  fill?: string
  tickStrokeWidth?: number
  circleStrokeWidth?: number
  strokeColor?: string
}

const Tick = ({
  checked,
  radius,
  fill = 'none',
  tickStrokeWidth = 1.5,
  circleStrokeWidth = 1.5,
  strokeColor = '#1e1e1c',
  ...props
}: ITickProps) => (
  <svg
    width={radius}
    height={radius}
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
      <g transform="translate(1.000000, 1.000000)" stroke={strokeColor}>
        <circle cx="8" cy="8" r="8" strokeWidth={circleStrokeWidth} />
        {checked && (
          <polyline
            strokeWidth={tickStrokeWidth}
            points="5 9.1294951 6.98508159 11 11 5"
          />
        )}
      </g>
    </g>
  </svg>
)

export default Tick
