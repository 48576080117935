import React from 'react'
import { css } from 'glamor'
import ListBox from './Brand/ListBox'
import CategoryDescriptionText from './Category/CategoryDescriptionText'
import { MARGIN } from './Footer'
import ProductInlineList from './Product/ProductInlineList'
import { createMQ, VERY_SMALL_MQ } from './Responsive'
import TitleSection from './TitleSection'

import { buildApiUrl } from './utils/buildApiUrl'
import Box from './Box'
import { HomeMetadata, Linkbox, TracdelightResponse } from './types'
import HomeHead from './HomeHead'
import { useQuery } from 'react-query'
import useDatabaseQuery from './utils/useDatabaseQuery'
import BrandStrip from './Brand/BrandStrip'
import { useIsNativeApp } from './utils/nativeApp'

const ProductListWrapper = (props) => (
  <div
    {...css({
      [createMQ('mobile', 'tablet')]: {
        overflow: 'hidden',
        marginLeft: -50,
        width: 'calc(100% + 100px)',
      },
    })}
    {...props}
  />
)

const Home = () => {
  const home = useDatabaseQuery<HomeMetadata>('/home')
  const linkboxes = useDatabaseQuery<Linkbox[]>('/linkboxes')
  const isNativeApp = useIsNativeApp()

  const popularProducts = useQuery<TracdelightResponse>(
    buildApiUrl({ faceting: undefined, page_size: 16 }),
  )

  const newestProducts = useQuery<TracdelightResponse>(
    buildApiUrl({ faceting: undefined, page_size: 16, sorting: 'newest' }),
  )

  return (
    <>
      <HomeHead />
      <div
        {...css({
          display: 'flex',
          margin: '0 50px',
          alignItems: 'center',
          flexDirection: 'column',
        })}
      >
        <div
          {...css({
            display: 'flex',
            maxWidth: 980,
            width: '100%',
            flexDirection: 'column',
          })}
        >
          <Box.Wrapper
            style={{
              gridAutoRows: 'minmax(300px, auto)',
              gridTemplateColumns: 'repeat(auto-fill, minmax(325px, 1fr))',
              [VERY_SMALL_MQ]: {
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
              },
              gridGap: 20,
              margin: '20px 0',
            }}
          >
            <Box image="/assets/damenmode_500x500.jpg">
              <Box.Link to="/damen">
                <Box.Title>Damenmode</Box.Title>
                <Box.Subtitle>Neueste Kollektion</Box.Subtitle>
              </Box.Link>
            </Box>
            <Box image="/assets/herrenmode_500x500.jpg">
              <Box.Link to="/herren">
                <Box.Title>Herrenmode</Box.Title>
                <Box.Subtitle>Die neuesten Klassiker</Box.Subtitle>
              </Box.Link>
            </Box>
            <Box image="/assets/magazin_500x500.jpg">
              <Box.Link to="/magazin">
                <Box.Title>Magazin</Box.Title>
                <Box.Subtitle>Pure Inspiration</Box.Subtitle>
              </Box.Link>
            </Box>
            <Box image="/assets/marken_500x500.jpg">
              <Box.Link to="/marken">
                <Box.Title>Marken</Box.Title>
                <Box.Subtitle>Designer & Marken</Box.Subtitle>
              </Box.Link>
            </Box>
          </Box.Wrapper>
          <div
            {...css({ [createMQ('mobile', 'tablet')]: { margin: '0 -35px' } })}
          >
            <TitleSection>Beliebte Produkte</TitleSection>
          </div>

          <ProductListWrapper>
            <ProductInlineList
              products={popularProducts.data?.results}
              loading={popularProducts.isLoading}
            />
          </ProductListWrapper>

          <div
            {...css({ [createMQ('mobile', 'tablet')]: { margin: '0 -35px' } })}
          >
            <TitleSection>Neueste Produkte</TitleSection>
          </div>
          <ProductListWrapper>
            <ProductInlineList
              products={newestProducts.data?.results}
              loading={newestProducts.isLoading}
            />
          </ProductListWrapper>
          {home.isSuccess && home.data && (
            <CategoryDescriptionText
              text={home.data.description}
              center
              justify
            />
          )}

          {!isNativeApp && (
            <hr
              {...css({
                width: '100%',
                borderWidth: 0,
                borderTop: '1px solid #d2d2d2',
                margin: '30px 0',
              })}
            />
          )}

          {!isNativeApp && linkboxes.isSuccess && (
            <ListBox.Wrapper>
              {(linkboxes?.data ?? [])
                // may be a List with holes (why?)
                .filter((group) => group != null)
                .map((group) => (
                  <ListBox title={group.title} size="s" key={group.title}>
                    {group.links
                      // may be a List with holes (why?)
                      .filter((link) => link != null)
                      .map((link) => (
                        <ListBox.Item
                          key={link.name}
                          link={link.link}
                          title={link.title}
                          size="m"
                        >
                          {link.name}
                        </ListBox.Item>
                      ))}
                  </ListBox>
                ))}
            </ListBox.Wrapper>
          )}
        </div>
      </div>
      <div {...css({ marginBottom: -MARGIN })}>
        <BrandStrip />
      </div>
    </>
  )
}

export default Home
