import React from 'react'

const PageBack = props => (
  <svg width={14} height={16} viewBox="0 0 14 16" {...props}>
    <path
      d="M5.966.587L.318 7.772v.494l5.648 7.147 1.135-.785L2.456 7.99l4.645-6.617L5.966.587zm6.601 0L6.919 7.772v.494l5.648 7.147 1.115-.785L9.037 7.99l4.645-6.617-1.115-.786z"
      fillRule="nonzero"
      fill="#1E1E1C"
    />
  </svg>
)

export default PageBack
