import { FunctionComponent, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const Modal: FunctionComponent = ({ children }) => {
  const selectedElement =
    process.env.BUILD_TARGET === 'client'
      ? document.querySelector('#root')!
      : undefined
  const [element] = useState(
    process.env.BUILD_TARGET === 'client'
      ? document.createElement('div')
      : undefined,
  )

  useEffect(() => {
    if (element) {
      selectedElement!.appendChild(element)

      return () => {
        selectedElement!.removeChild(element)
      }
    }
  })

  return process.env.BUILD_TARGET === 'client'
    ? createPortal(children, selectedElement!)
    : null
}

export default Modal
