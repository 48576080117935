import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Cookies, useCookies } from 'react-cookie'
import { useSidebarContext } from '../Sidebar/SidebarProvider'

export const COOKIE_NAME = 'is_native'

if (
  process.env.BUILD_TARGET === 'client' &&
  process.env.NODE_ENV === 'development'
) {
  window.forceNative = (force: boolean) => {
    document.cookie = force
      ? `${COOKIE_NAME}=;`
      : `${COOKIE_NAME}=; expires = Thu, 01 Jan 1970 00:00:00 GMT`
    location.reload()
  }
}

export const clientIsNative = () => !!new Cookies().get('is_native')

export const useIsNativeApp = () => {
  const [cookies] = useCookies([COOKIE_NAME])

  return COOKIE_NAME in cookies
}

export const sendToNativeApp = (
  name: string,
  data: string | object | number | undefined
) => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      name,
      data,
    })
  )
}

export const useRegisterNativeCommunicator = () => {
  const history = useHistory()
  const location = useLocation()
  const isNativeApp = useIsNativeApp()
  const { close } = useSidebarContext()

  useEffect(() => {
    sendToNativeApp('routeChange', {
      path: location.pathname,
      length: history.length,
    })
  }, [history.length, location.pathname])

  useEffect(() => {
    if (!isNativeApp) return

    if (process.env.NODE_ENV === 'development') {
      console.warn('Forced native mode is active.')

      // if (process.env.BUILD_TARGET === 'client') {
      //   window.ReactNativeWebView = {
      //     postMessage: (data: string) => {
      //       console.info('Post message sent to native')
      //       console.info(JSON.parse(data))
      //     },
      //   }
      // }
    }

    window.NativeCommunicator = {
      push: (path: string) => {
        // close sidebar if necessary so doesnt stay open on Home changed routes
        close()
        if (history.location.pathname !== path) {
          history.push(path)
        }
      },
      replace: (path: string) => {
        history.replace(path)
      },
    }

    return () => {
      delete window.NativeCommunicator
    }
  }, [history, isNativeApp])
}
