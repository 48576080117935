import { QueryOptions, useQueries, UseQueryResult } from 'react-query'
import merge from 'lodash/merge'
import { BrandCategories } from '../../types'
import { databaseCall } from '../../utils/databaseCall'
import { __REACT_QUERY_DATABASE_HASH_KEY__ } from '../../utils/useDatabaseQuery'
import useProductListInfo, { paramToDbGender } from './useProductListInfo'
import useDatabaseUrl from '../../utils/useDatabaseUrl'

const useBrandCategories = (
  options?: QueryOptions,
): UseQueryResult<BrandCategories | undefined> => {
  const { params } = useProductListInfo()
  const { gender, brand } = params
  const isBrandPage = Boolean(brand)

  const paths = gender
    ? [`/brand-categories/${paramToDbGender(gender)}/${brand}`]
    : [`/brand-categories/female/${brand}`, `/brand-categories/male/${brand}`]

  const databaseUrl = useDatabaseUrl()

  const queries = useQueries(
    paths.map((p) => ({
      queryKey: [__REACT_QUERY_DATABASE_HASH_KEY__, p],
      queryFn: () => databaseCall(p, undefined, databaseUrl),
      enabled: isBrandPage,
      ...options,
    })),
  )

  // @todo hmmm
  // @ts-ignore
  return merge(...queries)
}

export default useBrandCategories
