import React from 'react'
import { Helmet } from 'react-helmet-async'

import CategoryDescriptionText from './Category/CategoryDescriptionText'
import FullPageLoading from './FullPageLoading'
import { Page } from './Layout'
import { useParams } from 'react-router'
import useDatabaseQuery from './utils/useDatabaseQuery'

interface PageContent {
  title: string
  text: string
}

interface IStaticPageProps {
  noIndex?: boolean
}

const StaticPage = ({ noIndex }: IStaticPageProps) => {
  const { page } = useParams<{ page: string }>()
  const pageContent = useDatabaseQuery<PageContent>(`/static-content/${page}`)

  if (!pageContent.isSuccess || !pageContent.data) return <FullPageLoading />

  return (
    <Page middleColumnOnly>
      {noIndex && (
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
          <title>{pageContent.data.title}</title>
        </Helmet>
      )}
      <CategoryDescriptionText
        justify={false}
        title={pageContent.data.title}
        text={pageContent.data.text}
      />
    </Page>
  )
}

export default StaticPage
