import React from 'react'
import intersectionWith from 'lodash/intersectionWith'
import { css } from 'glamor'
import { Link } from 'react-router-dom'
import shuffleSeed from 'shuffle-seed'

import HighlightBox from '../HighlightBox/HighlightBox'
import upperFirst from 'lodash/upperFirst'
import { BrandFacet, BrandWithSlug, CategoryWithSlug } from '../types'
import gold from '../utils/gold'
import darkerGold from '../utils/darkerGold'
import useProductListInfo from '../Product/hooks/useProductListInfo'
import useDatabaseQuery from '../utils/useDatabaseQuery'
import useFacets from '../Product/hooks/useFacets'
import { BrandsResponse } from './BrandOverview'

interface IPopularBrandsProps {
  facetBrands: readonly BrandFacet[]
  category?: CategoryWithSlug
  gender?: string
}

const PopularBrands = ({ category }: IPopularBrandsProps) => {
  const { params } = useProductListInfo()
  const { gender } = params
  const facets = useFacets()
  const popularBrands = useDatabaseQuery<BrandsResponse, BrandWithSlug[]>(
    '/brands',
    {
      enabled:
        facets.isSuccess && facets.data && facets.data?.brand_id.length > 0,
      select: brandData => {
        const brands = Object.values(brandData)

        if (!facets.data) return []

        const whitelistedBrands = intersectionWith(
          brands,
          // we ensured data is available because the query is only enabled
          // if the other request was successful
          facets.data.brand_id,
          (brand, facetBrands) => brand.id === facetBrands.id
        )

        const popularBrands = shuffleSeed.shuffle(
          Object.values(whitelistedBrands),
          category ? category.id : gender
        )

        popularBrands.length = 15

        return popularBrands
      },
    }
  )

  if (!popularBrands.isSuccess) return null

  return (
    <HighlightBox
      title={
        <>
          Beliebte Marken für {upperFirst(gender)} {category && category.name}
        </>
      }
    >
      {popularBrands.data.map((brand, index, array) => (
        <div {...css({ margin: 2, display: 'inline-block' })} key={brand.name}>
          {category ? (
            <Link
              title={`${upperFirst(gender)} ${brand.name} ${category.name}`}
              to={`/marken/${brand._slug}/${gender}/${category.slug}`}
            >
              <span {...css({ color: gold })}>{brand.name}</span>
              &nbsp;
              <span {...css({ color: '#353535' })}>
                {upperFirst(gender)} {category.name}
              </span>
            </Link>
          ) : (
            <Link
              title={`${upperFirst(gender)} ${brand.name}`}
              to={`/marken/${brand._slug}/${gender}`}
            >
              <span {...css({ color: darkerGold })}>{brand.name}</span>
              &nbsp;
              <span {...css({ color: '#353535' })}>
                {upperFirst(gender)}
                mode
              </span>
            </Link>
          )}
          {index < array.length - 1 && ' · '}
        </div>
      ))}
    </HighlightBox>
  )
}

export default PopularBrands
