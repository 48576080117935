import React from 'react'
import { Route } from 'react-router'

import LayoutStyles from '../Layout'
import Responsive from '../Responsive'
import ProductDetailPage from './ProductDetailPage'
import CategoryRoutes from '../Category/CategoryRoutes'
import ProductList from './ProductList'

const ProductPage = ({ scrollAnchorRef }) => (
  <>
    <div {...LayoutStyles.left()}>
      {/* @todo Make `onlyRenderOnMatch` smarter to avoid mismatches: https://github.com/ReactTraining/react-media/pull/50#issuecomment-415700905 */}
      <Responsive desktop onlyRenderOnMatch wrap={false}>
        <CategoryRoutes />
      </Responsive>
    </div>
    <div {...LayoutStyles.middle} ref={scrollAnchorRef}>
      <Route path="/produkt/:id/:slug?">
        <ProductDetailPage />
      </Route>
      <Route
        path={[
          '/marken/:brand/:gender(damen|herren)?/:cat1?',
          '/:gender(damen|herren)/:cat1?/:cat2?/:cat3?/:cat4?',
          '/suche/:searchTerm',
        ]}
      >
        <ProductList />
      </Route>
    </div>
    <div {...LayoutStyles.right} />
  </>
)

export default ProductPage
