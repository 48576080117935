import React from 'react'
import { Helmet } from 'react-helmet-async'

import HeroHeader from './HeroHeader'
import { PRODUCT_TYPES } from './ProductMode'
import { BrandWithSlug, CategoryWithSlug, ValueOf } from '../types'
import useProductListInfo from './hooks/useProductListInfo'
import useBrand from './hooks/useBrand'
import { getCategoryFromPathname } from '../Category/utils'
import useDatabaseQuery from '../utils/useDatabaseQuery'
import { getFormattedGender } from './ProductList'

const getGenderProperty = (gender?: string) =>
  gender ? (gender === 'damen' ? 'femaleUrl' : 'maleUrl') : 'neutralUrl'

type HeaderImageProps = {
  gender?: string
  category?: CategoryWithSlug
  brand?: BrandWithSlug
}

interface IHeroHeaderContainerProps extends HeaderImageProps {
  type: ValueOf<typeof PRODUCT_TYPES>
}

const HeroHeaderContainer = ({ type }: IHeroHeaderContainerProps) => {
  const brand = useBrand()
  const { params, pathname } = useProductListInfo()
  const { gender } = params
  const category = getCategoryFromPathname(pathname)
  const dbGenderPath = getGenderProperty(gender)

  const refPath = category
    ? `/images/categories/${category.slug}/${dbGenderPath}`
    : params.brand
    ? `/images/brands/${params.brand}/${dbGenderPath}`
    : '/i-do-not-exist-and-am-just-a-fake-request'

  const fallback = useDatabaseQuery<{
    femaleUrl: string
    maleUrl: string
    neutralUrl: string
  }>('/images/fallback')
  const image = useDatabaseQuery<string>(refPath)

  const title =
    type === PRODUCT_TYPES.BRAND
      ? brand?.data?.name
      : type === PRODUCT_TYPES.CATEGORY
      ? category
        ? category.name
        : `${getFormattedGender(gender)}mode`
      : null

  const subtitle =
    type === PRODUCT_TYPES.BRAND ? (
      <>
        {gender && !category ? `${getFormattedGender(gender)}mode` : ''}
        {gender && category ? getFormattedGender(gender) + ' ' : ''}
        {category ? category.name : ''}
        {!category && params.gender ? ' von' : ''}
      </>
    ) : type === PRODUCT_TYPES.CATEGORY ? (
      gender && category ? (
        getFormattedGender(gender) + ' '
      ) : (
        ''
      )
    ) : null

  const heroImage =
    image.data ||
    (fallback.isSuccess && fallback?.data?.[getGenderProperty(gender)])

  return (
    <>
      {heroImage && (
        <Helmet>
          <meta property="og:image" content={heroImage} />
        </Helmet>
      )}
      <HeroHeader
        image={heroImage || undefined}
        title={title}
        subtitle={subtitle}
      />
    </>
  )
}

export default HeroHeaderContainer
